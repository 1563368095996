/* eslint-disable array-callback-return */
import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { ICourseModel } from "app/Model/course";
import { modulesSaga } from "./saga";
import { ModulesState } from "./type";
import { produce } from "immer";
import { IDeleteModule, IUpdateCourse } from "app/pages/Courses/slice/types";
import { ILanguage } from "utils/type";

export const initialState: ModulesState = {
  result: [],
  moduleModalState: false,
  isModuleLoading: false,
};

const slice = createSlice({
  name: "modules",
  initialState,
  reducers: {
    getModules(state, action: PayloadAction<ILanguage>) {
      state.isModuleLoading = true;
    },

    setModulesState(state, action: PayloadAction<ICourseModel[]>) {
      return produce(state, (draft) => {
        draft.result = action.payload?.flatMap((course) =>
          course.modules.map((module) => ({
            ...module,
            course_id: course.id,
            course_title: course.title,
          }))
        );
      });
    },

    setIsModulesLoading(state, action: PayloadAction<boolean>) {
      state.isModuleLoading = action.payload;
    },
    deleteModule(state, action: PayloadAction<IDeleteModule>) {
      state.isModuleLoading = true;
    },
    updateModuleTitle(state, action: PayloadAction<IUpdateCourse>) {
      state.isModuleLoading = true;
    },
    toggleModuleModalState(state, action: PayloadAction<boolean>) {
      state.moduleModalState = action.payload;
    },
    clearModule(state) {
      state.result = null;
    },
  },
});

export const { actions: modulesActions } = slice;
export const useModulesSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: modulesSaga });
  return { actions: slice.actions };
};
export const courseReducers = slice.reducer;
