import React from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { Typography, Button, Spacer } from "app/components/Core";
import Grid from "@mui/material/Grid";
import { Visibility, VisibilityOff } from "styled-icons/material";
import changePasswordValidationSchema from "../Form/Validation/changePasswordValidation";
import { IChangePasswordFields } from "app/pages/ChangePassword/slice/type";
import { theme } from "styles/theme/themes";
import { PasswordInput } from "../FormikWrapper/AuthForm";

interface IProps {
  onSubmit: (values: IChangePasswordFields) => void;
  initialValues: IChangePasswordFields;
  isLoading: boolean;
}

export default function PasswordForm(props: IProps) {
  const [inputPasswordType, setInputPasswordType] = React.useState(true);
  const [inputConfirmPasswordType, setInputConfirmPasswordType] =
    React.useState(true);
  const handlePasswordInputType = () => {
    setInputPasswordType(!inputPasswordType);
  };
  const handleConfirmPasswordInputType = () => {
    setInputConfirmPasswordType(!inputConfirmPasswordType);
  };

  return (
    <Formik
      initialValues={props.initialValues}
      validationSchema={changePasswordValidationSchema}
      onSubmit={props.onSubmit}
    >
      <Form style={{ width: "100%" }}>
        <Grid
          container
          sx={{ width: "100%" }}
          alignItems="center"
          direction="column"
          rowSpacing={2}
        >
          <Grid item xs={6}>
            <Typography> Password*</Typography>
            <Spacer mb={2} />
            <PasswordInput
              icon={inputPasswordType ? VisibilityOff : Visibility}
              handleInputType={handlePasswordInputType}
              inputName="password"
              inputType={inputPasswordType}
            />
            <ErrorMessage name="password">
              {(msg) => (
                <Typography fontWeight={3} color="red" fontSize="11px">
                  {msg}
                </Typography>
              )}
            </ErrorMessage>
          </Grid>
          <Grid item xs={6}>
            <Typography>Confirm Password*</Typography>
            <Spacer mb={2} />
            <PasswordInput
              icon={inputConfirmPasswordType ? VisibilityOff : Visibility}
              handleInputType={handleConfirmPasswordInputType}
              inputName="confirmPassword"
              inputType={inputConfirmPasswordType}
            />
            <ErrorMessage name="confirmPassword">
              {(msg) => (
                <Typography fontWeight={3} color="red" fontSize="11px">
                  {msg}
                </Typography>
              )}
            </ErrorMessage>
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <Button
              width={71}
              loading={props.isLoading}
              variant="secondary"
              type="submit"
              bg={theme.colors.primary.main}
              py={[2, 3]}
            >
              save
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
}
