import { call, put, takeLatest } from "redux-saga/effects";
import makeCall from "app/API/makeCalls";
import routes from "app/API/api.routes";
import { topicActions as actions } from ".";
import { handleAPIError } from "app/pages/DefaultLayout/slice/saga";
import { PayloadAction } from "@reduxjs/toolkit";
import { defaultLayoutActions } from "app/pages/DefaultLayout/slice";
import {
  DEFAULT_DESCRIPTION,
  DEFAULT_TITLE,
  VARIANTS,
} from "app/pages/DefaultLayout/constants";
import { TypeOptions } from "react-toastify";
import { v4 as uuidV4 } from "uuid";
import { ILanguage } from "utils/type";

function* getTopicsSaga(action: PayloadAction<ILanguage>) {
  try {
    const response = yield call(makeCall, {
      method: "GET",
      isSecureRoute: true,
      route: routes.topic.list,
      query: { language: action.payload },
    });
    if (response.code === "0") {
      if (response?.topics) {
        yield put(actions.setTopicsState(response.topics));
      }
    }
  } catch (error: any) {
    yield put(actions.setIsTopicsLoading(false));
    yield call(handleAPIError, error);
    console.log("Error Getting Topics", error);
  }
}

function* updateElementSaga(
  action: PayloadAction<{ title: string; element_id: string }>
) {
  try {
    const response = yield call(makeCall, {
      method: "POST",
      isSecureRoute: true,
      route: routes.topic.updateTitle,
      body: {
        title: action.payload.title,
        topic_id: action.payload.element_id,
      },
    });
    if (response.code === "0") {
      yield put(
        defaultLayoutActions.setAppMessage({
          currentMessage: DEFAULT_DESCRIPTION.Success,
          currentTitle: DEFAULT_TITLE.Success,
          currentVariant: VARIANTS.SUCCESS as TypeOptions,
          count: uuidV4(),
        })
      );
      yield put(actions.setIsTopicsLoading(false));
    }
  } catch (error: any) {
    yield put(
      defaultLayoutActions.setAppMessage({
        currentMessage: DEFAULT_DESCRIPTION.Error,
        currentTitle: DEFAULT_TITLE.Fail,
        currentVariant: VARIANTS.Error as TypeOptions,
        count: uuidV4(),
      })
    );
    yield put(actions.setIsTopicsLoading(false));
    yield call(handleAPIError, error);
    console.log("Error Getting Topics", error);
  }
}

function* deleteSaga(action: PayloadAction<string>) {
  try {
    const response = yield call(makeCall, {
      method: "POST",
      isSecureRoute: true,
      route: routes.topic.delete,
      body: {
        topic_id: action.payload,
      },
    });
    if (response.code === "0") {
      yield put(
        defaultLayoutActions.setAppMessage({
          currentMessage: DEFAULT_DESCRIPTION.Success,
          currentTitle: DEFAULT_TITLE.Success,
          currentVariant: VARIANTS.SUCCESS as TypeOptions,
          count: uuidV4(),
        })
      );
      yield put(actions.setIsTopicsLoading(false));
    }
  } catch (error: any) {
    yield put(
      defaultLayoutActions.setAppMessage({
        currentMessage: DEFAULT_DESCRIPTION.Error,
        currentTitle: DEFAULT_TITLE.Fail,
        currentVariant: VARIANTS.Error as TypeOptions,
        count: uuidV4(),
      })
    );
    yield put(actions.setIsTopicsLoading(false));
    yield call(handleAPIError, error);
    console.log("Error Getting Topics", error);
  }
}

export function* topicsSaga() {
  yield takeLatest(actions.getTopics.type, getTopicsSaga);
  yield takeLatest(actions.updateElement.type, updateElementSaga);
  yield takeLatest(actions.deleteElement.type, deleteSaga);
}
