import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ArrowRight } from "@mui/icons-material";

export default function LandingPage() {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxHeight: "100vh",
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#ffffff",
          padding: "20px 5%",
          width: "100%",
        }}
      >
        <Box
          component="nav"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "50px",
            width: "100%",
          }}
        >
          <Box>
            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontSize: "1.5rem",
                cursor: "pointer",
              }}
              variant="h1"
            >
              Course Engine AI
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "15px" }}>
            <Button variant="outlined">Become an AI Course Creator</Button>
            <Button variant="contained" onClick={() => navigate("/login")}>
              Login / Signup
            </Button>
          </Box>
        </Box>
        <Box sx={{ textAlign: "center", maxWidth: "800px", margin: "0 auto" }}>
          <Typography
            sx={{
              fontSize: "40px",
              fontWeight: "700",
              marginBottom: "20px",
              lineHeight: "1.2",
            }}
          >
            Revolutionize education
            <br />
            <span style={{ color: "#3498db" }}>with AI-assisted learning</span>
          </Typography>
          <Typography
            component="p"
            sx={{ fontSize: "18px", marginBottom: "30px" }}
          >
            Create hundreds of adaptive lessons tailored to your students'
            needs.
          </Typography>
          <Button
            variant="contained"
            endIcon={<ArrowRight />}
            sx={{ padding: "15px 30px" }}
            onClick={() => navigate("/home")}
          >
            {" "}
            Start building your AI-powered course
          </Button>
        </Box>
      </Box>
      <Box
        sx={{ padding: "50px 5%", backgroundColor: "#ffffff", width: "100%" }}
      >
        <Typography
          sx={{
            fontSize: "30px",
            fontWeight: "700",
            textAlign: "center",
            marginBottom: "40px",
          }}
        >
          Say goodbye to time-consuming and costly course creation:
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              textAlign: "center",
              maxWidth: "250px",
              marginBottom: "30px",
            }}
          >
            <Avatar
              sx={{
                width: "80px",
                height: "80px",
                margin: "0 auto 20px",
                backgroundColor: "#f5f7fa",
                borderRadius: "50%",
              }}
            ></Avatar>
            <Typography component="p" sx={{ fontSize: "16px" }}>
              No back and forth communication with subject matter experts
            </Typography>
          </Box>
          <Box
            sx={{
              textAlign: "center",
              maxWidth: "250px",
              marginBottom: "30px",
            }}
          >
            <Avatar
              sx={{
                width: "80px",
                height: "80px",
                margin: "0 auto 20px",
                backgroundColor: "#f5f7fa",
                borderRadius: "50%",
              }}
            ></Avatar>
            <Typography component="p" sx={{ fontSize: "16px" }}>
              Get your AI-generated course content in minutes
            </Typography>
          </Box>
          <Box
            sx={{
              textAlign: "center",
              maxWidth: "250px",
              marginBottom: "30px",
            }}
          >
            <Avatar
              sx={{
                width: "80px",
                height: "80px",
                margin: "0 auto 20px",
                backgroundColor: "#f5f7fa",
                borderRadius: "50%",
              }}
            ></Avatar>
            <Typography component="p" sx={{ fontSize: "16px" }}>
              For a fraction of traditional course development costs
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{ padding: "50px 5%", backgroundColor: "#ffffff", width: "100%" }}
      >
        <Typography
          sx={{
            fontSize: "30px",
            fontWeight: "700",
            textAlign: "center",
            marginBottom: "40px",
          }}
        >
          Take full control of the learning experience
        </Typography>
        <Typography
          component="p"
          sx={{ fontSize: "18px", marginBottom: "30px", textAlign: "center" }}
        >
          Transform your ideas into engaging, interactive, and personalized
          learning modules.
        </Typography>
        <Box
          sx={{
            maxWidth: "900px",
            margin: "40px auto 0",
            border: "1px solid #ecebec",
            borderRadius: "10px",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#f5f7fa",
              padding: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span
              style={{
                width: "12px",
                height: "12px",
                backgroundColor: "#e4e4e5",
                borderRadius: "50%",
                marginRight: "5px",
              }}
            />
            <span
              style={{
                width: "12px",
                height: "12px",
                backgroundColor: "#e4e4e5",
                borderRadius: "50%",
                marginRight: "5px",
              }}
            />
            <span
              style={{
                width: "12px",
                height: "12px",
                backgroundColor: "#e4e4e5",
                borderRadius: "50%",
                marginRight: "5px",
              }}
            />
            <span
              style={{
                marginLeft: "20px",
                color: "#083844",
              }}
            >
              courseengine.ai
            </span>
          </Box>
          <Box sx={{ display: "flex", minHeight: "400px" }}>
            <Box
              sx={{
                width: "200px",
                backgroundColor: "#f5f7fa",
                padding: "20px",
              }}
            >
              <Typography sx={{ fontSize: "18px", marginBottom: "20px" }}>
                AI Course Creator
              </Typography>
              <List>
                <ListItem
                  sx={{
                    backgroundColor: "#3498db",
                    color: "#ffffff",
                    borderRadius: "5px",
                  }}
                >
                  <ListItemText primary="Course Outline" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Learning Objectives" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Content Generation" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Assessment Tools" />
                </ListItem>
              </List>
            </Box>
            <Box sx={{ flexGrow: 1, padding: "20px" }}>
              <Typography sx={{ fontSize: "20px", marginBottom: "20px" }}>
                Course: Introduction to Data Science
              </Typography>
              <Box
                sx={{
                  backgroundColor: "#f5f7fa",
                  padding: "20px",
                  borderRadius: "5px",
                  marginBottom: "20px",
                }}
              >
                <Typography component="p">
                  Welcome to Introduction to Data Science. This course will
                  cover fundamental concepts including data analysis, machine
                  learning, and statistical inference. By the end of this
                  course, you'll be able to...
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
                  gap: "15px",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#f5f7fa",
                    padding: "15px",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                >
                  Module 1: Data Exploration
                </Box>
                <Box
                  sx={{
                    backgroundColor: "#f5f7fa",
                    padding: "15px",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                >
                  Module 2: Statistical Analysis
                </Box>
                <Box
                  sx={{
                    backgroundColor: "#f5f7fa",
                    padding: "15px",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                >
                  Module 3: Machine Learning Basics
                </Box>
                <Box
                  sx={{
                    backgroundColor: "#f5f7fa",
                    padding: "15px",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                >
                  Module 4: Data Visualization
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ minHeight: "100px" }}></Box>
    </Box>
  );
}
