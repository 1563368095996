import React from "react";
import { IModuleProps } from "./type";
import { CustomModal } from "app/components/Core";
import ModuleContent from "./ModuleContent";

export default function ModuleModal(props: IModuleProps) {
  return (
    <CustomModal
      leftWidth="239px"
      open={props?.isModalOpen || false}
      handleClose={props.handleClose}
    >
      <ModuleContent modules={props.modules} courseId={props.courseId} />
    </CustomModal>
  );
}
