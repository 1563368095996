import { call, put, takeLatest } from "redux-saga/effects";
import makeCall from "app/API/makeCalls";
import routes from "app/API/api.routes";
import { signUpActions as actions } from ".";
import { defaultLayoutActions } from "app/pages/DefaultLayout/slice";
import {
  DEFAULT_DESCRIPTION,
  DEFAULT_TITLE,
  VARIANTS,
} from "app/pages/DefaultLayout/constants";
import { TypeOptions } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { PayloadAction } from "@reduxjs/toolkit";
import { ISignUpPayload } from "../types";

function* SignUpSaga(action: PayloadAction<ISignUpPayload>) {
  try {
    const response = yield call(makeCall, {
      method: "POST",
      isSecureRoute: false,
      route: routes.user.signup,
      body: {
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
        phone: action.payload?.phone,
        password: action.payload.password,
        confirm_password: action.payload.confirmPassword,
        pref_language: action.payload.preferred_language,
        invitation_token: action.payload.token,
      },
    });
    if (response.code == "0") {
      yield put(actions.setUser(true));
      yield put(
        defaultLayoutActions.setAppMessage({
          currentMessage: DEFAULT_DESCRIPTION.Success,
          currentTitle: DEFAULT_TITLE.Success,
          currentVariant: VARIANTS.SUCCESS as TypeOptions,
          count: uuidv4(),
        })
      );
      yield put(actions.setIsSigningUp(false));
    }
  } catch (error: any) {
    try {
      yield put(actions.setIsSigningUp(false));
      if (error?.message == "User already exists.") {
        yield put(
          defaultLayoutActions.setAppMessage({
            currentMessage: "User already exists!",
            currentTitle: DEFAULT_TITLE.Fail,
            currentVariant: VARIANTS.Error as TypeOptions,
            count: uuidv4(),
          })
        );
      } else {
        yield put(
          defaultLayoutActions.setAppMessage({
            currentMessage: DEFAULT_DESCRIPTION.Error,
            currentTitle: DEFAULT_TITLE.Fail,
            currentVariant: VARIANTS.Error as TypeOptions,
            count: uuidv4(),
          })
        );
      }
    } catch (parseError) {
      yield put(
        defaultLayoutActions.setAppMessage({
          currentMessage: DEFAULT_DESCRIPTION.Error,
          currentTitle: DEFAULT_TITLE.Fail,
          currentVariant: VARIANTS.Error as TypeOptions,
          count: uuidv4(),
        })
      );
    }
  }
}
function* getOrganisationsSaga() {
  try {
    const response = yield call(makeCall, {
      method: "GET",
      isSecureRoute: true,
      route: routes.organizations.list,
    });
    if (response.code == "0") {
      if (response?.Organizations) {
        const res = response.Organizations?.reduce((acc: any, curr: any) => {
          acc.push({
            id: curr.ID,
            name: curr.Name,
          });
          return acc;
        }, []);
        yield put(actions.setOrganizations(res));
      }
      yield put(actions.setIsSigningUp(false));
    }
  } catch (error: any) {
    yield put(actions.setIsSigningUp(false));
    yield put(
      defaultLayoutActions.setAppMessage({
        currentMessage: DEFAULT_DESCRIPTION.Error,
        currentTitle: DEFAULT_TITLE.Fail,
        currentVariant: VARIANTS.Error as TypeOptions,
        count: uuidv4(),
      })
    );
    console.log("Error Getting Organizations", error);
  }
}
export function* SignUpSagaWatcher() {
  yield takeLatest(actions.signupUser.type, SignUpSaga);
  yield takeLatest(actions.getOrganisations.type, getOrganisationsSaga);
}
