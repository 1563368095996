/* eslint-disable array-callback-return */
import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { jobsSaga } from "./saga";
import { IJobStateInterface, EJob } from "./types";

export const initialState: IJobStateInterface = {
  result: undefined,
  isjobsLoading: false,
};

const slice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    getJobs(state) {
      state.isjobsLoading = true;
    },
    setJobsState(state, action: PayloadAction<EJob[]>) {
      state.result = action.payload;
    },
    setIsJobsLoading(state, action: PayloadAction<boolean>) {
      state.isjobsLoading = action.payload;
    },
  },
});

export const { actions: jobsActions } = slice;
export const useJobSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: jobsSaga });
  return { actions: slice.actions };
};
export const jobsReducers = slice.reducer;
