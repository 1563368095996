import React, { useRef, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  Button,
  LinearProgress,
  Alert,
} from "@mui/material";
import { CustomModal } from "../Core";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { selectSetting } from "app/pages/Settings/slice/selector";

interface ProfileModalProps {
  open: boolean;
  handleClose: () => void;
  handleFileUpload: (file: File) => void;
}

export default function ProfileModal({
  open,
  handleClose,
  handleFileUpload,
}: ProfileModalProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const settingState = useSelector(selectSetting);
  const { uploadState } = settingState;

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (uploadState?.progress === 100 || uploadState?.haveError) {
      timer = setTimeout(() => {
        handleClose();
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [uploadState, handleClose]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type === "image/png") {
      handleFileUpload(file);
    } else {
      alert("Please select a PNG file.");
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };

  return (
    <CustomModal
      open={open}
      handleClose={handleClose}
      isTransparent={false}
      borderRadius={8}
    >
      <Box
        sx={{
          width: "400px",
          padding: "24px",
          backgroundColor: "white",
          borderRadius: "8px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "24px",
          }}
        >
          <Typography variant="h6">Upload Profile Photo</Typography>
          {!uploadState?.processing && (
            <IconButton onClick={handleClose} size="small">
              <CloseIcon />
            </IconButton>
          )}
        </Box>

        {uploadState?.processing ? (
          <Box sx={{ width: "100%", mb: 2 }}>
            <LinearProgress
              variant="determinate"
              value={uploadState.progress}
            />
            <Typography variant="body2" sx={{ mt: 1 }}>
              Uploading: {uploadState.progress}%
            </Typography>
          </Box>
        ) : uploadState?.progress === 100 ? (
          <Alert severity="success" sx={{ mb: 2 }}>
            Profile picture uploaded successfully!
          </Alert>
        ) : uploadState?.haveError ? (
          <Alert severity="error" sx={{ mb: 2 }}>
            Error uploading profile picture. Please try again.
          </Alert>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "#f5f5f5",
              borderRadius: "8px",
              padding: "32px",
            }}
          >
            <input
              ref={fileInputRef}
              accept=".png"
              style={{ display: "none" }}
              id="avatar-upload"
              type="file"
              onChange={handleFileChange}
            />
            <Box
              onClick={triggerFileInput}
              sx={{
                width: 120,
                height: 120,
                borderRadius: "50%",
                backgroundColor: "#e0e0e0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#d0d0d0",
                },
              }}
            >
              <CameraAltIcon sx={{ fontSize: 40, color: "#757575" }} />
            </Box>
            <Typography
              variant="body2"
              sx={{ marginTop: "16px", color: "#757575" }}
            >
              Click to select a PNG file
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={triggerFileInput}
              sx={{ marginTop: "16px" }}
            >
              Upload Photo
            </Button>
          </Box>
        )}

        {(uploadState?.progress === 100 || uploadState?.haveError) && (
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
            sx={{ mt: 2 }}
          >
            Close
          </Button>
        )}
      </Box>
    </CustomModal>
  );
}
