import React from "react";
import Avatar from "@mui/material/Avatar";
import { Flex, Spacer, Typography } from "../Core";

export const AccountAvatar = () => {
  return (
    <Avatar
      src={require("../../../assets/images/AvatarActivate.png")}
      sx={{ width: ["120px"], height: ["120px"] }}
    />
  );
};

export function withHeader(WrappedComponent: React.ElementType) {
  const MyComp = () => {
    return (
      <Flex width={71} flexDirection={"column"} alignItems="center">
        <WrappedComponent />
        <Spacer my="8px" />
        <Typography
          color="#083844"
          fontFamily="Mulish"
          fontWeight={5}
          fontSize={[6, 8]}
        >
          Change Password
        </Typography>
      </Flex>
    );
  };
  MyComp.displayName = "test";
  return MyComp;
}
