import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "types";
import { initialState } from ".";
import { EImportModuleState } from "./type";

const selectSlice = (state: RootState) => state?.importModule || initialState;

export const selectImportModule: (state: RootState) => EImportModuleState =
  createSelector([selectSlice], (state) => state);

export const selectIsImportModuleLoading = createSelector(
  [selectImportModule],
  (state) => state.isImportModuleLoading
);

export const selectIsImportModuleSuccessful = createSelector(
  [selectImportModule],
  (state) => state.isImportModuleSuccess
);
