import { useState, useEffect } from "react";
import Box from "@mui/material/Box";

interface IProps {
  pdfUrl: string;
  handleClick: () => void;
}

export default function PDFViewer({ pdfUrl, handleClick }: IProps) {
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    if (pdfUrl) {
      setIsLoading(true);
      setError("");

      const getDocId = (url: string) => {
        const match = url.match(/[-\w]{25,}/);
        return match ? match[0] : null;
      };

      const docId = getDocId(pdfUrl);

      if (docId) {
        // Try different thumbnail sizes
        const thumbnailSizes = ["w200-h200", "w100-h100", "w50-h50"];

        const tryNextSize = (index = 0) => {
          if (index >= thumbnailSizes.length) {
            setError("Thumbnail not available");
            setIsLoading(false);
            return;
          }

          const thumbnailUrl = `https://drive.google.com/thumbnail?id=${docId}&sz=${thumbnailSizes[index]}`;

          fetch(thumbnailUrl, { mode: "no-cors" })
            .then(() => {
              setThumbnailUrl(thumbnailUrl);
              setIsLoading(false);
            })
            .catch(() => {
              // Try the next size
              tryNextSize(index + 1);
            });
        };

        tryNextSize();
      } else {
        setError("Invalid PDF URL");
        setIsLoading(false);
      }
    }
  }, [pdfUrl]);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        "&:hover": {
          cursor: "pointer",
        },
      }}
      onClick={handleClick}
    >
      {isLoading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>{error}</div>
      ) : thumbnailUrl ? (
        <img
          src={thumbnailUrl}
          alt="PDF preview"
          style={{ maxWidth: "200px", height: "auto" }}
          onError={() => setError("Failed to load image")}
        />
      ) : (
        <div>No preview available</div>
      )}
    </Box>
  );
}
