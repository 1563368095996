import lottieLoading from "assets/lotties/loading.json";
import Lottie from "react-lottie";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export const CourseModuleLoading = ({ isCourse }: { isCourse: boolean }) => {
  const lottieDefaultOptions = {
    loop: true,
    autoplay: true,
    animationData: lottieLoading,
    renderSettings: {
      preserveAspectRation: "xMidYMid slice",
      clearCanvas: true,
      progressiveLoad: false,
      hideOnTransparent: true,
    },
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        zIndex: "6500",
        position: "fixed",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        background: "rgba(0, 0, 0, 0.9) !important",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Typography
        sx={{
          fontSize: "36px",
          fontWeight: 400,
          pb: 4,
          color: "#ffffff",
        }}
      >
        {isCourse
          ? "Please wait while your course is being generated for you!"
          : "Please wait while your module is being generated for you!"}
      </Typography>
      <Box>
        <Lottie
          options={lottieDefaultOptions}
          style={{ backgroundColor: "transparent" }}
        />
      </Box>
    </Box>
  );
};
