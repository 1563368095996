import List from "@editorjs/list";
import Paragraph from "@editorjs/paragraph";
import Table from "@editorjs/table";
import Topics from "@kalebu2468/editorjs-topic";
import Lessons from "@kalebu2468/editor-lessons";
import Points from "@kalebu2468/editorjs-points";
import SubPoints from "@kalebu2468/editor-subpoints";
import Comment from "@kalebu2468/editorjs-comment";

export const createEditorJSTools = (moduleId, onCommentClick) => ({
  list: {
    class: List,
    config: {
      defaultStyle: "unordered",
    },
    inlineToolbar: ["bold", "italic"],
  },
  paragraph: {
    class: Paragraph,
    inlineToolbar: ["bold", "italic"],
  },
  table: {
    class: Table,
    inlineToolbar: ["bold", "italic"],
  },
  topics: {
    class: Topics,
    config: {
      placeholder: "Enter a Topic",
      levels: [1],
      defaultLevel: 1,
    },
    inlineToolbar: false,
  },
  lessons: {
    class: Lessons,
    config: {
      placeholder: "Enter a Lesson",
      levels: [2],
      defaultLevel: 2,
    },
    inlineToolbar: false,
  },
  points: {
    class: Points,
    config: {
      placeholder: "Enter a Point",
      levels: [3],
      defaultLevel: 3,
    },
    inlineToolbar: false,
  },
  subpoints: {
    class: SubPoints,
    config: {
      placeholder: "Enter a Sub-Point",
      levels: [4],
      defaultLevel: 4,
    },
    inlineToolbar: false,
  },
  comment: {
    class: Comment,
    config: {
      activeColor: "green",
      onCommentClick: onCommentClick,
    },
  },
});
