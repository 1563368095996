export const setToLocalStorage = (value: any) => {
  localStorage.setItem("user", value);
};

export const getFromLocalStorage = () => {
  return localStorage.getItem("user");
};

export const clearLocalStorage = () => {
  localStorage.clear();
};

export const removeAndSetTokenExpiration = (expirationTime) => {
  localStorage.removeItem("expirationTime");
  localStorage.setItem("expirationTime", expirationTime);
};

export const removeAndSetPrefLanguage = (pref_language) => {
  localStorage.removeItem("pref_language");
  localStorage.setItem("pref_language", pref_language);
};
