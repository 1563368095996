import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { ITopicsRes, ITopicsState } from "./types";
import { topicsSaga } from "./saga";
import { ILanguage } from "utils/type";

export const initialState: ITopicsState = {
  result: null,
  isTopicLoading: false,
};

const slice = createSlice({
  name: "topics",
  initialState,
  reducers: {
    getTopics(state, action: PayloadAction<ILanguage>) {
      state.isTopicLoading = true;
    },
    setTopicsState(state, action: PayloadAction<ITopicsRes[]>) {
      state.result = action.payload;
    },
    setIsTopicsLoading(state, action: PayloadAction<boolean>) {
      state.isTopicLoading = action.payload;
    },
    updateElement(
      state,
      action: PayloadAction<{ element_id: string; title: string }>
    ) {
      state.isTopicLoading = true;
    },
    deleteElement(state, action: PayloadAction<string>) {
      state.isTopicLoading = true;
    },
  },
});

export const { actions: topicActions } = slice;
export const useTopicsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: topicsSaga });
  return { actions: slice.actions };
};
export const topicsReducers = slice.reducer;
