import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Grid,
  Chip,
  Button,
  MenuItem,
  Paper,
  Container,
  TableContainer,
  TableHead,
  TableCell,
  Table,
  TableRow,
  TableBody,
  IconButton,
  Avatar,
  Typography,
  useTheme,
  TextField,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Tooltip,
} from "@mui/material";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Close as CloseIcon,
  AccountCircle as AccountCircleIcon,
  People as PeopleIcon,
  Business as BusinessIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import { selectDefaultLayoutState } from "../DefaultLayout/slice/selectors";
import { useSettingSlice } from "./slice";
import { selectRoles, selectSetting } from "./slice/selector";
import { IProfileFormValues } from "./type";
import { createUserValidationSchema } from "app/components/Form/Validation/createUserFormValidation";
import { IUserModel } from "app/Model/user";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { LanguageSelect } from "app/components/Core/Input/Select";
import { CustomModal, Spacer } from "app/components/Core";
import profileValidation from "app/components/Form/Validation/profileValidation";
import { theme as styledTheme } from "styles/theme/themes";
import { organizationValidationSchema } from "app/components/Form/Validation/organizationValidation";
import {
  IAddOrganization,
  ISettingOrganization,
  IUpdateOrganization,
  OrganizationFormValues,
} from "./slice/types";
import ProfileModal from "app/components/ProfileModal";
import { addTimestampToUrl } from "helper/helperFunctions";
import { addOrganizationValidationSchema } from "app/components/Form/Validation/addOrganizationValidation";

export function Settings() {
  const defaultLayout = useSelector(selectDefaultLayoutState);
  const dispatch = useDispatch();
  const { actions } = useSettingSlice();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const userRole = defaultLayout.user?.role;

  useEffect(() => {
    setSelectedIndex(
      location.pathname.includes("users")
        ? 1
        : location.pathname.includes("organization")
        ? 2
        : 0
    );
    if (location.pathname.includes("users")) {
      dispatch(actions.listUsers());
    }
  }, [location.pathname, dispatch, actions]);

  const handleListItemClick = (index: number) => {
    setSelectedIndex(index);
    navigate(
      index === 0
        ? "/settings"
        : index === 1
        ? "/settings/users"
        : "/settings/organization"
    );
  };

  return (
    <Box sx={{ display: "flex", height: "calc(100vh - 64px)", width: "100%" }}>
      <Grid container>
        <Grid item xs={3} sx={{ borderRight: "1px solid #e0e0e0" }}>
          <List component="nav" aria-label="settings options">
            <ListItem
              button
              selected={selectedIndex === 0}
              onClick={() => handleListItemClick(0)}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "transparent",
                  "& .MuiListItemText-primary": {
                    color: theme.palette.primary.main,
                    fontWeight: "bold",
                  },
                },
              }}
            >
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary="Account" />
            </ListItem>
            {(userRole === "super_user" || userRole === "admin") && (
              <>
                <ListItem
                  button
                  selected={selectedIndex === 1}
                  onClick={() => handleListItemClick(1)}
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: "transparent",
                      "& .MuiListItemText-primary": {
                        color: theme.palette.primary.main,
                        fontWeight: "bold",
                      },
                    },
                  }}
                >
                  <ListItemIcon>
                    <PeopleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Users" />
                </ListItem>
                <ListItem
                  button
                  selected={selectedIndex === 2}
                  onClick={() => handleListItemClick(2)}
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: "transparent",
                      "& .MuiListItemText-primary": {
                        color: theme.palette.primary.main,
                        fontWeight: "bold",
                      },
                    },
                  }}
                >
                  <ListItemIcon>
                    <BusinessIcon />
                  </ListItemIcon>
                  <ListItemText primary="Organization" />
                </ListItem>
              </>
            )}
          </List>
        </Grid>
        <Grid item xs={9} sx={{ p: 3 }}>
          <Routes>
            <Route path="" element={<SettingsProfile />} />
            {(userRole === "super_user" || userRole === "admin") && (
              <>
                <Route path="users" element={<SettingsUsers />} />
                <Route path="organization" element={<SettingsOrganization />} />
              </>
            )}
          </Routes>
        </Grid>
      </Grid>
    </Box>
  );
}

export function SettingsProfile() {
  const defaultLayout = useSelector(selectDefaultLayoutState);
  const settingState = useSelector(selectSetting);
  const dispatch = useDispatch();
  const { actions } = useSettingSlice();
  const [uploadProfile, setUploadProfile] = React.useState(false);
  const theme = useTheme();

  const handleFileUpload = (file: File) => {
    dispatch(actions.uploadFileStart(file));
  };

  const initialValues: IProfileFormValues = {
    first_name: defaultLayout?.user?.first_name || "",
    last_name: defaultLayout?.user?.last_name || "",
    phone: defaultLayout?.user?.phone || "",
    email: defaultLayout?.user?.id || "",
    pref_language: defaultLayout?.user?.pref_language || "",
    address: defaultLayout.user?.address || "",
  };

  const handleClose = () => {
    setUploadProfile(false);
  };

  return (
    <Box>
      <ProfileModal
        open={uploadProfile}
        handleClose={handleClose}
        handleFileUpload={handleFileUpload}
      />
      <Typography
        gutterBottom
        sx={{
          fontSize: "24px",
          fontWeight: 600,
          color: styledTheme.colors.primary.main,
          mb: 3,
        }}
      >
        Profile Details
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
        <Box sx={{ position: "relative", mr: 3 }}>
          <Avatar
            src={addTimestampToUrl(defaultLayout.user?.image_url!)}
            sx={{ width: 100, height: 100 }}
          />
          <IconButton
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              backgroundColor: "rgba(0, 0, 0, 0.3)",
            }}
            onClick={() => setUploadProfile(true)}
          >
            <CameraAltIcon />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          border: `1px solid ${theme.palette.grey[300]}`,
          borderRadius: theme.shape.borderRadius,
          padding: theme.spacing(4),
          marginTop: theme.spacing(3),
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={profileValidation}
          onSubmit={(values, { setSubmitting }) => {
            dispatch(
              actions.updateUser({
                ...values,
                active_flag: true,
                role: defaultLayout.user?.role || "new",
                is_admin: false,
              })
            );
            setSubmitting(false);
          }}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="first_name"
                    label="First Name"
                    error={touched.first_name && errors.first_name}
                    helperText={touched.first_name && errors.first_name}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="last_name"
                    label="Last Name"
                    error={touched.last_name && errors.last_name}
                    helperText={touched.last_name && errors.last_name}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="phone"
                    label="Phone Number"
                    error={touched.phone && errors.phone}
                    helperText={touched.phone && errors.phone}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="email"
                    label="Email"
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="address"
                    label="Address"
                    error={touched.address && errors.address}
                    helperText={touched.address && errors.address}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LanguageSelect name="pref_language" />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting || settingState.isSettingLoading}
                  >
                    Save Changes
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}

export function SettingsUsers() {
  const settingState = useSelector(selectSetting);
  const defaultLayout = useSelector(selectDefaultLayoutState);
  const dispatch = useDispatch();
  const { actions } = useSettingSlice();
  const role = defaultLayout?.user?.role;
  const organization = defaultLayout?.user?.org_name;
  const [modalOpen, setModalOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState<IUserModel | null>(null);
  const roles = useSelector(selectRoles);
  const theme = useTheme();

  useEffect(() => {
    if (modalOpen) {
      dispatch(actions.getRoles());
    }
  }, [modalOpen, dispatch, actions]);

  const handleEditUser = (username: string) => {
    const listUsers = settingState.users;
    if (listUsers) {
      const filteredUser = listUsers.find((item) => item.id === username);
      setCurrentUser(filteredUser || null);
      setModalOpen(true);
    }
  };

  const handleDeleteUser = (username: string) => {
    const listUsers = settingState.users;
    if (listUsers) {
      const filteredUser = listUsers.find((item) => item.id === username);
      if (filteredUser) {
        dispatch(actions.deleteUser(filteredUser));
      }
    }
  };

  const handleModalClose = () => {
    setCurrentUser(null);
    setModalOpen(false);
  };

  const ModalContent = React.useMemo(() => {
    return (
      <div
        style={{
          width: 400,
          padding: 20,
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <IconButton
          onClick={handleModalClose}
          style={{ alignSelf: "flex-end" }}
        >
          <CloseIcon />
        </IconButton>
        <h2>{currentUser ? "Edit User" : "Add Bulk Users"}</h2>
        <Formik
          initialValues={
            currentUser
              ? {
                  first_name: currentUser.first_name,
                  last_name: currentUser.last_name,
                  phone: currentUser.phone,
                  email: currentUser.id,
                  role: currentUser?.role
                    ? `${
                        currentUser.role.charAt(0).toUpperCase() +
                        currentUser.role.slice(1)
                      }`
                    : "",
                  pref_language: currentUser.pref_language.toUpperCase(),
                  address: currentUser.address,
                }
              : { emails: "", role: "" }
          }
          validationSchema={
            currentUser ? profileValidation : createUserValidationSchema
          }
          onSubmit={(values, { setSubmitting, resetForm }) => {
            console.log("Form submitted", values);
            if (currentUser) {
              const { first_name, last_name, role, address, phone, email } =
                values;
              if (first_name && last_name && role && address && phone && email)
                dispatch(
                  actions.updateUser({
                    ...values,
                    active_flag: true,
                    is_admin: true,
                  })
                );
            } else {
              const emails = values?.emails
                ?.split(",")
                .map((email) => email.trim());
              if (emails)
                dispatch(actions.inviteUser({ emails, role: values.role }));
            }
            setSubmitting(false);
            resetForm();
            handleModalClose();
          }}
        >
          {({ errors, touched, isSubmitting, handleSubmit }) => (
            <Form
              onSubmit={handleSubmit}
              style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
            >
              {currentUser ? (
                <div>
                  <Field
                    name="first_name"
                    as={TextField}
                    label="First Name"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    error={touched.first_name && errors.first_name}
                  />
                  <Field
                    name="last_name"
                    as={TextField}
                    label="Last Name"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    error={touched.last_name && errors.last_name}
                  />
                  <Field
                    name="phone"
                    as={TextField}
                    label="Phone"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    error={touched.phone && errors.phone}
                  />
                  <Field
                    name="email"
                    as={TextField}
                    label="Email"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    disabled
                    error={touched.email && errors.email}
                  />
                  <Field
                    name="role"
                    as={TextField}
                    select
                    label="Role"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    error={touched.role && errors.role}
                  >
                    {roles.map((role) => (
                      <MenuItem
                        key={role.id}
                        value={`${
                          role.name.charAt(0).toUpperCase() + role.name.slice(1)
                        }`}
                      >
                        {`${
                          role.name.charAt(0).toUpperCase() + role.name.slice(1)
                        }`}
                      </MenuItem>
                    ))}
                  </Field>
                  <Field
                    name="address"
                    as={TextField}
                    label="Address"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    error={touched.address && errors.address}
                  />
                  <Spacer mb={2} />
                  <LanguageSelect name="pref_language" />
                  <Spacer mb={4} />
                </div>
              ) : (
                <>
                  <Field
                    name="emails"
                    as={TextField}
                    label="Emails (comma-separated)"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    multiline
                    rows={4}
                    error={touched.emails && errors.emails}
                    helperText={touched.emails && errors.emails}
                  />
                  <Field
                    name="role"
                    as={TextField}
                    select
                    label="Role"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    error={touched.role && errors.role}
                    helperText={touched.role && errors.role}
                  >
                    {roles.map((role) => (
                      <MenuItem key={role.id} value={role.name}>
                        {role.name}
                      </MenuItem>
                    ))}
                  </Field>
                </>
              )}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting || role === "new"}
                style={{ marginTop: "auto" }}
              >
                {currentUser ? "Update" : "Submit"}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    );
  }, [currentUser, roles, dispatch, actions, handleModalClose]);

  return (
    <Container
      sx={{
        "&.MuiContainer-root": {
          maxWidth: "unset",
        },
      }}
    >
      <CustomModal open={modalOpen} handleClose={handleModalClose}>
        {ModalContent}
      </CustomModal>

      <Paper>
        <Box
          sx={{
            p: 3,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Org. Name: {organization}</Typography>
          {(role === "admin" || role === "super_user") && (
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setModalOpen(true)}
            >
              Add User
            </Button>
          )}
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: theme.palette.grey[100],
                  borderBottom: `2px solid ${theme.palette.divider}`,
                }}
              >
                <TableCell
                  sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
                >
                  First Name
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
                >
                  Last Name
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
                >
                  Email
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
                >
                  Phone
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
                >
                  Address
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
                >
                  Pref Language
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
                >
                  Role
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
                >
                  Is Active
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {settingState.users?.map((user, index) => (
                <TableRow
                  key={user.id}
                  sx={{
                    "&:nth-of-type(even)": {
                      backgroundColor: theme.palette.action.hover,
                    },
                    borderBottom: `1px solid ${theme.palette.divider}`,
                  }}
                >
                  <TableCell>{user.first_name}</TableCell>
                  <TableCell>{user.last_name}</TableCell>
                  <TableCell>{user.id}</TableCell>
                  <TableCell>{user.phone}</TableCell>
                  <TableCell>{user.address}</TableCell>
                  <TableCell>{user.pref_language}</TableCell>
                  <TableCell>{user.role}</TableCell>
                  <TableCell>
                    <Chip
                      label={user.active_flag ? "Active" : "Inactive"}
                      color={user.active_flag ? "success" : "error"}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => handleEditUser(user.id!)}
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDeleteUser(user.id!)}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
}

export function SettingsOrganization() {
  const dispatch = useDispatch();
  const { actions } = useSettingSlice();
  const settingState = useSelector(selectSetting);
  const [showSecrets, setShowSecrets] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentOrganization, setCurrentOrganization] =
    useState<ISettingOrganization | null>(null);
  const theme = useTheme();

  React.useEffect(() => {
    dispatch(actions.listOrganizations());
  }, []);

  const toggleSecretVisibility = () => {
    setShowSecrets(!showSecrets);
  };

  const handleEditOrganization = (orgId) => {
    const organization = settingState?.organizations?.find(
      (org) => org.id === orgId
    );
    if (organization) setCurrentOrganization(organization);
    setModalOpen(true);
  };

  const handleAddOrganization = () => {
    setCurrentOrganization(null);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setCurrentOrganization(null);
    setModalOpen(false);
  };

  const handleSubmit = (values: OrganizationFormValues, { setSubmitting }) => {
    if (currentOrganization) {
      const updateValues: IUpdateOrganization = {
        id: values.id,
        name: values.name,
        email: values.email,
        anthropic_api_key: values.anthropic_api_key,
        aws_secret_key: values.aws_secret_key,
        aws_access_key: values.aws_access_key,
        cloudinary_api_key: values.cloudinary_api_key,
        cloudinary_api_secret: values.cloudinary_api_secret,
        coudinary_cloud_name: values.coudinary_cloud_name,
        deepl_api_key: values.deepl_api_key,
        eleven_labs_api_key: values.eleven_labs_api_key,
        is_active: values.ActiveFlag,
        openai_api_key: values.openai_api_key,
        perplexity_api_key: values.perplexity_api_key,
        ses_password: values.ses_password,
        ses_region: values.ses_region,
        ses_username: values.ses_username,
      };
      dispatch(actions.updateOrganization(updateValues));
    } else {
      const addValues: IAddOrganization = {
        name: values.name,
        email: values.email,
        anthropic_api_key: values.anthropic_api_key,
        aws_secret_key: values.aws_secret_key,
        aws_access_key: values.aws_access_key,
        cloudinary_api_key: values.cloudinary_api_key,
        cloudinary_api_secret: values.cloudinary_api_secret,
        coudinary_cloud_name: values.coudinary_cloud_name,
        deepl_api_key: values.deepl_api_key,
        eleven_labs_api_key: values.eleven_labs_api_key,
        openai_api_key: values.openai_api_key,
        perplexity_api_key: values.perplexity_api_key,
        ses_password: values.ses_password,
        ses_region: values.ses_region,
        ses_username: values.ses_username,
        root_user_email: values.root_user_email!,
        root_user_first_name: values.root_user_first_name!,
        root_user_lastname: values.root_user_lastname!,
        root_user_pref_language: values.root_user_pref_language!,
        root_user_phone: values.root_user_phone!,
        root_user_password: values.root_user_password!,
      };
      dispatch(actions.addOrganization(addValues));
    }
    setSubmitting(false);
    handleModalClose();
  };

  const ModalContent = (
    <Box
      sx={{
        width: "800px",
        overflowY: "auto",
        overflowX: "hidden",
        maxHeight: "900px",
        p: 4,
      }}
    >
      <Typography sx={{ mb: 3, fontSize: "24px", fontWeight: 600 }}>
        {currentOrganization ? "Edit Organization" : "Add Organization"}
      </Typography>
      <Formik<OrganizationFormValues>
        initialValues={
          currentOrganization
            ? {
                ...currentOrganization,
                root_user_email: "",
                root_user_first_name: "",
                root_user_lastname: "",
                root_user_pref_language: "",
                root_user_phone: "",
                root_user_password: "",
              }
            : {
                id: "",
                name: "",
                email: "",
                address: "",
                ActiveFlag: false,
                RootFlag: false,
                aws_access_key: "",
                aws_secret_key: "",
                ses_region: "",
                ses_username: "",
                ses_password: "",
                openai_api_key: "",
                coudinary_cloud_name: "",
                cloudinary_api_key: "",
                cloudinary_api_secret: "",
                deepl_api_key: "",
                eleven_labs_api_key: "",
                anthropic_api_key: "",
                perplexity_api_key: "",
                image: "",
                created_by: "",
                created_date: "",
                modified_by: "",
                modified_date: "",
                root_user_email: "",
                root_user_first_name: "",
                root_user_lastname: "",
                root_user_pref_language: "",
                root_user_phone: "",
                root_user_password: "",
              }
        }
        validationSchema={
          currentOrganization
            ? organizationValidationSchema
            : addOrganizationValidationSchema
        }
        onSubmit={handleSubmit}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  fullWidth
                  name="name"
                  label="Organization Name"
                  error={touched.name && errors.name}
                  helperText={touched.name && errors.name}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  fullWidth
                  name="email"
                  label="Organization Email"
                  error={touched.email && errors.email}
                  helperText={touched.email && errors.email}
                />
              </Grid>

              <Grid item xs={12}>
                <Divider sx={{ my: 3 }} />
                <Typography variant="h6" sx={{ mb: 2 }}>
                  AWS Configuration
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  fullWidth
                  name="aws_access_key"
                  label="AWS Access Key"
                  type={showSecrets ? "text" : "password"}
                  error={touched.aws_access_key && errors.aws_access_key}
                  helperText={touched.aws_access_key && errors.aws_access_key}
                  InputProps={{
                    endAdornment: (
                      <Tooltip title={showSecrets ? "Hide" : "Show"}>
                        <IconButton onClick={toggleSecretVisibility}>
                          {showSecrets ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  fullWidth
                  name="aws_secret_key"
                  label="AWS Secret Key"
                  type={showSecrets ? "text" : "password"}
                  error={touched.aws_secret_key && errors.aws_secret_key}
                  helperText={touched.aws_secret_key && errors.aws_secret_key}
                  InputProps={{
                    endAdornment: (
                      <Tooltip title={showSecrets ? "Hide" : "Show"}>
                        <IconButton onClick={toggleSecretVisibility}>
                          {showSecrets ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Divider sx={{ my: 3 }} />
                <Typography variant="h6" sx={{ mb: 2 }}>
                  SES Configuration
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  as={TextField}
                  fullWidth
                  name="ses_region"
                  label="SES Region"
                  error={touched.ses_region && errors.ses_region}
                  helperText={touched.ses_region && errors.ses_region}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  as={TextField}
                  fullWidth
                  name="ses_username"
                  label="SES Username"
                  error={touched.ses_username && errors.ses_username}
                  helperText={touched.ses_username && errors.ses_username}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  as={TextField}
                  fullWidth
                  name="ses_password"
                  label="SES Password"
                  type={showSecrets ? "text" : "password"}
                  error={touched.ses_password && errors.ses_password}
                  helperText={touched.ses_password && errors.ses_password}
                  InputProps={{
                    endAdornment: (
                      <Tooltip title={showSecrets ? "Hide" : "Show"}>
                        <IconButton onClick={toggleSecretVisibility}>
                          {showSecrets ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Divider sx={{ my: 3 }} />
                <Typography variant="h6" sx={{ mb: 2 }}>
                  API Keys
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  fullWidth
                  name="openai_api_key"
                  label="OpenAI API Key"
                  type={showSecrets ? "text" : "password"}
                  error={touched.openai_api_key && errors.openai_api_key}
                  helperText={touched.openai_api_key && errors.openai_api_key}
                  InputProps={{
                    endAdornment: (
                      <Tooltip title={showSecrets ? "Hide" : "Show"}>
                        <IconButton onClick={toggleSecretVisibility}>
                          {showSecrets ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  fullWidth
                  name="deepl_api_key"
                  label="DeepL API Key"
                  type={showSecrets ? "text" : "password"}
                  error={touched.deepl_api_key && errors.deepl_api_key}
                  helperText={touched.deepl_api_key && errors.deepl_api_key}
                  InputProps={{
                    endAdornment: (
                      <Tooltip title={showSecrets ? "Hide" : "Show"}>
                        <IconButton onClick={toggleSecretVisibility}>
                          {showSecrets ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Divider sx={{ my: 3 }} />
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Cloudinary Configuration
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  as={TextField}
                  fullWidth
                  name="coudinary_cloud_name"
                  label="Cloudinary Cloud Name"
                  error={
                    touched.coudinary_cloud_name && errors.coudinary_cloud_name
                  }
                  helperText={
                    touched.coudinary_cloud_name && errors.coudinary_cloud_name
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  as={TextField}
                  fullWidth
                  name="cloudinary_api_key"
                  label="Cloudinary API Key"
                  type={showSecrets ? "text" : "password"}
                  error={
                    touched.cloudinary_api_key && errors.cloudinary_api_key
                  }
                  helperText={
                    touched.cloudinary_api_key && errors.cloudinary_api_key
                  }
                  InputProps={{
                    endAdornment: (
                      <Tooltip title={showSecrets ? "Hide" : "Show"}>
                        <IconButton onClick={toggleSecretVisibility}>
                          {showSecrets ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  as={TextField}
                  fullWidth
                  name="cloudinary_api_secret"
                  label="Cloudinary API Secret"
                  type={showSecrets ? "text" : "password"}
                  error={
                    touched.cloudinary_api_secret &&
                    errors.cloudinary_api_secret
                  }
                  helperText={
                    touched.cloudinary_api_secret &&
                    errors.cloudinary_api_secret
                  }
                  InputProps={{
                    endAdornment: (
                      <Tooltip title={showSecrets ? "Hide" : "Show"}>
                        <IconButton onClick={toggleSecretVisibility}>
                          {showSecrets ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
              {!currentOrganization && (
                <>
                  <Grid item xs={12}>
                    <Divider sx={{ my: 3 }} />
                    <Typography variant="h6" sx={{ mb: 2 }}>
                      Root User Details
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      as={TextField}
                      fullWidth
                      name="root_user_email"
                      label="Root User Email"
                      error={touched.root_user_email && errors.root_user_email}
                      helperText={
                        touched.root_user_email && errors.root_user_email
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      as={TextField}
                      fullWidth
                      name="root_user_first_name"
                      label="Root User First Name"
                      error={
                        touched.root_user_first_name &&
                        errors.root_user_first_name
                      }
                      helperText={
                        touched.root_user_first_name &&
                        errors.root_user_first_name
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      as={TextField}
                      fullWidth
                      name="root_user_lastname"
                      label="Root User Last Name"
                      error={
                        touched.root_user_lastname && errors.root_user_lastname
                      }
                      helperText={
                        touched.root_user_lastname && errors.root_user_lastname
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      as={TextField}
                      fullWidth
                      name="root_user_phone"
                      label="Root User Phone"
                      error={touched.root_user_phone && errors.root_user_phone}
                      helperText={
                        touched.root_user_phone && errors.root_user_phone
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      as={TextField}
                      fullWidth
                      name="root_user_password"
                      label="Root User Password"
                      type="password"
                      error={
                        touched.root_user_password && errors.root_user_password
                      }
                      helperText={
                        touched.root_user_password && errors.root_user_password
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <LanguageSelect name="root_user_pref_language" />
                  </Grid>
                </>
              )}
            </Grid>
            <Box sx={{ mt: 4, display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                {currentOrganization ? "Update" : "Add"} Organization
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );

  return (
    <Paper
      sx={{
        mt: 3,
        maxHeight: "calc(100vh - 200px)",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 3,
        }}
      >
        <Typography
          gutterBottom
          sx={{
            fontSize: "24px",
            fontWeight: 600,
            color: styledTheme.colors.primary.main,
          }}
        >
          Organizations
        </Typography>

        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleAddOrganization}
        >
          Add Organization
        </Button>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: theme.palette.grey[100],
                borderBottom: `2px solid ${theme.palette.divider}`,
              }}
            >
              <TableCell
                sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
              >
                Name
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
              >
                Email
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
              >
                Address
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
              >
                Is Active
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {settingState.organizations?.map((org, index) => (
              <TableRow
                key={org.id}
                sx={{
                  "&:nth-of-type(even)": {
                    backgroundColor: theme.palette.action.hover,
                  },
                  borderBottom: `1px solid ${theme.palette.divider}`,
                }}
              >
                <TableCell>{org.name}</TableCell>
                <TableCell>{org.email}</TableCell>
                <TableCell>{org.address}</TableCell>
                <TableCell>
                  <Chip
                    label={org.ActiveFlag ? "Active" : "Inactive"}
                    color={org.ActiveFlag ? "success" : "error"}
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => handleEditOrganization(org.id)}
                    color="primary"
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomModal open={modalOpen} handleClose={handleModalClose}>
        {ModalContent}
      </CustomModal>
    </Paper>
  );
}
