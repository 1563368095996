import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import IconButton from "@mui/material/IconButton";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import { CustomModal } from "app/components/Core";
import { ICourseFormValue } from "app/pages/Courses/types";
import ModuleController from "app/components/Card/ModuleModal/ModuleForm";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import Menu, { MenuProps } from "@mui/material/Menu";
import CircularProgress from "@mui/material/CircularProgress";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { useModuleSlice } from "app/pages/Workplace/slice";
import ImageIcon from "@mui/icons-material/Image";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IModuleEdit } from "./type";
import { ImageViewerModal } from "app/components/ImageViewerModal";
import { Spinner } from "assets/images";
import { selectDownloadingObject } from "app/pages/Workplace/slice/selector";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LoadingText from "app/components/Loading";
import PDFViewer from "app/components/PDFViewer";
import {
  selectGlobalLanguage,
  selectUser,
} from "app/pages/DefaultLayout/slice/selectors";
import {
  selectCourse,
  selectGettingCousrse,
  selectModule,
} from "./slice/selector";
import { useCourseDetailSlice } from "./slice";
import { CourseModuleLoading } from "app/components/CourseModuleLoading";
interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (values: ICourseFormValue) => void;
  initialFormValues: ICourseFormValue;
  title: string;
  elementType: string;
}

export const CreateModuleModal = (props: IProps) => {
  return (
    <CustomModal open={props.isOpen} handleClose={props.handleClose}>
      <ModuleController
        title={props.title}
        elementType={props.elementType}
        initialValues={props.initialFormValues}
        onSubmit={props.handleSubmit}
      />
    </CustomModal>
  );
};

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&::before": {
    display: "none",
  },
  borderRadius: "12px",
  width: "100%",
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: "#f0f7ff",
  },
  marginBottom: "10px",
  // height: "70px",
}));

interface IExtendedAccordionSummaryProps extends AccordionSummaryProps {
  isCourseLoading?: boolean;
}

const AccordionSummary = styled(
  ({ isCourseLoading, ...rest }: IExtendedAccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...rest}
    />
  )
)(({ theme, isCourseLoading }) => ({
  position: "relative",
  // height: "100%",
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  "&:hover": {
    "& .actionItems": {
      display: isCourseLoading ? "none" : "flex",
    },
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    boxShadow:
      "0px 5px 5px -3px rgb(253 253 253 / 20%), 0px 8px 10px 1px rgb(255 255 255 / 14%), 0px 3px 14px 2px rgb(137 129 129 / 12%) !important",
  },
}));

export function Course() {
  const loggedInUser = useSelector(selectUser);
  const role = loggedInUser?.role;
  const [createModuleOpen, setCreateModuelOpen] = React.useState(false);
  const [imageViewerOpen, setImageViewerOpen] = React.useState(false);
  const [itemType, setItemType] = React.useState<"isPpt" | "isPdf" | "isImage">(
    "isImage"
  );
  const [editInitialValues, setEditInitialValues] = React.useState<{
    title: string;
    description?: string;
  }>({
    title: "",
    description: "",
  });
  const globalLanguage = useSelector(selectGlobalLanguage);
  const [isAdd, setIsAdd] = React.useState(true);
  const [topicId, setTopicId] = React.useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { actions } = useCourseDetailSlice();
  const { actions: moduleActions } = useModuleSlice();
  const isDownloadingItem = useSelector(selectDownloadingObject);
  const course = useSelector(selectCourse);
  const storeModule = useSelector(selectModule);
  const isCourseLoading = useSelector(selectGettingCousrse);
  const [element, setElement] = React.useState<{
    elName: string;
    elId: string;
    sequence_no?: number;
  }>({
    elName: "",
    elId: "",
  });
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const number_of_items = course?.modules?.length;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  React.useEffect(() => {
    if (id)
      dispatch(actions.getCourse({ course_id: id, language: globalLanguage! }));
    return () => {
      dispatch(actions.clearModule());
    };
  }, [id, globalLanguage]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, topicId) => {
    setAnchorEl(event.currentTarget);
    setTopicId(topicId);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (panel: { id: string }) => {
    return (event: React.SyntheticEvent, newExpanded: boolean) => {
      if (newExpanded) dispatch(actions.getModule(panel.id));
      setExpanded(newExpanded ? panel.id : false);
    };
  };

  const handleDelete = (module_id: string, isModule: boolean) => {
    if (isModule) {
      dispatch(
        actions.deleteModule({
          course_id: id!,
          module_id: module_id,
          language: globalLanguage!,
        })
      );
    } else {
      dispatch(
        moduleActions.deleteElement({
          element_id: module_id,
          element_name: "Topic",
        })
      );
      setAnchorEl(null);
      setExpanded(false);
    }
  };

  const handleUpdateSequence = (
    updateParams: {
      moduleId: string;
      seq_no: number;
    },
    isModule
  ) => {
    if (isModule) {
      dispatch(
        actions.updateModuleSequence({
          course_id: id!,
          module_id: updateParams.moduleId,
          module_sequence_number: updateParams.seq_no,
        })
      );
    }
  };

  const handleSubmit = (values: ICourseFormValue) => {
    if (isAdd) {
      dispatch(
        actions.createModule({
          title: values.title,
          course_id: id!,
        })
      );
    } else {
      if (topicId) {
        dispatch(
          moduleActions.updateElement({
            element_id: element.elId,
            element_name: element.elName,
            title: values.title,
          })
        );
      } else {
        dispatch(
          actions.updateModuleTitle({
            title: values.title,
            course_id: element.elId,
            description: values.description!,
            language: globalLanguage!,
          })
        );
      }
    }
    setCreateModuelOpen(false);
  };

  const handleOpenCreateModuleModal = (add, _module?: IModuleEdit) => {
    if (add) {
      setIsAdd(true);
    } else {
      setIsAdd(false);
      if (_module?.element.element_type == "module")
        setEditInitialValues({
          title: _module?.title!,
          description: _module?.description,
        });
      else
        setEditInitialValues({
          title: _module?.title!,
          description: undefined,
        });
      setElement({
        elId: _module?.element.element_id!,
        elName: _module?.element.element_type!,
      });
    }
    setAnchorEl(null);
    setExpanded(false);
    setCreateModuelOpen(true);
  };

  const handleUpdate = (isIncrement, current_seq, module_id, isModule) => {
    if (isModule) {
      if (course?.modules?.length) {
        if (number_of_items) {
          if (isIncrement && current_seq < Math.max(number_of_items, 0)) {
            handleUpdateSequence(
              {
                moduleId: module_id,
                seq_no: current_seq + 1,
              },
              true
            );
          } else if (!isIncrement && current_seq > 1) {
            handleUpdateSequence(
              {
                moduleId: module_id,
                seq_no: current_seq - 1,
              },
              true
            );
          }
        }
      }
    } else {
      if (isIncrement) {
        handleUpdateSequence(
          {
            moduleId: module_id,
            seq_no: ++current_seq,
          },
          false
        );
      } else {
        handleUpdateSequence(
          {
            moduleId: module_id,
            seq_no: --current_seq,
          },
          false
        );
      }
    }
  };

  const handleOpenImageViewerModal = (type: "isPpt" | "isPdf" | "isImage") => {
    if (type == "isImage") {
      setItemType(type);
      setImageViewerOpen(true);
    }
    if (type == "isPdf") {
      window.open(storeModule?.pdf_url, "_blank");
    } else if (type == "isPpt") {
      window.open(storeModule?.ppt_url, "_blank");
    }
  };

  const handleCloseImageViewerModal = () => {
    setImageViewerOpen(false);
  };

  const handleRedirect = (topic_id) => {
    dispatch(actions.clearModule());
    navigate(
      `/module/${storeModule?.module_id}?isFromCourseDetail=T&topicId=${topic_id}`
    );
  };

  const handleDownloadFile = (itemId, itemType) => {
    dispatch(
      moduleActions.downloadFile({
        itemId,
        itemType,
        isCourseDetails: true,
      })
    );
  };

  const handleCloseCreateModuleModal = () => {
    setCreateModuelOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100vh",
        width: "100%",
        paddingTop: 4,
        paddingLeft: "24px",
      }}
    >
      <Box
        sx={{
          display: `${isDownloadingItem ? "flex" : "none"}`,
          zIndex: "2000",
          position: "absolute",
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
          background: "rgba(0, 0, 0, 0.7) !important",
          justifyContent: "center",
          alignItems: "center",
          direction: "column",
        }}
      >
        <Typography
          sx={{
            fontSize: "54px",
            fontWeight: 700,
            pt: 6,
            pb: 4,
            color: "#ffffff",
          }}
        >
          {isDownloadingItem ? "Please wait!" : "Saving, Please wait!"}
        </Typography>
        <img src={Spinner} alt="Loading" />
      </Box>
      <CreateModuleModal
        isOpen={createModuleOpen}
        elementType={element.elName}
        handleClose={handleCloseCreateModuleModal}
        handleSubmit={handleSubmit}
        title={isAdd ? "Create" : "Update"}
        initialFormValues={isAdd ? { title: "" } : editInitialValues}
      />
      <ImageViewerModal
        itemType={itemType}
        isOpen={imageViewerOpen}
        module={storeModule}
        handleClose={handleCloseImageViewerModal}
      />
      <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
        <Box sx={{ flex: 1, paddingRight: "8%" }}>
          <Grid container rowSpacing={3}>
            <Grid container item xs={12}>
              <Button
                onClick={() => {
                  navigate(-1);
                }}
                variant="text"
                startIcon={<NavigateBeforeIcon />}
              >
                <Typography>Go Back</Typography>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">{course?.title}</Typography>
              <Stack direction="row" justifyContent="space-between">
                <Typography
                  sx={{
                    background: "transparent",
                    color: "#6b7a90",
                    fontSize: "0.6875rem",
                    fontWeight: "700",
                    textTransform: "uppercase",
                    lineHeight: "1",
                    letterSpacing: "0.08rem",
                    marginY: "auto",
                  }}
                >
                  Modules
                </Typography>
                {(role == "admin" ||
                  role == "editor" ||
                  role == "super_user") && (
                  <Button
                    onClick={() => handleOpenCreateModuleModal(true)}
                    component="label"
                    variant="text"
                    startIcon={<AddIcon />}
                  >
                    New
                  </Button>
                )}
              </Stack>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sx={{
                maxHeight: "calc(100vh - 200px)",
                overflow: "auto",
              }}
            >
              {course?.modules?.map((module) => (
                <Accordion
                  key={module.id}
                  expanded={expanded === module.id}
                  onChange={handleChange({
                    id: module.id,
                  })}
                >
                  <AccordionSummary
                    isCourseLoading={isCourseLoading}
                    aria-controls="panel3d-content"
                    id="panel3d-header"
                  >
                    {(role == "admin" ||
                      role == "editor" ||
                      role == "super_user") && (
                      <Box
                        className="actionItems"
                        sx={{
                          position: "absolute",
                          display: "none",
                          right: "2px",
                          top: "2px",
                          height: "30px",
                          borderRadius: "0px 8px 0px 0px",
                          width: "300px",
                          backgroundColor: "#CCD3D3D3",
                        }}
                      >
                        <Grid direction="column" container sx={{ width: 1 }}>
                          <Grid item xs>
                            <Tooltip title="Module Details">
                              <IconButton
                                onClick={() => {
                                  navigate(`/module/${module.id}`);
                                }}
                                size="small"
                              >
                                <VisibilityIcon fontSize="inherit" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item xs>
                            <Tooltip title="Generate Image">
                              <IconButton
                                onClick={(ev) => {
                                  ev.stopPropagation();
                                  handleDownloadFile(module.id, "isImage");
                                }}
                                size="small"
                              >
                                <ImageIcon fontSize="inherit" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item xs>
                            <Tooltip title="Generate PPT">
                              <IconButton
                                onClick={(ev) => {
                                  ev.stopPropagation();
                                  handleDownloadFile(module.id, "isPpt");
                                }}
                                size="small"
                              >
                                <ListAltIcon fontSize="inherit" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item xs>
                            <Tooltip title="Generate PDF">
                              <IconButton
                                onClick={(ev) => {
                                  ev.stopPropagation();
                                  handleDownloadFile(module.id, "isPdf");
                                }}
                                size="small"
                              >
                                <PictureAsPdfIcon fontSize="inherit" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item xs>
                            <Tooltip title="Move Up">
                              <IconButton
                                onClick={(ev) => {
                                  ev.stopPropagation();
                                  handleUpdate(
                                    false,
                                    module.sequence_num,
                                    module.id,
                                    true
                                  );
                                }}
                                size="small"
                              >
                                <ArrowUpwardIcon fontSize="inherit" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item xs>
                            <Tooltip title="Move Down">
                              <IconButton
                                onClick={(ev) => {
                                  ev.stopPropagation();
                                  handleUpdate(
                                    true,
                                    module.sequence_num,
                                    module.id,
                                    true
                                  );
                                }}
                                size="small"
                              >
                                <ArrowDownwardIcon fontSize="inherit" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item xs>
                            <Tooltip title="Edit">
                              <IconButton
                                onClick={(ev) => {
                                  ev.stopPropagation();
                                  handleOpenCreateModuleModal(false, {
                                    element: {
                                      element_type: "module",
                                      element_id: module.id,
                                    },
                                    title: module.title,
                                    description: module.description,
                                  });
                                  setTopicId("");
                                }}
                                size="small"
                              >
                                <EditIcon fontSize="inherit" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item xs>
                            <Tooltip title="Delete">
                              <IconButton
                                onClick={(ev) => {
                                  ev.stopPropagation();
                                  handleDelete(module.id, true);
                                }}
                                size="small"
                              >
                                <ClearIcon fontSize="inherit" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                    <Typography>
                      {isCourseLoading ? <LoadingText /> : module.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid
                      container
                      sx={{ paddingTop: 2 }}
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {isCourseLoading ? (
                        <CircularProgress />
                      ) : (
                        <>
                          {Object.values(storeModule?.topics || {})?.length ? (
                            <>
                              {Object.keys(storeModule?.topics || {})?.map(
                                (topic_id: any) => (
                                  <Grid
                                    container
                                    key={topic_id}
                                    item
                                    xs={12}
                                    alignItems="baseline"
                                  >
                                    <Grid item xs={1}>
                                      <FormatListBulletedIcon />
                                    </Grid>
                                    <Grid
                                      item
                                      container
                                      justifyContent={"space-between"}
                                      xs
                                    >
                                      <Typography
                                        sx={{
                                          display: "inline",
                                          fontWeight: 400,
                                          fontSize: "1rem",
                                          lineHeight: "1.5",
                                          letterSpacing: "0.00938em",
                                          color: "#1976d2",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          handleRedirect(topic_id);
                                        }}
                                      >
                                        {storeModule?.topics?.[topic_id]
                                          ?.title || "NA"}
                                      </Typography>
                                      {(role == "admin" ||
                                        role == "editor" ||
                                        role == "super_user") && (
                                        <IconButton
                                          id="long-button"
                                          onClick={(event) =>
                                            handleClick(event, topic_id)
                                          }
                                        >
                                          <MoreVertIcon />
                                        </IconButton>
                                      )}
                                    </Grid>
                                    <StyledMenu
                                      id="long-menu"
                                      anchorEl={anchorEl}
                                      open={open}
                                      onClose={handleClose}
                                    >
                                      <MenuItem
                                        selected={true}
                                        onClick={() => {
                                          handleOpenCreateModuleModal(false, {
                                            element: {
                                              element_id: topicId,
                                              element_type: "topics",
                                            },
                                            title:
                                              storeModule?.topics?.[topicId]
                                                ?.title!,
                                          });
                                        }}
                                      >
                                        <ListItemIcon>
                                          <EditIcon />
                                        </ListItemIcon>
                                        edit
                                      </MenuItem>
                                      <MenuItem
                                        selected={false}
                                        onClick={() => {
                                          handleDelete(topicId, false);
                                        }}
                                      >
                                        <ListItemIcon>
                                          <DeleteIcon />
                                        </ListItemIcon>
                                        delete
                                      </MenuItem>
                                    </StyledMenu>
                                  </Grid>
                                )
                              )}
                            </>
                          ) : (
                            <Typography>No Topics</Typography>
                          )}
                        </>
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            marginTop: "-334.047px",
            position: "relative",
            flex: "0 0 42%",
            marginLeft: "auto",
            padding: "0px 10px",
            zIndex: 2,
          }}
        >
          <Box
            sx={{
              boxSizing: "border-box",
              position: "fixed",
              top: "121px",
              bottom: "0px",
              width: "360px",
              background: "#ffffff",
            }}
          >
            <Box
              sx={{
                boxShadow: "0 32px 54px 0 rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
                border: 0,
                overflow: "hidden",
                backgroundColor: "transparent",
                height: "calc(100vh - 121px)",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Grid
                container
                direction="row"
                rowSpacing={2}
                sx={{
                  padding: "10px 30px",
                  overflowY: "auto",
                  maxHeight: "100%",
                }}
              >
                <Grid item xs={12}>
                  {expanded ? (
                    <Stack direction="row" justifyContent={"space-between"}>
                      <Typography variant="h5">
                        {isCourseLoading ? (
                          <LoadingText />
                        ) : (
                          storeModule?.module_title
                        )}
                      </Typography>
                    </Stack>
                  ) : (
                    <Typography variant="h5">{course?.title}</Typography>
                  )}
                </Grid>
                {!expanded && (
                  <>
                    <Grid item xs={12}>
                      {course?.image && (
                        <Box
                          sx={{
                            width: "100%",
                            height: "200px",
                            borderRadius: "10px",
                            overflow: "hidden",
                            marginBottom: "20px",
                          }}
                        >
                          <img
                            src={course.image}
                            alt={course.title}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </Box>
                      )}
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <Typography variant="body1">
                    {expanded
                      ? storeModule?.module_description
                      : course?.description}
                  </Typography>
                </Grid>
                {!expanded && (
                  <Grid container item xs={12}>
                    <Grid item xs={12}>
                      <Typography variant="h5">Course Includes</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">
                        {course?.modules?.length} modules
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {expanded && (
                  <Grid container sx={{ py: 3 }} direction={"column"}>
                    <Typography gutterBottom variant="h6" align="center">
                      Module Files
                    </Typography>
                    <Stack direction="column" spacing={2}>
                      <MuiAccordion
                        sx={{ "&.MuiPaper-root": { marginTop: "8px" } }}
                      >
                        <MuiAccordionSummary
                          expandIcon={<ExpandMore />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          Module Image
                        </MuiAccordionSummary>
                        <MuiAccordionDetails>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              rowGap: 2,
                            }}
                          >
                            {storeModule?.module_image_url !== "NA" ? (
                              <Box
                                onClick={() =>
                                  handleOpenImageViewerModal("isImage")
                                }
                                sx={{
                                  border: "2px solid #e0e0e0",
                                  borderRadius: "12px",
                                  cursor: "pointer",
                                  height: "200px",
                                  width: "240px",
                                }}
                              >
                                <img
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    borderRadius: "12px",
                                    objectFit: "cover",
                                  }}
                                  src={storeModule?.module_image_url}
                                  alt={`Module image for ${storeModule?.module_title}`}
                                />
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: " center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <Box
                                  sx={{
                                    borderRadius: "12px",
                                    height: "50px",
                                    width: "64px",
                                  }}
                                >
                                  <img
                                    style={{
                                      height: "100%",
                                      width: "100%",
                                      borderRadius: "12px",
                                    }}
                                    src={
                                      "https://placehold.co/150x100?text=No Img"
                                    }
                                    alt="No image available"
                                  />
                                </Box>
                              </Box>
                            )}
                          </Box>
                        </MuiAccordionDetails>
                      </MuiAccordion>
                      <MuiAccordion
                        sx={{ "&.MuiPaper-root": { marginTop: "8px" } }}
                      >
                        <MuiAccordionSummary
                          expandIcon={<ExpandMore />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          Module PDF
                        </MuiAccordionSummary>
                        <MuiAccordionDetails>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              rowGap: 2,
                            }}
                          >
                            {storeModule?.pdf_url !== "NA" ? (
                              <>
                                <PDFViewer
                                  handleClick={() =>
                                    handleOpenImageViewerModal("isPdf")
                                  }
                                  pdfUrl={storeModule?.pdf_url!}
                                />
                              </>
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: " center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                  rowGap: 2,
                                }}
                              >
                                <Box
                                  sx={{
                                    borderRadius: "12px",
                                    height: "50px",
                                    width: "64px",
                                  }}
                                >
                                  <img
                                    style={{
                                      height: "100%",
                                      width: "100%",
                                      borderRadius: "12px",
                                    }}
                                    src={
                                      "https://placehold.co/150x100?text=No Pdf"
                                    }
                                  ></img>
                                </Box>
                              </Box>
                            )}
                          </Box>
                        </MuiAccordionDetails>
                      </MuiAccordion>
                      <MuiAccordion
                        sx={{ "&.MuiPaper-root": { marginTop: "8px" } }}
                      >
                        <MuiAccordionSummary
                          expandIcon={<ExpandMore />}
                          aria-controls="panel3-content"
                          id="panel3-header"
                        >
                          Module PPT
                        </MuiAccordionSummary>
                        <MuiAccordionDetails>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              rowGap: 2,
                            }}
                          >
                            {storeModule?.ppt_url !== "NA" ? (
                              <Box
                                onClick={() =>
                                  handleOpenImageViewerModal("isPpt")
                                }
                                sx={{
                                  border: "2px solid #e0e0e0",
                                  borderRadius: "12px",
                                  width: "240px",
                                  height: "200px",
                                }}
                              >
                                <img
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    borderRadius: "12px",
                                  }}
                                  src={"https://placehold.co/150x100?text=ppt"}
                                ></img>
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: " center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                  rowGap: 2,
                                }}
                              >
                                <Box
                                  sx={{
                                    borderRadius: "12px",
                                    height: "50px",
                                    width: "64px",
                                  }}
                                >
                                  <img
                                    style={{
                                      height: "100%",
                                      width: "100%",
                                      borderRadius: "12px",
                                    }}
                                    src={
                                      "https://placehold.co/150x100?text=No PPT"
                                    }
                                  ></img>
                                </Box>
                              </Box>
                            )}
                          </Box>
                        </MuiAccordionDetails>
                      </MuiAccordion>
                    </Stack>
                  </Grid>
                )}
              </Grid>
            </Box>
            <Box
              sx={{
                position: "static",
                width: "360px",
                height: "100%",
                display: "block",
                verticalAlign: "baseline",
                float: "none",
                backgroundColor: "#ffffff",
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
