import React, { useEffect, useState, ReactElement } from "react";
import { Dangerous } from "styled-icons/material-outlined";
import { CheckCircle, Close, Help } from "styled-icons/material";
import { ResponsiveValue, TLengthStyledSystem } from "styled-system";
import { Button, Container, CustomModal, Flex, Typography } from "../Core";
import { theme } from "styles/theme/themes";

interface Props {
  open: boolean;
  children?: React.ReactElement<any, any> | React.ReactElement<any, any>[];
  handleClose: () => void;
  command?: () => void;
  title?: string;
  description?: string;
  type: "warning" | "error" | "success" | "info";
  hideIcon?: boolean;
  hideChildren?: boolean;
  dontClose?: boolean;
  width?: string | number | Array<string | number | null> | number[] | string[];
  isLoading?: boolean;
  makeTransparent?: boolean;
  hideActions?: boolean;
  succesIconSize?: number;
  height?: ResponsiveValue<TLengthStyledSystem>;
  borderRadius?: ResponsiveValue<TLengthStyledSystem>;
}

export const WarningModal = ({
  handleClose,
  open,
  command,
  title,
  description,
  type = "success",
  children,
  hideIcon = false,
  hideChildren = false,
  dontClose = false,
  width,
  isLoading,
  makeTransparent = false,
  hideActions = false,
  succesIconSize,
  height,
  borderRadius,
}: Props) => {
  const [modalTheme, setModalTheme] = useState<{
    color: string;
    Icon: ReactElement<any, any> | null;
  }>({
    color: "",
    Icon: null,
  });
  useEffect(() => {
    switch (type) {
      case "success":
        setModalTheme({
          color: theme.colors.primary.main,
          Icon: (
            <CheckCircle
              color={theme.colors.primary.main}
              width={succesIconSize || "30%"}
            />
          ),
        });
        break;
      case "warning":
        setModalTheme({
          color: theme.colors.gold[0],
          Icon: <Dangerous size={35} color={theme.colors.grey[600]} />,
        });
        break;
      case "error":
        setModalTheme({
          color: theme.colors.error.main as string,
          Icon: <Close size={35} color={theme.colors.error.main as string} />,
        });
        break;
      case "info":
        setModalTheme({
          color: theme.colors.primary.light,
          Icon: <Help size={35} color={theme.colors.primary.light} />,
        });
        break;
      default:
        setModalTheme({
          color: theme.colors.secondary.main,
          Icon: <Help size={35} color={theme.colors.secondary.main} />,
        });
    }
  }, [type]);

  const { color, Icon } = modalTheme;
  return (
    <CustomModal
      isTransparent={makeTransparent}
      open={open}
      handleClose={handleClose}
      borderRadius={borderRadius}
    >
      <Flex
        height={height}
        width={width || ["100%", null, 600]}
        p={[2, 3]}
        flexDirection={"column"}
      >
        <Container justifyContent="flex-start" height="auto">
          {!hideIcon && Icon}
          <Typography
            fontWeight={theme.fontWeights[5]}
            fontSize={[3, 6]}
            color={color}
          >
            {title}
          </Typography>
        </Container>
        {hideChildren && children}
        {/** *@Actions */}
        {hideActions ? (
          <></>
        ) : (
          <Flex justifyContent="flex-end" width="100%" alignItems="center">
            <Button
              onClick={handleClose}
              mr={2}
              color={theme.colors.grey[600]}
              variant="status"
              fontSize={[2, 3, 4]}
            >
              Cancel
            </Button>
            <Button
              loading={isLoading}
              onClick={() => {
                command && command();
                !dontClose && handleClose();
              }}
              variant="status"
              bg={color}
              px={[2, 3]}
              fontSize={[2, 3, 4]}
            >
              Ok
            </Button>
          </Flex>
        )}
        {/** ****@END ********** */}
      </Flex>
    </CustomModal>
  );
};
