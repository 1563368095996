import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CalendarViewDayIcon from "@mui/icons-material/CalendarViewDay";
import TableChartIcon from "@mui/icons-material/TableChart";

import { Input } from "../../components/Form/FormField/_Input";
import { CreateCourseModal } from "app/components/CreateCourseModal";
import ModuleModal from "app/components/Card/ModuleModal";
import { DeleteModal } from "app/components/Card/DeleteModule";
import { useCourseSlice } from "./slice";
import { selectCourses, selectModuleById } from "./slice/selectors";
import {
  selectGlobalLanguage,
  selectUser,
} from "../DefaultLayout/slice/selectors";
import { ICourseEdit, ICourseFormValue } from "./types";
import { theme as styledTheme } from "styles/theme/themes";
import {
  throttle,
  truncate,
  turnToHumanReadable,
} from "helper/helperFunctions";

export function Courses() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { actions } = useCourseSlice();
  const loggedInUser = useSelector(selectUser);
  const globalLanguage = useSelector(selectGlobalLanguage);
  const role = loggedInUser?.role;
  const { result, isCourseLoading, courseModalState } =
    useSelector(selectCourses);

  const [alignment, setAlignment] = React.useState<string>("list");
  const [isAdd, setIsAdd] = React.useState<boolean>(true);
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [editInitialValues, setEditInitialValues] = React.useState<{
    title: string;
    description?: string;
  }>({
    title: "",
    description: "",
  });
  const [courseId, setCourseId] = React.useState<string>("");
  const [searchResults, setSearchResults] = React.useState<any[]>([]);
  const [moduleOpen, setModuleOpen] = React.useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] =
    React.useState<boolean>(false);

  const modules = useSelector((state) => selectModuleById(state, courseId));

  React.useEffect(() => {
    dispatch(actions.getCourses(globalLanguage!));
  }, [globalLanguage]);

  const handleInputChange = (event) => {
    const inputText = event.target.value;
    setSearchTerm(inputText);
    throttledSearch(inputText);
  };

  const throttledSearch = throttle((inputText: string) => {
    const results = result
      ?.filter((course) =>
        course.title.toLowerCase().includes(inputText.toLowerCase())
      )
      .map((course) => ({
        course_id: course.id,
        title: course.title,
        course_modified_date: course.modified_date,
        number_of_modules: course.modules.length,
      }));
    if (results) setSearchResults(results);
  }, 300);

  const handleDelete = (courseId: string) => {
    dispatch(actions.deleteCourse(courseId));
    setIsDeleteModalOpen(false);
  };

  const handleClose = () => {
    dispatch(actions.toggleCourseModalState(false));
  };

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);
  };

  const handleClickOpen = (add: boolean, course?: ICourseEdit) => {
    setIsAdd(add);
    if (!add && course) {
      setEditInitialValues({
        title: course.title,
        description: course.description,
      });
      setCourseId(course.course_id);
    }
    dispatch(actions.toggleCourseModalState(true));
  };

  const handleSubmit = (values: ICourseFormValue) => {
    if (isAdd) {
      dispatch(actions.createCourse(values));
    } else {
      dispatch(
        actions.updateCourse({
          title: values.title,
          course_id: courseId,
          description: values.description!,
          language: globalLanguage!,
        })
      );
    }
    handleClose();
  };

  const handleModuleOpen = (courseId: string) => {
    setCourseId(courseId);
    setModuleOpen(true);
  };

  const handleModuleClose = () => {
    setCourseId("");
    setModuleOpen(false);
  };

  const handleDeleteModalOpen = (courseId: string) => {
    setCourseId(courseId);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setCourseId("");
    setIsDeleteModalOpen(false);
  };
  const renderTableView = () => (
    <TableContainer
      component={Paper}
      sx={{ maxHeight: "calc(100vh - 200px)", overflow: "auto" }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="courses table">
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: theme.palette.grey[100],
              borderBottom: `2px solid ${theme.palette.divider}`,
            }}
          >
            <TableCell
              sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
            >
              Course Title
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
            >
              Modules
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
            >
              Description
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
            >
              Last Modified Date
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
            >
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(searchTerm ? searchResults : result || [])?.map((row, index) => {
            const course = row;
            return (
              <TableRow
                key={row.id}
                sx={{
                  "&:nth-of-type(even)": {
                    backgroundColor: theme.palette.action.hover,
                  },
                  borderBottom: `1px solid ${theme.palette.divider}`,
                }}
              >
                <TableCell component="th" scope="row">
                  <Typography
                    sx={{
                      color: theme.palette.primary.main,
                      cursor: "pointer",
                      fontWeight: "medium",
                    }}
                    onClick={() => navigate(`/course/${row.id}`)}
                  >
                    {course?.title}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    sx={{
                      display: "inline",
                      fontWeight: 400,
                      fontSize: "1rem",
                      lineHeight: "1.5",
                      letterSpacing: "0.00938em",
                      color: "#1976d2",
                      cursor: "pointer",
                    }}
                    onClick={() => handleModuleOpen(row.id)}
                  >
                    #{course?.modules?.length}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    {truncate(course?.description, 100)}
                  </Typography>
                </TableCell>
                <TableCell>
                  {turnToHumanReadable(course?.modified_date)}
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex" }}>
                    <IconButton
                      onClick={() =>
                        handleClickOpen(false, {
                          title: course?.title,
                          description: course?.description,
                          course_id: row.id,
                        })
                      }
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDeleteModalOpen(row.id)}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderCardView = () => (
    <Grid
      container
      spacing={3}
      sx={{ maxHeight: "calc(100vh - 200px)", overflow: "auto" }}
    >
      {(searchTerm ? searchResults : result || [])?.map((item) => {
        const course = item;
        return (
          <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
            <Card
              sx={{
                height: "100%",
                borderLeft: `4px solid ${theme.palette.primary.main}`,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography
                  variant="h6"
                  sx={{
                    cursor: "pointer",
                    mb: 2,
                  }}
                  onClick={() => navigate(`/course/${item.id}`)}
                >
                  {truncate(course?.title, 20)}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Modules: {course?.modules?.length}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Last modified: {turnToHumanReadable(course?.modified_date)}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  mt: "auto",
                  display: "flex",
                  justifyContent: "space-between",
                  p: 2,
                  borderTop: `1px solid ${theme.palette.divider}`,
                }}
              >
                <Button
                  size="small"
                  startIcon={<EditIcon />}
                  onClick={() =>
                    handleClickOpen(false, {
                      title: course?.title,
                      description: course?.description,
                      course_id: item.id,
                    })
                  }
                >
                  Edit
                </Button>
                <Button
                  size="small"
                  startIcon={<DeleteIcon />}
                  color="error"
                  onClick={() => handleDeleteModalOpen(item.id)}
                >
                  Delete
                </Button>
              </Box>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
  return (
    <>
      <Box
        sx={{
          p: 3,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          margin: "0 auto",
          flexDirection: "column",
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={8}>
            <Input
              isFancy
              placeholder="Search"
              endAdornment={
                <InputAdornment
                  position="end"
                  sx={{ width: "25px", height: "25px", paddingTop: 1 }}
                >
                  <SearchIcon fontSize={"medium"} />
                </InputAdornment>
              }
              sx={{
                backgroundColor: "#FFFFFF",
                boxShadow: styledTheme.shadows[9],
                padding: styledTheme.spaces[6],
                width: "35%",
              }}
              value={searchTerm}
              onChange={(e) => {
                handleInputChange(e);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" justifyContent="flex-end">
              {(role === "admin" ||
                role === "editor" ||
                role === "super_user") && (
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => handleClickOpen(true)}
                  sx={{ mr: 2 }}
                >
                  Add Course
                </Button>
              )}
              <ToggleButtonGroup
                color="primary"
                value={alignment}
                exclusive
                onChange={handleChange}
              >
                <ToggleButton value="list">
                  <Tooltip title="List View">
                    <CalendarViewDayIcon />
                  </Tooltip>
                </ToggleButton>
                <ToggleButton value="card">
                  <Tooltip title="Card View">
                    <TableChartIcon />
                  </Tooltip>
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ mt: 4 }}>
          {alignment === "list" ? renderTableView() : renderCardView()}
        </Box>
        <CreateCourseModal
          initialValues={
            isAdd ? { title: "", language: globalLanguage } : editInitialValues
          }
          open={courseModalState}
          handleClose={handleClose}
          isLoading={isCourseLoading}
          handleSubmit={handleSubmit}
        />
        <ModuleModal
          modules={modules}
          courseId={courseId}
          handleClose={handleModuleClose}
          isModalOpen={moduleOpen}
          isSubmittingForm={isCourseLoading}
        />
        <DeleteModal
          open={isDeleteModalOpen}
          handleClose={handleDeleteModalClose}
          isLoading={isCourseLoading}
          handleDelete={handleDelete}
          courseId={courseId}
        />
      </Box>
    </>
  );
}
