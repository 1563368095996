import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "types";
import { initialState } from ".";
import { ModulesState } from "./type";

const selectSlice = (state: RootState) => state?.modules || initialState;

export const selectModules: (state: RootState) => ModulesState = createSelector(
  [selectSlice],
  (state) => state
);

export const selectAllModules = createSelector(
  [selectModules],
  (state) => state?.result
);

export const selectGettingModules = createSelector(
  [selectModules],
  (state) => state.isModuleLoading
);
