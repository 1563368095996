import styled from "styled-components";
import { Autocomplete } from "@mui/material";
import {
  alignSelf,
  color,
  compose,
  justifySelf,
  layout,
  space,
  variant,
  flexBasis,
  flexGrow,
  flex,
  justifyContent,
  alignItems,
  flexDirection,
  flexbox,
} from "styled-system";
import { SelectFieldProps } from "../types";
import { Theme, theme } from "styles/theme/themes";

export const AutocompleteField = styled(Autocomplete)<SelectFieldProps>`
  .MuiInputLabel-root {
    color: ${(props) => props.color || theme.colors.grey[600]};
  }
  .MuiOutlinedInput-notchedOutline {
    border: ${theme.borders[0]};
  }
  .MuiAutocomplete-endAdornment {
    position: relative;
  }
  &:hover {
    border: none;
  }

  ${compose(
    color,
    layout,
    space,
    alignSelf,
    justifySelf,
    flexBasis,
    flexGrow,
    flex,
    justifyContent,
    alignItems,
    flexDirection,
    flexbox,
    variant({
      variants: {
        primary: {
          "& .MuiAutocomplete-inputRoot": {
            borderBottomLeftRadius: [3],
            borderTopRightRadius: [3],
            border: "none",
            color: (props: Theme) => props.colors.grey[500],
            background: (props: Theme) => props.colors.grey[100],
            paddingY: theme.spaces[1],
            paddingX: theme.spaces[5],
            outline: 0,
            fontFamily: "Jost",
          },
        },
        secondary: {
          "& .MuiAutocomplete-inputRoot": {
            borderRadius: [1],
            border: "none",
            color: (props: Theme) => props.colors.grey[500],
            background: (props: Theme) => props.colors.white[0],
            pl: theme.spaces[2],
            outline: 0,
            fontFamily: "Jost",
          },
          "& .MuiOutlinedInput-inputRoot": {
            paddingX: 0,
          },
          "& .MuiAutocomplete-popupIndicator": {
            position: "absolute",
            right: 0,
          },
          "& .MuiAutocomplete-endAdornment": {
            position: "absolute",
          },
        },
      },
    })
  )}
`;

AutocompleteField.defaultProps = {
  variant: "primary",
};
