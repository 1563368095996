import { CustomModal } from "app/components/Core";
import { addTimestampToUrl } from "helper/helperFunctions";

interface EImageProps {
  isTopic?: string;
  isOpen: boolean;
  handleClose: () => void;
  itemType: "isPdf" | "isImage" | "isPpt";
  module: any;
}

export const ImageViewerModal = (props: EImageProps) => {
  return (
    <CustomModal open={props.isOpen} handleClose={props.handleClose}>
      <img
        style={{
          width: "100%",
          height: "calc(100vh - 64px)",
          objectFit: "cover",
          objectPosition: "center",
        }}
        src={
          props.isTopic
            ? addTimestampToUrl(props.isTopic)
            : addTimestampToUrl(props.module?.image_url)
        }
      ></img>
    </CustomModal>
  );
};
