import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Avatar,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CommentCard from "./CommentCard";
import { IModuleComment } from "app/pages/Workplace/slice/type";
import { useDispatch, useSelector } from "react-redux";
import SendIcon from "@mui/icons-material/Send";
import { useModuleSlice } from "app/pages/Workplace/slice";
import {
  selectActiveCommentBlockId,
  selectAllComments,
  selectCommentLoading,
  selectIsRightSidebarOpen,
} from "app/pages/Workplace/slice/selector";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
interface CommentsListProps {
  onCommentClick: (comment: IModuleComment) => void;
}

const CommentsList: React.FC<CommentsListProps> = ({ onCommentClick }) => {
  const { actions } = useModuleSlice();
  const dispatch = useDispatch();
  const comments = useSelector(selectAllComments);
  const activeCommentBlockId = useSelector(selectActiveCommentBlockId);
  const isLoading = useSelector(selectCommentLoading);
  const isRightSidebarOpen = useSelector(selectIsRightSidebarOpen);
  const [newCommentContent, setNewCommentContent] = useState("");
  const [newCommentError, setNewCommentError] = useState("");

  useEffect(() => {
    if (!isRightSidebarOpen) {
      setNewCommentContent("");
      setNewCommentError("");
    }
  }, [isRightSidebarOpen]);

  const handleEdit = (id: string, newContent: string) => {
    if (newContent.trim().length < 3) {
      return;
    }
    dispatch(actions.updateComment({ id, content: newContent }));
  };

  const handleDelete = (id: string) => {
    dispatch(actions.deleteComment(id));
  };

  const handleNewComment = () => {
    if (newCommentContent.trim().length < 3) {
      setNewCommentError("Comment must be at least 3 characters long");
      return;
    }
    if (/^\d+$/.test(newCommentContent.trim())) {
      setNewCommentError("Comment cannot contain only numbers");
      return;
    }
    if (newCommentContent.trim() && activeCommentBlockId) {
      dispatch(
        actions.createComment({
          blockId: activeCommentBlockId,
          content: newCommentContent,
        })
      );
      setNewCommentContent("");
      setNewCommentError("");
    }
  };

  const handleAllComments = () => {
    dispatch(actions.setActiveCommentBlockId(null));
    dispatch(actions.getComments(null));
  };

  const handleReply = (blockId: string) => {
    dispatch(actions.setActiveCommentBlockId(blockId));
    dispatch(actions.getComments(blockId));
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box
        sx={{
          p: 2,
          borderBottom: "1px solid #e0e0e0",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {activeCommentBlockId && (
          <Box>
            <IconButton onClick={handleAllComments}>
              <ArrowBackIcon />
            </IconButton>
          </Box>
        )}
        <Typography variant="h6">
          {activeCommentBlockId ? "Comments for this block" : "All Comments"}
        </Typography>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          overflow: "auto",
          width: "100%",
          bgcolor: "background.paper",
          height: "calc(100% - 120px)",
        }}
      >
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
            <Typography variant="body1" sx={{ mt: 2 }}>
              Loading comments...
            </Typography>
          </Box>
        ) : comments.length > 0 ? (
          comments.map((comment) => (
            <CommentCard
              key={comment.id}
              comment={comment}
              onEdit={handleEdit}
              onDelete={handleDelete}
              onCommentClick={onCommentClick}
              onReply={handleReply}
            />
          ))
        ) : (
          <Typography variant="body1" sx={{ p: 2 }}>
            No Comments
          </Typography>
        )}
      </Box>
      {activeCommentBlockId && (
        <Box
          sx={{
            p: 2,
            borderTop: "1px solid #e0e0e0",
            bgcolor: "background.paper",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar sx={{ width: 32, height: 32, mr: 1 }}>K</Avatar>
            <TextField
              fullWidth
              value={newCommentContent}
              onChange={(e) => {
                setNewCommentContent(e.target.value);
                setNewCommentError("");
              }}
              placeholder="Comment or add others with @"
              variant="outlined"
              size="small"
              error={!!newCommentError}
              helperText={newCommentError}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "20px",
                  "& fieldset": {
                    borderColor: "#e0e0e0",
                  },
                  "&:hover fieldset": {
                    borderColor: "#bdbdbd",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#1976d2",
                  },
                },
              }}
            />
            <IconButton
              onClick={handleNewComment}
              disabled={!newCommentContent.trim()}
              sx={{
                ml: 1,
                bgcolor: newCommentContent.trim() ? "#1976d2" : "#e0e0e0",
                color: "white",
                "&:hover": {
                  bgcolor: newCommentContent.trim() ? "#1565c0" : "#e0e0e0",
                },
              }}
            >
              <SendIcon />
            </IconButton>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CommentsList;
