import React from "react";
import { CustomModal } from "../Core";
import { Box } from "app/components/Core";
import AuthController from "app/components/FormikWrapper/AuthForm";
import { useDispatch, useSelector } from "react-redux";
import ForgetPasswordController from "app/components/FormikWrapper/ForgetPassword";
import { useDefaultLayoutSlice } from "../../pages/DefaultLayout/slice";
import {
  selectDefaultLayoutState,
  selectIsForgetPasswordLoading,
} from "../../pages/DefaultLayout/slice/selectors";
import { IForgetPassword, ISignInFormValues } from "app/pages/SignInPage/types";
import {
  forgetPasswordInitialValues,
  signInFormInitialValues,
} from "app/pages/SignInPage/constants";

export const LoginModal = ({ open, handleClose }) => {
  const { actions } = useDefaultLayoutSlice();
  const dispatch = useDispatch();
  const signingInState = useSelector(selectDefaultLayoutState);
  const isForgetPasswordLoading = useSelector(selectIsForgetPasswordLoading);
  const [isSignIn, setIsSignIn] = React.useState(true);

  const onLoginInSubmit = (values: ISignInFormValues) => {
    dispatch(actions.signinUser(values));
  };

  const onForgetPasswordSubmit = (values: IForgetPassword) => {
    dispatch(actions.forgetPassword(values));
  };

  const handleToggleSignIn = () => {
    setIsSignIn(!isSignIn);
  };

  const handleCloseLoginError = () => {
    dispatch(actions.setIsLoginError(false));
  };

  return (
    <CustomModal open={open} handleClose={handleClose}>
      <Box p={4} display="flex" bg={"#ffffff"} flex={1} alignItems="center">
        {isSignIn ? (
          <AuthController
            isLoginModal
            handleCloseLoginError={handleCloseLoginError}
            isLoginErrorModal={signingInState.isLoginError}
            isAuthLoading={signingInState.isSigningIn}
            isSubmittingForm={signingInState.isSigningIn}
            onSubmit={onLoginInSubmit}
            initialValues={signInFormInitialValues}
            handleToggleIsSignIn={handleToggleSignIn}
          />
        ) : (
          <ForgetPasswordController
            isLoginModal
            isLoadingForgetPassword={isForgetPasswordLoading}
            initialValues={forgetPasswordInitialValues}
            onSubmit={onForgetPasswordSubmit}
            handleToggleIsSignIn={handleToggleSignIn}
          />
        )}
      </Box>
    </CustomModal>
  );
};
