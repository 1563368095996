import { useLocation, matchRoutes } from "react-router-dom";

export const useCurrentPath = () => {
  const routes = [
    { path: "/module/:id" },
    { path: "/import" },
    { path: "/jobs" },
    { path: "/course/:id" },
  ];
  const location = useLocation();
  const route = matchRoutes(routes, location);
  return route;
};
