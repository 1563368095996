import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import makeCall from "app/API/makeCalls";
import routes from "app/API/api.routes";
import {
  DEFAULT_DESCRIPTION,
  DEFAULT_TITLE,
  VARIANTS,
} from "app/pages/DefaultLayout/constants";
import { defaultLayoutActions } from "app/pages/DefaultLayout/slice";
import { TypeOptions } from "react-toastify";
import { v4 as uuidV4 } from "uuid";
import { changePasswordActions as actions } from ".";
import { IChangePasswordProps } from "./type";

function* updatePasswordSagag(action: PayloadAction<IChangePasswordProps>) {
  try {
    const { password, confirmPassword, token } = action.payload;
    const response: any = yield call(makeCall, {
      method: "POST",
      isSecureRoute: false,
      route: routes.user.changePassword,
      body: {
        password,
        confirm_password: confirmPassword,
        reset_password_token: token,
      },
    });
    if (response.code == "0") {
      yield put(actions.setIsChangePasswordLoading(false));
      yield put(actions.setIsChangePasswordError(false));
    }
  } catch (error: any) {
    defaultLayoutActions.setAppMessage({
      currentMessage: DEFAULT_DESCRIPTION.Error,
      currentTitle: DEFAULT_TITLE.Fail,
      currentVariant: VARIANTS.Error as TypeOptions,
      count: uuidV4(),
    }),
      yield put(actions.setIsChangePasswordLoading(false));
    yield put(actions.setIsChangePasswordError(true));
  }
}

export function* changePasswordSaga() {
  yield takeLatest(actions.changePassword.type, updatePasswordSagag);
}
