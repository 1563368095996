import { call, put, takeLatest, select } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import makeCall from "app/API/makeCalls";
import routes from "app/API/api.routes";
import { EImportModuleForm } from "./type";
import { importModuleActions as actions } from ".";
import { TypeOptions } from "react-toastify";
import {
  DEFAULT_DESCRIPTION,
  DEFAULT_TITLE,
  VARIANTS,
} from "app/pages/DefaultLayout/constants";
import { v4 as uuidV4 } from "uuid";
import { defaultLayoutActions } from "app/pages/DefaultLayout/slice";
import { handleAPIError } from "app/pages/DefaultLayout/slice/saga";

function* eImportModuleSaga(action: PayloadAction<EImportModuleForm>) {
  try {
    const response: any = yield call(makeCall, {
      method: "POST",
      isSecureRoute: true,
      route: routes.module.importModule,
      body: {
        course_id: action.payload?.courseId,
        gdrive_doc_link: action.payload.url,
      },
    });
    if (response.code == "0") {
      yield put(
        defaultLayoutActions.setAppMessage({
          currentMessage: DEFAULT_DESCRIPTION.Success,
          currentTitle: DEFAULT_TITLE.Success,
          currentVariant: VARIANTS.SUCCESS as TypeOptions,
          count: uuidV4(),
        })
      );

      yield put(actions.setIsImportModuleLoading(false));
      yield put(actions.setIsImportModuleSuccessful(true));
    }
  } catch (error: any) {
    yield put(
      defaultLayoutActions.setAppMessage({
        currentMessage: DEFAULT_DESCRIPTION.Error,
        currentTitle: DEFAULT_TITLE.Fail,
        currentVariant: VARIANTS.Error as TypeOptions,
        count: uuidV4(),
      })
    );
    yield put(actions.setIsImportModuleLoading(false));
    yield call(handleAPIError, error);
  }
}

export function* importModuleSaga() {
  yield takeLatest(actions.importModule.type, eImportModuleSaga);
}
