import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { CustomModal } from "../Core";

interface ResultModalProps {
  open: boolean;
  handleClose: () => void;
  isSuccess: boolean;
  message: string;
}

export function ResultModal({
  open,
  handleClose,
  isSuccess,
  message,
}: ResultModalProps) {
  return (
    <CustomModal open={open} handleClose={handleClose} isTransparent={false}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 4,
          backgroundColor: "white",
          borderRadius: 2,
        }}
      >
        {isSuccess ? (
          <AccessTimeIcon
            sx={{ fontSize: 80, color: "green", marginBottom: 2 }}
          />
        ) : (
          <CancelOutlinedIcon
            sx={{ fontSize: 80, color: "red", marginBottom: 2 }}
          />
        )}
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            color: isSuccess ? "green" : "red",
            marginBottom: 2,
          }}
        >
          {isSuccess ? "Processing!" : "Failed!"}
        </Typography>
        <Typography variant="body1" align="center">
          {message}
        </Typography>
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", top: 8, right: 8 }}
        >
          <CancelOutlinedIcon />
        </IconButton>
      </Box>
    </CustomModal>
  );
}
