/**
 *
 * SignUpPage
 *
 */
import * as React from "react";
import AuthController from "app/components/FormikWrapper/AuthForm";
import { useDispatch, useSelector } from "react-redux";
import { Box, Image } from "app/components/Core";
import { useNavigate, useSearchParams } from "react-router-dom";
import { signUpFormInitialValues } from "./constants";
import { ISignUpFormValues } from "./types";
import { signInImage } from "assets/images";
import { ImageContainer } from "../SignInPage";
import { useSignUpSlice } from "./slice";
import { selectSignUp } from "./slice/selector";
import { selectToastMessage } from "../DefaultLayout/slice/selectors";
import { toast } from "react-toastify";
import { useDefaultLayoutSlice } from "../DefaultLayout/slice";

interface Props {}

export function SignUpPage(_props: Props) {
  const [searchParams] = useSearchParams();
  const { actions } = useSignUpSlice();
  const { actions: defaultActions } = useDefaultLayoutSlice();
  const dispatch = useDispatch();
  const signingUpState = useSelector(selectSignUp);
  const navigate = useNavigate();
  const toastData = useSelector(selectToastMessage);
  const toastID = React.useRef<string | number | undefined>(undefined);

  React.useEffect(() => {
    if (!toast.isActive(toastID.current as React.ReactText) && !!toastData) {
      toastID.current = toast(toastData?.currentMessage, {
        position: "bottom-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        type: toastData?.currentVariant,
        toastId: toastData?.count,
      });
    }
    return () => {
      dispatch(defaultActions.hideAppMessage());
    };
  }, [toastData?.count]);

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    if (token && signingUpState.user) {
      navigate("/home");
    } else if (!token && signingUpState.user) {
      navigate("/login");
    }
  }, [signingUpState.user]);

  const onSignUpSubmit = (values: ISignUpFormValues) => {
    dispatch(
      actions.signupUser({ ...values, token: searchParams.get("token") || "" })
    );
  };

  return (
    <Box display="flex" flex={1}>
      <ImageContainer isLogin={false}>
        <Box
          display="flex"
          width={[null, 260, 340, 453]}
          height={[null, 240, 320, 420]}
        >
          <Image width="100%" height="100%" src={signInImage} />
        </Box>
      </ImageContainer>
      <Box
        display="flex"
        bg={"#ffffff"}
        flex={1}
        paddingRight={2}
        alignItems="center"
      >
        <AuthController
          isAuthLoading={signingUpState.isSigningUp}
          isSubmittingForm={signingUpState.isSigningUp}
          onSubmit={onSignUpSubmit}
          initialValues={signUpFormInitialValues}
          isSignUpPage
        />
      </Box>
    </Box>
  );
}
