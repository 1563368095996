import { styled } from "@mui/material/styles";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import { theme } from "styles/theme/themes";
import { ResponsiveValue, TLengthStyledSystem } from "styled-system";
import { theme as baseTheme } from "styles/theme/muiTheme";

interface StyledDialogProps extends DialogProps {
  isTransparent?: boolean;
  leftWidth?: ResponsiveValue<TLengthStyledSystem>;
  borderRadius?: ResponsiveValue<TLengthStyledSystem>;
}

const StyledDialog = styled(Dialog)<StyledDialogProps>(
  ({ isTransparent, borderRadius, leftWidth }) => ({
    ...(isTransparent
      ? {
          "& .MuiDialog-paper": {
            background: "rgba(255, 255, 255, 0.79)",
            boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.25)",
            borderRadius: "0px",
          },
        }
      : {
          "& .MuiDialog-paper": {
            background: "rgba(255, 255, 255, 0.98)",
            boxShadow: baseTheme.shadows[9],
            borderRadius: borderRadius || "10px",
            overflow: "visible",
            "::-webkit-scrollbar": {
              width: "3px",
              paddingRight: "50px",
            },
            "::-webkit-scrollbar-thumb": {
              background: theme.colors.grey[400],
            },
          },

          "& .MuiDialog-container": {
            background: "rgba(0, 0, 0, 0.87)",
            position: "fixed",
            left: leftWidth || "0px",
            top: "0px",
            right: "0px",
            bottom: "0px",
          },
        }),
  })
);

export default {
  StyledDialog,
};
