/* eslint-disable array-callback-return */
import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { changePasswordSaga } from "./saga";
import { IChangePasswordProps, IChangePasswordState } from "./type";

export const initialState: IChangePasswordState = {
  isChangePasswordError: undefined,
  isChangingPasswordLoading: false,
};

const slice = createSlice({
  name: "changePassword",
  initialState,
  reducers: {
    changePassword(state, action: PayloadAction<IChangePasswordProps>) {
      state.isChangingPasswordLoading = true;
    },
    setIsChangePasswordLoading(state, action: PayloadAction<boolean>) {
      state.isChangingPasswordLoading = action.payload;
    },
    setIsChangePasswordError(state, action: PayloadAction<boolean>) {
      state.isChangePasswordError = action.payload;
    },
  },
});

export const { actions: changePasswordActions } = slice;
export const usechangePasswordSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: changePasswordSaga });
  return { actions: slice.actions };
};
export const changePasswordReducers = slice.reducer;
