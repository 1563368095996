import { Courses } from "app/pages/Courses";
import { SignUpPage } from "app/pages/SignUpPage";
import { SignInPage } from "app/pages/SignInPage";
import { IRoute } from "./types";
import { RouteNames } from "utils/constants";
import { Course } from "app/pages/Course/CourseDetails";
import { Settings } from "app/pages/Settings";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import Topics from "app/pages/Topics";
import Modules from "app/pages/Modules";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import HomePage from "app/pages/Homepage";
import ComingSoon from "app/pages/ComingSoon";
import ImportPage from "app/pages/ImportPage";
import { UnauthorizedPage } from "app/pages/403";
import ChangePasswordPage from "app/pages/ChangePassword";
import Glossary from "app/pages/Glossary";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import Workplace from "app/pages/Workplace";
import LandingPage from "app/pages/LandingPage";
import JobListingPage from "app/pages/JobsPage";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ListIcon from "@mui/icons-material/List";

export const routes: IRoute[] = [
  {
    path: "/login",
    component: SignInPage,
    isAuthenticated: false,
    exact: true,
    label: RouteNames.Login,
  },
  {
    path: "/signup",
    component: SignUpPage,
    isAuthenticated: false,
    exact: true,
    label: RouteNames.SignUp,
  },
  {
    path: "/home",
    component: HomePage,
    isAuthenticated: true,
    exact: true,
    icon: HomeIcon,
    label: RouteNames.Home,
  },
  {
    path: "/courses",
    component: Courses,
    isAuthenticated: true,
    exact: true,
    icon: LibraryBooksIcon,
    label: RouteNames.Courses,
  },
  {
    path: "/module/:id",
    component: Workplace,
    isAuthenticated: true,
    exact: true,
  },
  {
    path: "/course/:id",
    component: Course,
    isAuthenticated: true,
    exact: true,
  },
  {
    path: "/modules",
    component: Modules,
    isAuthenticated: true,
    exact: true,
    icon: LibraryBooksIcon,
    label: RouteNames.Modules,
  },
  {
    path: "/topics",
    component: Topics,
    isAuthenticated: true,
    exact: true,
    icon: LibraryBooksIcon,
    label: RouteNames.Topics,
  },
  {
    path: "/glossaries",
    component: Glossary,
    isAuthenticated: true,
    exact: true,
    label: RouteNames.Glossaries,
    icon: BookmarkBorderIcon,
  },
  {
    path: "/quizzes",
    component: ComingSoon,
    isAuthenticated: true,
    exact: true,
    icon: LibraryBooksIcon,
    label: RouteNames.Quizzes,
  },
  {
    path: "/import",
    component: ImportPage,
    isAuthenticated: true,
    exact: true,
    icon: CloudDownloadIcon,
    label: RouteNames.ImportModule,
  },
  {
    path: "/jobs",
    component: JobListingPage,
    isAuthenticated: true,
    exact: true,
    icon: ListIcon,
    label: RouteNames.Jobs,
  },
  {
    path: "/settings/*",
    component: Settings,
    isAuthenticated: true,
    exact: true,
    icon: SettingsIcon,
    label: RouteNames.Settings,
  },
  {
    path: "/no-access",
    component: UnauthorizedPage,
    isAuthenticated: true,
    exact: true,
    label: RouteNames.NoAccess,
  },
  {
    path: "/ResetPassword",
    component: ChangePasswordPage,
    isAuthenticated: false,
    exact: true,
  },
  {
    path: "/",
    component: LandingPage,
    isAuthenticated: false,
    exact: true,
  },
];
