import React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import DashboardBody from "app/components/DashboardBody";
import { Flex } from "app/components/Core";
import { useDefaultLayoutSlice } from "./slice";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCreatingCourse,
  selectCreatingModule,
  selectLoginModalOpen,
  selectResultModalOpen,
  selectToastMessage,
  selectUser,
} from "./slice/selectors";
import SideBar from "app/components/Layout/SideBar";
import { useCurrentPath } from "utils/hooks/customHooks";
import { UnauthorizedPage } from "../403";
import { LoginModal } from "app/components/LoginModal";
import { clearLocalStorage } from "utils/localStorage";
import { ResultModal } from "app/components/ResultModal";
import TopBar from "app/components/Layout/TopBar";
import { useNavigate } from "react-router-dom";
import { CourseModuleLoading } from "app/components/CourseModuleLoading";

interface IProps {
  children: React.ReactNode;
  window?: () => Window;
}

export default function DefaultLayout(props: IProps) {
  const navigate = useNavigate();
  const { actions } = useDefaultLayoutSlice();
  const dispatch = useDispatch();
  const loggedInUser = useSelector(selectUser);
  const role = loggedInUser?.role;
  const toastData = useSelector(selectToastMessage);
  const isLoginModal = useSelector(selectLoginModalOpen);
  const isResultModal = useSelector(selectResultModalOpen);
  const isCourseCreating = useSelector(selectCreatingCourse);
  const isModuleCreating = useSelector(selectCreatingModule);
  const toastID = React.useRef<string | number | undefined>(undefined);
  const [prevCountValue, setPrevCountValue] = React.useState<
    string | undefined
  >();
  const isMatch = useCurrentPath();
  const intervalRef = React.useRef<NodeJS.Timeout | null>(null);

  React.useEffect(() => {
    dispatch(actions.getUserFromToken());
    intervalRef.current = setInterval(() => {
      checkIfExpired();
    }, 10000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  React.useEffect(() => {
    if (
      !toast.isActive(toastID.current as React.ReactText) &&
      prevCountValue !== toastData?.count
    ) {
      toastID.current = toast(toastData?.currentMessage, {
        position: "bottom-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        type: toastData?.currentVariant,
        toastId: toastData?.count,
      });
    }
    setPrevCountValue(toastData?.count);
    return () => {
      setPrevCountValue(undefined);
      toast.clearWaitingQueue();
      dispatch(actions.hideAppMessage());
    };
  }, [toastData?.count, prevCountValue]);

  React.useEffect(() => {
    if (isResultModal == "success") navigate("/jobs");
  }, [isResultModal]);

  React.useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (
        isLoginModal &&
        isMatch &&
        isMatch[0].pathname != "/import" &&
        isMatch[0].pathname != "/jobs" &&
        isMatch[0].route.path != "/course/:id"
      ) {
        event.preventDefault();
        const message =
          "You have unsaved changes. Do you really want to leave?";
        event.returnValue = message;
        return message;
      }
    };

    if (
      isLoginModal &&
      isMatch &&
      isMatch[0].pathname != "/import" &&
      isMatch[0].pathname !== "/jobs" &&
      isMatch[0].route.path != "/course/:id"
    ) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    } else {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }

    if (
      isMatch &&
      (isMatch[0].pathname == "/import" || isMatch[0].pathname == "/jobs") &&
      loggedInUser?.org_name != "Scholistico" &&
      (role == "new" || role == "viewer")
    ) {
      dispatch(actions.setIsImportModuleDisabeled(true));
    } else dispatch(actions.setIsImportModuleDisabeled(false));
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isLoginModal, isMatch]);

  React.useEffect(() => {
    if (isCourseCreating) {
      if (typeof isCourseCreating == "string") {
        navigate(`/course/${isCourseCreating}`);
        dispatch(actions.setIsCourseCreating(false));
      }
    }
    if (isModuleCreating) {
      if (typeof isModuleCreating == "string") {
        navigate(`/module/${isModuleCreating}`);
        dispatch(actions.setIsModuleCreating(false));
      }
    }
  }, [isCourseCreating, isModuleCreating]);

  const checkIfExpired = () => {
    const expirationTime = localStorage.getItem("expirationTime");
    if (expirationTime) {
      const currentTime = Math.floor(Date.now() / 1000);
      const remainingTime = parseInt(expirationTime) - currentTime;

      if (remainingTime <= 0) {
        dispatch(actions.setIsLoginModalOpen(true));
        clearLocalStorage();
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      }
    }
  };

  return (
    <Flex
      height="auto"
      flexDirection={"row"}
      alignItems="strech"
      justifyContent="flex-start"
      width="100%"
    >
      {(isModuleCreating || isCourseCreating) && (
        <CourseModuleLoading isCourse={!!isCourseCreating} />
      )}
      <LoginModal open={isLoginModal} handleClose={() => {}} />
      <ResultModal
        open={!!isResultModal && isResultModal != "processing"}
        handleClose={() => dispatch(actions.setDownloadingStatus(undefined))}
        isSuccess={isResultModal == "success"}
        message={
          isResultModal == "success"
            ? "Your request is being processed"
            : "Your request failed!"
        }
      />
      {role == "new" ? (
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <UnauthorizedPage />
        </Box>
      ) : (
        <>
          <CssBaseline />
          {!(
            isMatch &&
            isMatch[0].pathname != "/import" &&
            isMatch[0].pathname !== "/jobs" &&
            isMatch[0].route.path != "/course/:id"
          ) && <SideBar />}
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              width: `calc(100% - ${
                !(
                  isMatch &&
                  isMatch[0].pathname != "/import" &&
                  isMatch[0].pathname !== "/jobs" &&
                  isMatch[0].route.path != "/course/:id"
                )
                  ? "240px"
                  : "0px"
              })`,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {!(
              isMatch &&
              isMatch[0].pathname != "/import" &&
              isMatch[0].pathname !== "/jobs"
            ) && <TopBar />}
            <DashboardBody>{props.children}</DashboardBody>
          </Box>
        </>
      )}
    </Flex>
  );
}
