import { Fragment } from "react";
import { ErrorMessage, Form, Formik } from "formik";
import courseValidation from "app/components/Form/Validation/courseValidation";
import { Button as MuiButton } from "@mui/material";
import { Box, Typography, Spacer } from "app/components/Core";
import FormControl from "../../Form/FormControl";
import { ICourseProps } from "./types";
import { LanguageSelect } from "app/components/Core/Input/Select";

const CourseController = (props: ICourseProps) => {
  const isEditMode = !!props.initialValues.description;
  return (
    <Formik
      initialValues={props.initialValues}
      validationSchema={courseValidation(isEditMode)}
      onSubmit={(values) => props.onSubmit(values)}
    >
      <Form style={{ width: "500px" }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width={71}
          px={[3, null]}
        >
          <Typography fontWeight={5} fontSize={[7, 8, 9]}>
            Course
          </Typography>
          <Spacer mb={4} />
          <Box display="flex" flexDirection="column">
            <Fragment>
              <Typography>Course Title</Typography>
              <Spacer mb={2} />
              <FormControl control="input" name="title" variant="filled" />
              <ErrorMessage name="title">
                {(msg) => (
                  <Typography fontWeight={3} color="red" fontSize="11px">
                    {msg}
                  </Typography>
                )}
              </ErrorMessage>
            </Fragment>
            {isEditMode && (
              <Fragment>
                <Typography>Course Description</Typography>
                <Spacer mb={2} />
                <FormControl
                  control="textarea"
                  name="description"
                  variant="filled"
                />
                <ErrorMessage name="description">
                  {(msg) => (
                    <Typography fontWeight={3} color="red" fontSize="11px">
                      {msg}
                    </Typography>
                  )}
                </ErrorMessage>
              </Fragment>
            )}
            {props.initialValues?.language && (
              <>
                <Typography>Language(Optional)</Typography>
                <Spacer mb={2} />
                <LanguageSelect name="language" />
              </>
            )}
          </Box>
          <Spacer mb={4} />
          <MuiButton
            disabled={props.isSubmittingForm}
            variant="contained"
            type="submit"
            onClick={() => {}}
          >
            submit
          </MuiButton>
          <Spacer mb={4} />
        </Box>
      </Form>
    </Formik>
  );
};

export default CourseController;
