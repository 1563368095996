import React from "react";
import {
  Box,
  Button,
  Typography,
  LinearProgress,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface FileUploaderProps {
  onFileSelect: (file: File) => void;
  onUpload: () => void;
  onClose: () => void;
  file: File | null;
  uploadState?: {
    progress?: number;
    processing?: boolean;
    haveError?: boolean;
  };
}

export const FileUploader: React.FC<FileUploaderProps> = ({
  onFileSelect,
  onUpload,
  onClose,
  file,
  uploadState,
}) => {
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      onFileSelect(event.target.files[0]);
    }
  };

  return (
    <Box
      sx={{
        width: "600px",
        height: "300px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        border: "2px dashed",
        borderColor: "primary.main",
        borderRadius: 2,
        bgcolor: "background.paper",
        transition: "border-color 0.3s ease",
        margin: "48px",
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{ position: "absolute", top: 8, right: 8 }}
      >
        <CloseIcon />
      </IconButton>

      <input
        accept="image/*,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation"
        style={{ display: "none" }}
        id="raised-button-file"
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="raised-button-file">
        <Button
          variant="contained"
          component="span"
          sx={{ marginBottom: "8px" }}
        >
          CHOOSE FILE
        </Button>
      </label>
      {file && (
        <Typography variant="body2" sx={{ marginTop: "8px" }}>
          Selected: {file.name}
        </Typography>
      )}

      {file && (
        <Button
          variant="contained"
          color="primary"
          onClick={onUpload}
          disabled={uploadState?.processing}
          sx={{ marginBottom: "8px" }}
        >
          {uploadState?.processing ? "UPLOADING" : "UPLOAD"}
        </Button>
      )}

      {uploadState?.processing && (
        <Box sx={{ width: "80%", marginTop: "16px" }}>
          <LinearProgress
            variant="determinate"
            value={uploadState.progress}
            sx={{ height: 8, borderRadius: 4 }}
          />
          <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            sx={{ marginTop: "8px" }}
          >
            {`${Math.round(uploadState.progress || 0)}%`}
          </Typography>
        </Box>
      )}

      {uploadState?.haveError && (
        <Typography variant="body2" color="error" sx={{ marginTop: "16px" }}>
          Upload failed. Please try again.
        </Typography>
      )}

      {uploadState?.progress === 100 && (
        <Typography
          variant="body2"
          color="success.main"
          sx={{ marginTop: "16px" }}
        >
          Upload successful!
        </Typography>
      )}
    </Box>
  );
};
