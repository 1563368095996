import * as yup from "yup";

const courseValidationSchema = (isEditMode: boolean) =>
  yup.object().shape({
    title: yup
      .string()
      .required("Title is Required")
      .typeError("title should be a string")
      .matches(/^[^\d]+$/, "Title must not be a number")
      .min(2, "Title must be at least 2 characters long"),
    description: isEditMode
      ? yup
          .string()
          .required("Description is Required")
          .typeError("Description should be a string")
      : yup.string().typeError("Description should be a string"),
    language: yup.string(),
  });

export default courseValidationSchema;
