import * as Yup from "yup";

export const createUserValidationSchema = Yup.object().shape({
  emails: Yup.string()
    .required("At least one email is required")
    .test("emails", "Invalid email address", function (value) {
      if (!value) return true;
      const emails = value.split(",").map((email) => email.trim());
      return emails.every((email) => Yup.string().email().isValidSync(email));
    }),
  role: Yup.string().required("Role is required"),
});
