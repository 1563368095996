import React, { Fragment } from "react";
import { ErrorMessage, Form, Formik } from "formik";
import loginValidationSchema from "app/components/Form/Validation/loginValidation";
import signUpValidationSchema from "app/components/Form/Validation/signupValidation";
import { Button as MuiButton, Box as MuiBox } from "@mui/material";
import { Box, Typography, Button, Spacer, Input } from "app/components/Core";
import { withIcon } from "app/components/Core/Input/withIcon";
import { Visibility, VisibilityOff } from "styled-icons/material";
import { IAuthProps } from "./types";
import FormControl from "../../Form/FormControl";
import { useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { FormControl as MuiFormControl } from "@mui/material";
import { languageOptions } from "utils/constants";
import { theme } from "styles/theme/themes";
import { ILanguage } from "utils/type";
import { LanguageSelect } from "app/components/Core/Input/Select";

export const PasswordInput = withIcon(({ name, inputType }) => (
  <Input
    tabIndex={0}
    control="input"
    name={name}
    type={inputType ? "password" : "text"}
    placeholder="Password"
    variant="filled"
    autoComplete="off"
  />
));

const AuthController = (props: IAuthProps) => {
  const [inputPasswordType, setInputPasswordType] = React.useState(true);
  const [inputLoginPasswordType, setInputLoginPasswordType] =
    React.useState(true);
  const [inputConfirmPasswordType, setInputConfirmPasswordType] =
    React.useState(true);
  let navigate = useNavigate();
  const handlePasswordInputType = () => {
    setInputPasswordType(!inputPasswordType);
  };
  const handleConfirmPasswordInputType = () => {
    setInputConfirmPasswordType(!inputConfirmPasswordType);
  };
  const handleLoginPasswordInputType = () => {
    setInputLoginPasswordType(!inputLoginPasswordType);
  };

  return (
    <Formik
      initialValues={props.initialValues}
      validationSchema={
        props.isSignUpPage ? signUpValidationSchema : loginValidationSchema
      }
      onSubmit={(values) => props.onSubmit(values)}
    >
      <Form style={{ padding: `${props.isSignUpPage ? "0px 24px" : "unset"}` }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width={71}
          px={[3, null]}
          height="auto"
          flex={1}
        >
          {props.isSignUpPage ? (
            <>
              <Typography
                fontWeight={5}
                color="#000000"
                fontSize={[7, 8, 9]}
                minWidth="100%"
                textAlign={"center"}
              >
                Let’s fill this in...✍️
              </Typography>
              <Typography
                fontWeight={3}
                color="#000000"
                fontSize={[4, 5, 6]}
                minWidth="100%"
                textAlign={"center"}
              >
                Please complete the form below
              </Typography>
            </>
          ) : (
            <Typography
              fontWeight={5}
              color="#000000"
              fontSize={[7, 8, 9]}
              textAlign="left"
              minWidth="100%"
            >
              Let’s get back in....👋
            </Typography>
          )}
          {props?.isLoginModal && props?.isLoginErrorModal && (
            <Chip
              label="invalid credentials!"
              variant="outlined"
              color="error"
              onDelete={() => {
                if (props?.handleCloseLoginError) props.handleCloseLoginError();
              }}
            />
          )}
          <Spacer mb={4} />
          {props.isSignUpPage ? (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography>First Name*</Typography>
                <Spacer mb={2} />
                <FormControl
                  control="input"
                  name="first_name"
                  placeholder="John"
                  variant="filled"
                />
                <ErrorMessage name="first_name">
                  {(msg) => (
                    <Typography fontWeight={3} color="red" fontSize="11px">
                      {msg}
                    </Typography>
                  )}
                </ErrorMessage>
              </Grid>
              <Grid item xs={6}>
                <Typography>Last Name*</Typography>
                <Spacer mb={2} />
                <FormControl
                  control="input"
                  name="last_name"
                  placeholder="Doe"
                  variant="filled"
                />
                <ErrorMessage name="last_name">
                  {(msg) => (
                    <Typography fontWeight={3} color="red" fontSize="11px">
                      {msg}
                    </Typography>
                  )}
                </ErrorMessage>
              </Grid>
              <Grid item xs={6}>
                <Typography>Phone Number</Typography>
                <Spacer mb={2} />
                <FormControl
                  control="input"
                  name="phone"
                  placeholder="+2519152736"
                  variant="filled"
                />
                <ErrorMessage name="phone_number">
                  {(msg) => (
                    <Typography fontWeight={3} color="red" fontSize="11px">
                      {msg}
                    </Typography>
                  )}
                </ErrorMessage>
              </Grid>
              <Grid item xs={6}>
                <Typography>Preferred Language*</Typography>
                <Spacer mb={2} />
                <LanguageSelect name="preferred_language" />
              </Grid>
              <Grid item xs={6}>
                <Typography> Password*</Typography>
                <Spacer mb={2} />
                <PasswordInput
                  icon={inputPasswordType ? VisibilityOff : Visibility}
                  handleInputType={handlePasswordInputType}
                  inputName="password"
                  inputType={inputPasswordType}
                />
                <ErrorMessage name="password">
                  {(msg) => (
                    <Typography fontWeight={3} color="red" fontSize="11px">
                      {msg}
                    </Typography>
                  )}
                </ErrorMessage>
              </Grid>
              <Grid item xs={6}>
                <Typography>Confirm Password*</Typography>
                <Spacer mb={2} />
                <PasswordInput
                  icon={inputConfirmPasswordType ? VisibilityOff : Visibility}
                  handleInputType={handleConfirmPasswordInputType}
                  inputName="confirmPassword"
                  inputType={inputConfirmPasswordType}
                />
                <ErrorMessage name="confirmPassword">
                  {(msg) => (
                    <Typography fontWeight={3} color="red" fontSize="11px">
                      {msg}
                    </Typography>
                  )}
                </ErrorMessage>
              </Grid>
              {/* <Grid item xs={12}>
                <MuiFormControl fullWidth>
                  <InputLabel id="select-label">Organizations</InputLabel>
                  <Select
                    labelId="select-label"
                    value={props?.selectedOrg}
                    onChange={(ev) => {
                      if (props?.handleOrgChange) props?.handleOrgChange(ev);
                    }}
                    label="Organizations"
                  >
                    {props?.organizations?.map((item) => (
                      <MenuItem key={item?.id} value={item?.name}>
                        {item?.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <ErrorMessage name="organization">
                    {(msg) => (
                      <Typography fontWeight={3} color="red" fontSize="11px">
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </MuiFormControl>
              </Grid> */}
              {/* <Grid item xs={12}>
                <Typography>OpenAI Key*</Typography>
                <Spacer mb={2} />
                <FormControl
                  control="input"
                  name="open_ai_key"
                  placeholder=""
                  variant="filled"
                />
                <ErrorMessage name="open_ai_key">
                  {(msg) => (
                    <Typography fontWeight={3} color="red" fontSize="11px">
                      {msg}
                    </Typography>
                  )}
                </ErrorMessage>
              </Grid>
              <Grid item xs={12}>
                <Typography>Deep Learning Key*</Typography>
                <Spacer mb={2} />
                <FormControl
                  control="input"
                  name="deepl_key"
                  placeholder=""
                  variant="filled"
                />
                <ErrorMessage name="deepl_key">
                  {(msg) => (
                    <Typography fontWeight={3} color="red" fontSize="11px">
                      {msg}
                    </Typography>
                  )}
                </ErrorMessage>
              </Grid> */}
            </Grid>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent={"flex-start"}
            >
              <Fragment>
                <Typography>Email Address</Typography>
                <Spacer mb={2} />
                <FormControl
                  control="input"
                  type="email"
                  name="email"
                  placeholder="johndoe@gmail.com"
                  variant="filled"
                />
                <ErrorMessage name="email">
                  {(msg) => (
                    <Typography fontWeight={3} color="red" fontSize="11px">
                      {msg}
                    </Typography>
                  )}
                </ErrorMessage>
              </Fragment>
              <Spacer mb={2} />
              <Fragment>
                <Typography> Password</Typography>
                <Spacer mb={2} />
                <PasswordInput
                  icon={inputLoginPasswordType ? VisibilityOff : Visibility}
                  handleInputType={handleLoginPasswordInputType}
                  inputName="password"
                  inputType={inputLoginPasswordType}
                />
                <ErrorMessage name="password">
                  {(msg) => (
                    <Typography fontWeight={3} color="red" fontSize="11px">
                      {msg}
                    </Typography>
                  )}
                </ErrorMessage>
              </Fragment>
            </Box>
          )}
        </Box>
        <Spacer mb={3} />
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Button
            loading={props.isAuthLoading}
            type="submit"
            height={["45px"]}
            width={"350px"}
            disabled={props.isSubmittingForm}
            margin="auto"
          >
            {props.isSignUpPage ? "Sign Up" : "Log In"}
          </Button>
          {!props.isSignUpPage && !props?.isLoginModal && (
            <>
              <Spacer mb={3} />
              <Divider>or</Divider>
            </>
          )}
        </Box>
        {props.isSignUpPage ? (
          <MuiBox
            sx={{
              pt: 2,
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <MuiButton variant="text" onClick={() => navigate("/login")}>
              Login?
            </MuiButton>
          </MuiBox>
        ) : (
          <MuiBox
            sx={{
              pt: 2,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <MuiBox
              sx={{
                pl: 3,
                width: "100%",
                display: "flex",
                justifyContent: props.isLoginModal ? "end" : "space-between",
                alignItems: "baseline",
              }}
            >
              {!props.isLoginModal && (
                <MuiButton variant="text" onClick={() => navigate("/signup")}>
                  Sign Up?
                </MuiButton>
              )}
              <MuiButton
                sx={{ textAlign: "end" }}
                variant="text"
                onClick={props.handleToggleIsSignIn}
              >
                Forgot Password?
              </MuiButton>
            </MuiBox>
          </MuiBox>
        )}
      </Form>
    </Formik>
  );
};

export default AuthController;
