import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "types";
import { initialState } from ".";
import { ISignUpState } from "./type";

const selectSlice = (state: RootState) => state?.signup || initialState;

export const selectSignUp: (state: RootState) => ISignUpState = createSelector(
  [selectSlice],
  (state) => state
);
