import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosProgressEvent,
} from "axios";
import { API_ROUTE } from "utils/constants";
import { APIError } from "./Errors";
import { HeaderObj, IAPICallConfig } from "./types";
import { localstorageGetItem } from "helper/helperFunctions";

const makeCall = async (config: IAPICallConfig) => {
  let authToken;
  try {
    const fullURL = `${API_ROUTE}${config.route}`;
    const header: HeaderObj = config.header ? { ...config.header } : {};

    if (config.isSecureRoute) {
      const token: any = await localstorageGetItem("token");
      // handle token
      authToken = token || "";
      header.Authorization = `Bearer ${authToken}`;
    }

    if (config.reCaptchaToken) {
      header["RECAPTCHA-RESPONSE"] = config.reCaptchaToken;
    }

    const axiosConfig = {
      method: config.method,
      params: config.query,
      data: config.body,
      url: fullURL,
      headers: header,
      responseType: config.isOnDownload
        ? "blob"
        : config.responseType || "json",
      onUploadProgress: config.onUploadProgress
        ? (progressEvent: AxiosProgressEvent) => {
            config.onUploadProgress!(
              progressEvent as unknown as ProgressEvent<EventTarget>
            );
          }
        : undefined,
      onDownloadProgress: config.onDownloadProgress
        ? (progressEvent: AxiosProgressEvent) => {
            config.onDownloadProgress!(
              progressEvent as unknown as ProgressEvent<EventTarget>
            );
          }
        : undefined,
    };

    const response: AxiosResponse = await axios(
      axiosConfig as AxiosRequestConfig
    );

    if (config.isOnDownload && response.status === 200) {
      let contentType = response.headers["content-type"];
      let filename = config?.defaultFileName || "download.pptx"; // Default to .pptx if not specified

      // If content type is generic, set it to PowerPoint type
      if (contentType === "application/octet-stream") {
        contentType =
          "application/vnd.openxmlformats-officedocument.presentationml.presentation";
      }

      const blob = new Blob([response.data], { type: contentType });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
      return { success: true, filename };
    } else if (response.status === 200 && config.returnCleanResponse) {
      return response;
    } else if (response.status === 200) {
      return response.data;
    } else {
      throw new APIError(response.status, response.data?.message);
    }
  } catch (error: any) {
    let errorData: any = {};
    if (error?.response) {
      const { response } = error;
      if (response.status == 401) {
        if (response.data.message == "Could not validate credentials")
          throw new APIError(401, { ...response.data, status: 401 } || {});
      }
      if (config.isLockModule) {
        errorData = response.data || {};
      } else {
        throw new APIError(response?.status, response.data?.message);
      }
    }
    if (error instanceof APIError) {
      throw error;
    }
    throw new APIError(500, errorData);
  }
};

export default makeCall;
