/* eslint-disable array-callback-return */
import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { ICourseModel, IModuleSchema } from "app/Model/course";
import { courseDetailSaga } from "./saga";
import {
  ICourseDetailState,
  ICreateModule,
  IDeleteModule,
  ISetCourseModule,
  ISetUpdateModuleRes,
  IUpdateCourse,
  IUpdateModule,
} from "./type";
import { produce } from "immer";
import { ILanguage } from "utils/type";

export const initialState: ICourseDetailState = {
  result: null,
  isCourseLoading: false,
  moduleModalState: false,
  module: undefined,
};

const slice = createSlice({
  name: "courseDetail",
  initialState,
  reducers: {
    getCourse(
      state,
      action: PayloadAction<{ course_id: string; language: ILanguage }>
    ) {
      state.isCourseLoading = true;
    },

    getModule(state, action: PayloadAction<string>) {
      state.isCourseLoading = true;
    },

    setCourseState(state, action: PayloadAction<ICourseModel>) {
      state.result = action.payload;
    },

    setModuleState(state, action: PayloadAction<any>) {
      state.module = action.payload;
    },

    createModule(state, action: PayloadAction<ICreateModule>) {},

    setModuleCourse(state, action: PayloadAction<ISetCourseModule>) {
      return produce(state, (draft) => {
        if (draft.result) {
          draft.result.modules.push({
            id: action.payload.module_id,
            sequence_num: action.payload.module_sequence_number,
            title: action.payload.title,
            description: action.payload.description,
            image_url: action.payload.image_url,
            created_by: action.payload.created_by,
            modified_by: action.payload.modified_by,
            creation_date: action.payload.creation_date,
            modified_date: action.payload.modified_date,
          });
        }
      });
    },

    updateModuleSequence(state, action: PayloadAction<IUpdateModule>) {
      state.isCourseLoading = true;
    },

    setModuleCourseUpdate(state, action: PayloadAction<ISetUpdateModuleRes>) {
      return produce(state, (draft) => {
        if (draft.result) {
          const updatedModules = action.payload.modules.reduce(
            (acc, moduleData) => {
              const existingModule = draft?.result?.modules.find(
                (module) => module.id === moduleData.module_id
              );
              if (existingModule) {
                acc.push({
                  ...existingModule,
                  sequence_num: moduleData.sequence_number,
                  title: moduleData.title,
                  description: moduleData.description,
                  image_url: moduleData.image,
                  ppt_url: moduleData.ppt,
                  pdf_url: moduleData.pdf,
                  modified_date: moduleData.modified_date,
                  modified_by: moduleData.modified_by,
                });
              }
              return acc;
            },
            [] as IModuleSchema[]
          );

          draft.result.modules = updatedModules.sort(
            (a, b) => a.sequence_num - b.sequence_num
          );

          if (action.payload.module_id) {
            draft.result.modules = draft.result.modules.filter(
              (module) => module.id !== action.payload.module_id
            );
          }
        }
      });
    },

    setIsCourseLoading(state, action: PayloadAction<boolean>) {
      state.isCourseLoading = action.payload;
    },

    deleteModule(state, action: PayloadAction<IDeleteModule>) {
      state.isCourseLoading = true;
    },

    updateModuleTitle(state, action: PayloadAction<IUpdateCourse>) {
      state.isCourseLoading = true;
    },

    toggleModuleModalState(state, action: PayloadAction<boolean>) {
      state.moduleModalState = action.payload;
    },

    clearModule(state) {
      state.module = undefined;
      state.result = null;
    },
  },
});

export const { actions: courseActions } = slice;
export const useCourseDetailSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: courseDetailSaga });
  return { actions: slice.actions };
};
export const courseDetailReducers = slice.reducer;
