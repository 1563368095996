import { useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useJobSlice } from "./slice";
import { selectAllJobs } from "./slice/selector";

const JobListingPage = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { actions } = useJobSlice();

  useEffect(() => {
    dispatch(actions.getJobs());
  }, []);

  const jobs = useSelector(selectAllJobs);

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case "completed":
      case "success":
        return "success";
      case "in progress":
        return "warning";
      default:
        return "error";
    }
  };

  return (
    <Box sx={{ p: 3, width: "100%" }}>
      <Typography
        gutterBottom
        align="left"
        sx={{
          fontSize: "24px",
          fontWeight: 600,
          color: theme.palette.primary.main,
          mb: 3,
        }}
      >
        Job Listings
      </Typography>
      <TableContainer
        component={Paper}
        sx={{ maxHeight: "calc(100vh - 200px)", overflow: "auto" }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: theme.palette.grey[100],
                borderBottom: `2px solid ${theme.palette.divider}`,
              }}
            >
              <TableCell
                sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
              >
                Job ID
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
              >
                Description
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
              >
                Created By
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
              >
                Organization
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
              >
                Creation Date
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
              >
                End Date
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
              >
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {jobs?.map((job, index) => (
              <TableRow
                key={job.JobID}
                sx={{
                  "&:nth-of-type(even)": {
                    backgroundColor: theme.palette.action.hover,
                  },
                  borderBottom: `1px solid ${theme.palette.divider}`,
                }}
              >
                <TableCell>{job.JobID}</TableCell>
                <TableCell>{job.JobDescription}</TableCell>
                <TableCell>{job.CreatedBy}</TableCell>
                <TableCell>{job.Organization}</TableCell>
                <TableCell>
                  {new Date(job.CreationDate).toLocaleString()}
                </TableCell>
                <TableCell>
                  {job.EndDate ? new Date(job.EndDate).toLocaleString() : "N/A"}
                </TableCell>
                <TableCell>
                  <Chip
                    label={job.Status}
                    color={getStatusColor(job.Status)}
                    sx={{
                      fontWeight: "bold",
                      color: theme.palette.getContrastText(
                        theme.palette[getStatusColor(job.Status)].main
                      ),
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default JobListingPage;
