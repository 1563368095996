import { PayloadAction } from "@reduxjs/toolkit";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { createSlice } from "utils/@reduxjs/toolkit";
import { IOrganisations, ISignUpState } from "./type";
import { ISignUpPayload } from "../types";
import { SignUpSagaWatcher } from "./saga";

export const initialState: ISignUpState = {
  isSigningUp: false,
  isSignUpError: false,
  user: false,
  organizations: [],
};
const SignUpSlice = createSlice({
  name: "signup",
  initialState,
  reducers: {
    getOrganisations(state) {
      state.isSigningUp = true;
    },
    signupUser(state, action: PayloadAction<ISignUpPayload>) {
      state.isSigningUp = true;
    },
    setIsSigningUp(state, action: PayloadAction<boolean>) {
      state.isSigningUp = action.payload;
    },
    setOrganizations(state, action: PayloadAction<IOrganisations[]>) {
      state.organizations = action.payload;
    },
    setIsSignUpError(state, action: PayloadAction<boolean>) {
      state.isSignUpError = action.payload;
    },
    setUser(state, action: PayloadAction<boolean>) {
      state.user = action.payload;
    },
    clearUser(state) {
      state.user = false;
    },
  },
});

export const { actions: signUpActions } = SignUpSlice;
export const useSignUpSlice = () => {
  useInjectReducer({ key: SignUpSlice.name, reducer: SignUpSlice.reducer });
  useInjectSaga({ key: SignUpSlice.name, saga: SignUpSagaWatcher });
  return { actions: SignUpSlice.actions };
};
export const modulesReducers = SignUpSlice.reducer;
