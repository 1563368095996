import React from "react";
import { ErrorMessage, useField } from "formik";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import { theme } from "styles/theme/themes";
import { ILanguage } from "utils/type";
import { languageOptions } from "utils/constants";

export const LanguageSelect = ({ name, ...props }) => {
  const [field, meta, helpers] = useField(name);

  const handleChange = (event) => {
    helpers.setValue(event.target.value);
  };

  return (
    <>
      <FormControl fullWidth variant="filled">
        <Select
          {...field}
          {...props}
          onChange={handleChange}
          defaultValue={ILanguage.en}
          sx={{
            borderRadius: theme.radii[1],
            fontSize: theme.fontSizes[4],
            fontFamily: theme.fonts.nunito,
            color: theme.colors.grey[600],
            width: "100%",
            height: "45px",
            minWidth: "350px",
            backgroundColor: theme.colors.grey[100],
            border: "none",
            "&:focus": {
              outline: "none",
              backgroundColor: theme.colors.grey[100],
            },
            "& .MuiSelect-select": {
              padding: theme.spaces[3],
              borderRadius: theme.radii[1],
            },
          }}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem value="" disabled>
            Select a language
          </MenuItem>
          {languageOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <span>{option.label}</span>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <ErrorMessage name={name}>
        {(msg) => (
          <Typography fontWeight={3} color="red" fontSize="11px">
            {msg}
          </Typography>
        )}
      </ErrorMessage>
    </>
  );
};
