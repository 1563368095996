import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "types";
import { initialState } from ".";

const selectSlice = (state: RootState) =>
  state?.defaultLayoutState || initialState;

export const selectDefaultLayoutState = createSelector(
  [selectSlice],
  (state) => state
);

export const selectToastMessage = createSelector(
  [selectDefaultLayoutState],
  (state) => state.appMessage
);

export const selectIsLoginError = createSelector(
  [selectDefaultLayoutState],
  (state) => state.isLoginError
);

export const selectUser = createSelector(
  [selectDefaultLayoutState],
  (state) => state.user
);

export const selectLoginModalOpen = createSelector(
  [selectDefaultLayoutState],
  (state) => state.isLoginModalOpen
);

export const selectResultModalOpen = createSelector(
  [selectDefaultLayoutState],
  (state) => state.downloadingItemsStatus
);

export const selectIsForgetPasswordLoading = createSelector(
  [selectDefaultLayoutState],
  (state) => state.isLoadingForgetPassword
);

export const selectStyleProps = createSelector(
  [selectDefaultLayoutState],
  (state) => state.styleProps
);

export const selectIsImportModuleDisabled = createSelector(
  [selectDefaultLayoutState],
  (state) => state.isImportModleDisabled
);

export const selectGlobalLanguage = createSelector(
  [selectDefaultLayoutState],
  (state) => state.lang
);

export const selectCreatingCourse = createSelector(
  [selectDefaultLayoutState],
  (state) => state.isCourseCreating
);

export const selectCreatingModule = createSelector(
  [selectDefaultLayoutState],
  (state) => state.isModuleCreating
);
