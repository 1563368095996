/* eslint-disable array-callback-return */
import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { ICourseModel } from "app/Model/course";
import { courseSaga } from "./saga";
import { CourseState, ICreateCourse, IUpdateCourse } from "./types";
import { ILanguage } from "utils/type";

export const initialState: CourseState = {
  result: [],
  isCourseLoading: false,
  courseModalState: false,
};

const slice = createSlice({
  name: "courses",
  initialState,
  reducers: {
    getCourses(state, action: PayloadAction<ILanguage>) {
      state.isCourseLoading = true;
    },

    setCourseState(state, action: PayloadAction<ICourseModel[]>) {
      state.result = action.payload;
    },

    createCourse(state, action: PayloadAction<ICreateCourse>) {},

    setIsCourseLoading(state, action: PayloadAction<boolean>) {
      state.isCourseLoading = action.payload;
    },
    deleteCourse(state, action: PayloadAction<string>) {
      state.isCourseLoading = true;
    },
    updateCourse(state, action: PayloadAction<IUpdateCourse>) {
      state.isCourseLoading = true;
    },
    toggleCourseModalState(state, action: PayloadAction<boolean>) {
      state.courseModalState = action.payload;
    },
    clearModule(state) {
      state.result = null;
    },
  },
});

export const { actions: courseActions } = slice;
export const useCourseSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: courseSaga });
  return { actions: slice.actions };
};
export const courseReducers = slice.reducer;
