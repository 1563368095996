export const appColors = {
  background_default: "#ecf0ee",
  background_paper: "#ffffff",
  primary_main: "#3498DB",
  primary_dark: "#2677BC",
  primary_light: "#63BBE9",
  primary_contrastText: "#FFFFFF",
  secondary_main: "#f5f7fa",
  secondary_dark: "#B3BFD7",
  secondary_light: "#F8F9FB",
  secondary_contrastText: "#FFFFFF",
  red: "#E4572E",
  white: "#FFFFFF",
};
