import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import ReplayIcon from "@mui/icons-material/Replay";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import { ICourseEdit } from "app/pages/Courses/types";
import { truncate, turnToHumanReadable } from "helper/helperFunctions";
import ModuleModal from "./ModuleModal";
import { useSelector } from "react-redux";
import { selectModuleById } from "app/pages/Courses/slice/selectors";
import { useNavigate } from "react-router-dom";
import { DeleteModal } from "./DeleteModule";
import { ICourseModel } from "app/Model/course";
import CardMedia from "@mui/material/CardMedia";
import BusinessIcon from "@mui/icons-material/Business";
interface IProps {
  course: ICourseModel;
  isCourseLoading?: boolean;
  handleClickOpen: (add: boolean, { title, course_id }: ICourseEdit) => void;
  handleDelete: (course_id: string) => void;
  role?: string;
}

export default function RenderBook(props: IProps) {
  const [moduleOpen, setModuleOpen] = React.useState(false);
  const modules = useSelector((state) =>
    selectModuleById(state, props.course.id)
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const navigate = useNavigate();
  const handleModuleOpen = () => {
    setModuleOpen(true);
  };
  const handleModuleClose = () => {
    setModuleOpen(false);
  };

  const handleDeleteModalOpen = () => {
    setIsDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <ModuleModal
        modules={modules}
        courseId={props.course.id}
        handleClose={handleModuleClose}
        isModalOpen={moduleOpen}
        isSubmittingForm={props.isCourseLoading}
      />
      <DeleteModal
        open={isDeleteModalOpen}
        handleClose={handleDeleteModalClose}
        isLoading={props.isCourseLoading}
        handleDelete={props.handleDelete}
        courseId={props.course.id}
      />
      <Card
        sx={{
          height: "100%",
          width: "100%",
          borderRadius: "15px",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
          transition: "transform 0.2s, box-shadow 0.2s",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          "&:hover": {
            boxShadow: "0 6px 25px rgba(0, 0, 0, 0.15)",
          },
        }}
      >
        <CardMedia
          component="img"
          height="140"
          image={props.course.image}
          alt={props.course.title}
        />
        <CardContent sx={{ flexGrow: 1 }}>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ color: "#1976d2", cursor: "pointer" }}
            onClick={() => navigate(`/course/${props.course.id}`)}
          >
            {truncate(props.course?.title, 20)}
          </Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            {truncate(props.course?.description, 100)}
          </Typography>
          <Stack direction="row" alignItems="center" spacing={1} mt={2}>
            <BusinessIcon fontSize="small" />
            <Typography variant="body2">{props.course.organization}</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1} mt={1}>
            <LibraryBooksIcon fontSize="small" />
            <Typography
              variant="body2"
              onClick={handleModuleOpen}
              sx={{ color: "#1976d2", cursor: "pointer" }}
            >
              {props.course?.modules.length} modules
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1} mt={1}>
            <ReplayIcon fontSize="small" />
            <Typography variant="body2" color="text.secondary">
              Last modified: {turnToHumanReadable(props.course?.modified_date)}
            </Typography>
          </Stack>
        </CardContent>
        {(props.role == "editor" ||
          props.role == "admin" ||
          props.role == "super_user") && (
          <CardActions sx={{ justifyContent: "flex-end", p: 2 }}>
            <Button
              size="small"
              onClick={() => {
                props.handleClickOpen(false, {
                  title: props.course?.title,
                  course_id: props.course.id,
                  description: props.course?.description,
                });
              }}
            >
              Edit
            </Button>
            <Button
              variant="outlined"
              color="error"
              size="small"
              onClick={() => {
                handleDeleteModalOpen();
              }}
            >
              Delete
            </Button>
          </CardActions>
        )}
      </Card>
    </>
  );
}
