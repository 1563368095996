import { call, put, takeLatest, select } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import makeCall from "app/API/makeCalls";
import routes from "app/API/api.routes";
import { ICourseModel } from "app/Model/course";
import { ICreateCourse, ISetCourse, IUpdateCourse } from "./types";
import { courseActions as actions } from ".";
import { TypeOptions } from "react-toastify";
import {
  DEFAULT_DESCRIPTION,
  DEFAULT_TITLE,
  VARIANTS,
} from "app/pages/DefaultLayout/constants";
import { v4 as uuidV4 } from "uuid";
import { defaultLayoutActions } from "app/pages/DefaultLayout/slice";
import { handleAPIError } from "app/pages/DefaultLayout/slice/saga";
import { selectGlobalLanguage } from "app/pages/DefaultLayout/slice/selectors";
import { ILanguage } from "utils/type";

function* createCourseSaga(action: PayloadAction<ICreateCourse>) {
  try {
    yield put(defaultLayoutActions.setIsCourseCreating(true));
    const globalLanguage = yield select(selectGlobalLanguage);
    const response: any = yield call(makeCall, {
      method: "POST",
      isSecureRoute: true,
      route: routes.course.create,
      body: {
        title: action.payload.title,
        language: action.payload?.language
          ? action.payload.language
          : globalLanguage,
      },
    });
    if (response.code == "0") {
      yield put(
        defaultLayoutActions.setGlobalLanguage(
          action.payload?.language ? action.payload.language : globalLanguage
        )
      );
      yield put(defaultLayoutActions.setIsCourseCreating(response.id));
    }
  } catch (error: any) {
    yield put(
      defaultLayoutActions.setAppMessage({
        currentMessage: DEFAULT_DESCRIPTION.Error,
        currentTitle: DEFAULT_TITLE.Fail,
        currentVariant: VARIANTS.Error as TypeOptions,
        count: uuidV4(),
      })
    );
    yield put(defaultLayoutActions.setIsCourseCreating(false));
    yield call(handleAPIError, error);
  }
}

function* updateCourseSaga(action: PayloadAction<IUpdateCourse>) {
  try {
    const response: any = yield call(makeCall, {
      method: "POST",
      isSecureRoute: true,
      route: routes.course.update,
      body: { ...action.payload },
    });
    if (response.code == "0") {
      const coursesRsponse: ISetCourse = yield call(makeCall, {
        method: "GET",
        isSecureRoute: true,
        route: routes.course.list,
        query: {
          language: action.payload.language,
        },
      });
      if (coursesRsponse.courses) {
        yield put(actions.setCourseState(coursesRsponse.courses));
        yield put(
          defaultLayoutActions.setAppMessage({
            currentMessage: DEFAULT_DESCRIPTION.Success,
            currentTitle: DEFAULT_TITLE.Success,
            currentVariant: VARIANTS.SUCCESS as TypeOptions,
            count: uuidV4(),
          })
        );
      }
      yield put(actions.setIsCourseLoading(false));
    }
  } catch (error: any) {
    console.log("ERROR UPDATING COURSE", error);
    yield put(
      defaultLayoutActions.setAppMessage({
        currentMessage: DEFAULT_DESCRIPTION.Error,
        currentTitle: DEFAULT_TITLE.Fail,
        currentVariant: VARIANTS.Error as TypeOptions,
        count: uuidV4(),
      })
    );
    yield put(actions.setIsCourseLoading(false));
    yield call(handleAPIError, error);
  }
}

function* getCoursesSaga(action: PayloadAction<ILanguage>) {
  try {
    let response: ISetCourse = yield call(makeCall, {
      method: "GET",
      isSecureRoute: true,
      route: routes.course.list,
      query: {
        language: action.payload,
      },
    });
    if (response.courses) {
      yield put(actions.setCourseState(response.courses));
    }
    yield put(actions.setIsCourseLoading(false));
  } catch (error: any) {
    yield put(actions.setIsCourseLoading(false));
    yield call(handleAPIError, error);
  }
}

function* deleteCourseSaga(action: PayloadAction<string>) {
  try {
    const language = yield select(selectGlobalLanguage);
    const response: ICourseModel = yield call(makeCall, {
      method: "POST",
      isSecureRoute: true,
      route: `${routes.course.delete}`,
      body: {
        course_id: action.payload,
      },
    });
    if (response) {
      const coursesRsponse: ISetCourse = yield call(makeCall, {
        method: "GET",
        isSecureRoute: true,
        route: routes.course.list,
        query: {
          language: language,
        },
      });
      if (coursesRsponse.courses) {
        yield put(actions.setCourseState(coursesRsponse.courses));
        yield put(
          defaultLayoutActions.setAppMessage({
            currentMessage: DEFAULT_DESCRIPTION.Success,
            currentTitle: DEFAULT_TITLE.Success,
            currentVariant: VARIANTS.SUCCESS as TypeOptions,
            count: uuidV4(),
          })
        );
      }
      yield put(actions.setIsCourseLoading(false));
    }
  } catch (e: any) {
    yield put(
      defaultLayoutActions.setAppMessage({
        currentMessage: DEFAULT_DESCRIPTION.Error,
        currentTitle: DEFAULT_TITLE.Fail,
        currentVariant: VARIANTS.Error as TypeOptions,
        count: uuidV4(),
      })
    );
    yield put(actions.setIsCourseLoading(false));
    yield call(handleAPIError, e);
    console.log(e);
  }
}

export function* courseSaga() {
  yield takeLatest(actions.createCourse.type, createCourseSaga);
  yield takeLatest(actions.getCourses.type, getCoursesSaga);
  yield takeLatest(actions.updateCourse.type, updateCourseSaga);
  yield takeLatest(actions.deleteCourse.type, deleteCourseSaga);
}
// export function* cartSagaWatcher() {
//   yield fork(cartSaga);
// }
