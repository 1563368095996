/**
 * Routes file to backend endpoints, always fnish routes with out slash symbol
 */

const course = {
  create: "/CreateCourse",
  list: "/ListCoursesAndModules",
  delete: "/DeleteCourse",
  update: "/UpdateCourse",
};

const module = {
  create: "/CreateModule",
  list: "/ListModules",
  get: "/GetModule",
  delete: "/DeleteModule",
  update: "/UpdateModuleSequence",
  update_title: "/UpdateModule",
  image: "/GenerateImage",
  validateModule: "/ValidateModule",
  pdf: "/GeneratePDF",
  ppt: "/GeneratePPT",
  lock: "/UpdateModuleStatus",
  translate: "/TranslateModule",
  importModule: "/ImportModule",
};

const content = {
  updateContentSequence: "/UpdateContentSequence",
  getContent: "/GetContent",
};

const topic = {
  create: "/CreateTopic",
  updateSequence: "/UpdateTopicSequence",
  updateTitle: "/UpdateTopicTitle",
  delete: "/DeleteTopic",
  list: "/ListTopics",
};

const lesson = {
  create: "/CreateLesson",
  updateLesson: "/UpdateLessonTitle",
  updateLessonSequence: "/UpdateLessonSequence",
  delete: "/DeleteLesson",
};

const point = {
  create: "/CreatePoint",
  updatePoint: "/UpdatePointTitle",
  updatePointSequence: "/UpdatePointSequence",
  delete: "/DeletePoint",
};

const subPoint = {
  create: "/CreateSubPoint",
  updateSubPoint: "/UpdateSubPointTitle",
  updateSubPointSequence: "/UpdateSubPointSequence",
  delete: "/DeleteSubPoint",
};

const paragraph = {
  create: "/CreateParagraph",
  update: "/UpdateParagraph",
  delete: "/DeleteParagraph",
};

const list = {
  create: "/CreateList",
  delete: "/DeleteList",
  update: "/UpdateListItem",
};

const comments = {
  create: "/CreateComments",
  update: "/UpdateComments",
  delete: "/DeleteComments",
};

const table = {
  create: "/CreateTable",
  update: "/UpdateTableCell",
  delete: "/DeleteTable",
};

const user = {
  login: `/login`,
  signup: `/signup`,
  update: `/users`,
  getAccount: `/users`,
  inviteUser: "/users",
  uploadProfile: "/UpdateProfilePicture",
  list: "/users",
  activateAccount: `/activate-account`,
  updateAccount: `/update-account`,
  forgetPassword: `/ForgotPassword`,
  changePassword: "/ResetPassword",
};

const notes = {
  create: "/CreateNote",
  update: "/UpdateNote",
  delete: "/DeleteNote",
};

const glossaries = {
  create: "/EditGlossary",
  update: "/EditGlossary",
  delete: "/DeleteGlossaries",
  list: "/GetGlossaries",
};

const organizations = {
  list: "/Organizations",
  update: "/UpdateOrganization",
};
const jobs = {
  list: "/Jobs",
};

const roles = {
  list: "/Roles",
};

const fileRelated = {
  uploadImage: "/UpdateImage",
  uploadPPT: "/UploadPPT",
  downloadPPT: "/DownloadPPT",
};

const routes = {
  user,
  course,
  module,
  topic,
  lesson,
  point,
  subPoint,
  paragraph,
  list,
  table,
  content,
  notes,
  organizations,
  glossaries,
  jobs,
  roles,
  fileRelated,
  comments,
};

export default routes;
