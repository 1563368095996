import { CustomModal } from "app/components/Core";
import CourseController from "app/components/FormikWrapper/CourseForm";

export const CreateCourseModal = ({
  open,
  initialValues,
  handleClose,
  isLoading,
  handleSubmit,
}) => {
  return (
    <CustomModal leftWidth="239px" open={open} handleClose={handleClose}>
      <CourseController
        initialValues={initialValues}
        onSubmit={handleSubmit}
        isSubmittingForm={isLoading}
      />
    </CustomModal>
  );
};
