import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "types";
import { initialState } from ".";
import { IGlossaryState } from "./type";

const selectSlice = (state: RootState) => state?.glossaries || initialState;

export const selectGlossaries: (state: RootState) => IGlossaryState =
  createSelector([selectSlice], (state) => state);

export const selectAllGlossaries = createSelector(
  [selectGlossaries],
  (state) => state.result
);

export const selectGettingGlossaries = createSelector(
  [selectGlossaries],
  (state) => state.isGlossaryLoading
);
