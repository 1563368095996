import styled from "styled-components";
import { Box } from "../Box/Box";
import { ButtonProps } from "../types";
import { theme } from "styles/theme/themes";

interface Props extends ButtonProps {
  type: "horizontal" | "vertical";
  thickness?: number | string;
  hideScrollBar?: boolean;
}
export const OverFlowWrapper = styled(Box)<Props>`
  overflow-x: ${(props) => (props.type === "horizontal" ? "auto" : "hidden")};
  overflow-y: ${(props) => (props.type === "vertical" ? "auto" : "hidden")};
  position: ${(props) => props.position};
  height: ${(props) => props.height || "auto"};
  ::-webkit-scrollbar {
    display: ${(props) => (props.hideScrollBar ? "none" : "initial")};
    height: ${(props) => props.thickness || "5px"};
    width: ${(props) => props.thickness || "5px"};
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.background || theme.colors.grey[400]};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) =>
      props.hideScrollBar ? theme.colors.white[0] : theme.colors.grey[400]};
  }
`;

export const HiddenScrollBarWrapper = styled(OverFlowWrapper)`
  /* Hide Scrollbar */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;
