import { memo, useEffect, useRef, useCallback } from "react";
import EditorJS, {
  API,
  BlockMutationEvent,
  OutputData,
} from "@kalebu2468/editorjs";
import { createEditorJSTools } from "utils/tool";
import Undo from "@kalebu2468/editorjs-undo";
import { useParams } from "react-router-dom";
import { useModuleSlice } from "app/pages/Workplace/slice";
import { useDispatch } from "react-redux";

type Props = {
  data?: OutputData;
  onChange(
    index: number,
    api: API,
    event: BlockMutationEvent | BlockMutationEvent[]
  ): void;
  holder?: string;
  index: number;
  editorData?: string;
  handleInitialize: (index: number, instance: EditorJS, undo: any) => void;
};

const Editor = ({
  data,
  editorData,
  onChange,
  holder,
  index,
  handleInitialize,
}: Props) => {
  const { id: moduleId } = useParams<{ id: string }>();
  const undoRef = useRef<null>(null);
  const { actions } = useModuleSlice();
  const dispatch = useDispatch();

  const handleCommentClick = useCallback(
    (blockId: string) => {
      dispatch(actions.setRightSidebarOpen(true));
      dispatch(actions.setTabValue(0));
      dispatch(actions.setActiveCommentBlockId(blockId));
      dispatch(actions.getComments(blockId));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!moduleId) return;
    const editor = new EditorJS({
      holder: holder,
      tools: createEditorJSTools(moduleId, handleCommentClick) as any,
      tunes: ["comment"],
      data: data,
      async onChange(api, event) {
        onChange(index, api, event);
      },
      readOnly: false,
      onReady: () => {
        if (editor) {
          const undo = new Undo({ editor });
          undo.initialize(data);
          undoRef.current = undo;
          handleInitialize(index, editor, undo);
        }
      },
    });
  }, []);

  return <div id={holder}></div>;
};

export default memo(Editor);
