import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import DefaultLayout from "../DefaultLayout";
import { IProps } from "./types";
import { localstorageGetItem } from "helper/helperFunctions";
import { UnauthorizedPage } from "../403";
import { useSelector } from "react-redux";
import { selectUser } from "../DefaultLayout/slice/selectors";

export default function Authenticated(route: IProps) {
  const { component: Component } = route;
  const token = localstorageGetItem("token");
  const loggedInUser = useSelector(selectUser);
  const location = useLocation();
  const getCorrectLayout = () => {
    if (token) {
      if (loggedInUser?.role == "new") {
        return <UnauthorizedPage />;
      } else {
        return (
          <DefaultLayout>
            <Component />
          </DefaultLayout>
        );
      }
      // eslint-disable-next-line
    }
    return <Navigate to="/login" replace state={{ from: location.pathname }} />;
  };
  return getCorrectLayout();
}
