import { createGlobalStyle } from "styled-components";
import inter100 from "../assets/fonts/Inter/static/Inter-Thin.ttf";
import inter200 from "../assets/fonts/Inter/static/Inter-Light.ttf";
import inter300 from "../assets/fonts/Inter/static/Inter-Regular.ttf";
import inter400 from "../assets/fonts/Inter/static/Inter-Medium.ttf";
import inter500 from "../assets/fonts/Inter/static/Inter-Bold.ttf";

interface StyledProps {
  isFirstEditor: boolean;
  isInvalidBlock: boolean;
}

export const GlobalStyle = createGlobalStyle<StyledProps>`
  html{
    height: 100%;
  }
  body {
    background-color:#f9fafb;
	font-family:Inter-Regular !important;
    margin:0;
    padding:0;
	color: #333333
  }
  html body{
  height:100%;
  overflow: hidden;
  }
  #root {
    min-height:100%;
    min-width:100%;
    position:relative;
  }
  @font-face {
    font-family:'Inter-Thin';
	font-weight:100;
	src: local('Inter-Thin'), url(${inter100}) format('truetype');
  }
  @font-face {
    font-family:'Inter-Light';
	font-weight:300;
	src: local('Inter-Light'), url(${inter200}) format('truetype');
  }
  @font-face {
    font-family:'Inter-Regular';
	font-weight:400;
    src: local('Inter-Regular'), url(${inter300}) format('truetype');
  }
  @font-face {
	font-family:'Inter-Medium';
	font-weight:500;
	src: local('Inter-Medium'), url(${inter400}) format('truetype');
  }
  @font-face {
    font-family:'Inter-Bold';
	font-weight:700;
	src: local('Inter-Bold'), url(${inter500}) format('truetype');
  }
  .MuiMenu-root{
  z-index: 6000;
  }
  .ce-toolbar__content{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 60px !important;
    margin-right: auto !important;
  }
  .ce-block__content{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 70px !important;
    margin-right: auto !important;
  }
  .ce-block__content{
    max-width: unset;
  } 
  .codex-editor__redactor{
    padding-bottom: ${(props) =>
      props.isFirstEditor ? "300px !important" : "0px !important"}
  } 
  .ce-toolbar__plus {
      display: ${(props) => (props.isInvalidBlock ? "none" : "block")}
    }
  .codex-editor{
    z-index: unset
  }
  .ce-example-popup__overlay{
    z-index: 2000
  }
  .ce-example-popup__popup{
    z-index: 3000
  }
  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 30px !important;
  }
  .ce-topic {
  font-size: 2.5em;
  }
  .ce-lesson {
  font-size: 2em;
  }
  .ce-point {
  font-size: 1.5em;
  }
  .ce-subPoint {
  font-size: 1.25em;
  }
  .swiper .swiper-button-next.swiper-button-disabled,
  .swiper .swiper-button-prev.swiper-button-disabled {
    opacity: 0;
  }
.ce-comment-item {
  display: flex;
  align-items: center;
  padding: 3px;
  cursor: pointer;
  transition: background-color 0.2s ease;
   border-radius: 6px;
}

.ce-comment-item:hover {
  background-color: #eff2f5;
}

.ce-comment-item .ce-popover__item-icon {
  margin-right: 10px;
}

.ce-comment-item .ce-popover__item-label {
  font-size: 14px;
}
.ce-topic, .ce-lesson, .ce-point, .ce-subPoint {
  position: relative;
  display: inline-block;
}

  .ce-topic::after, .ce-lesson::after, .ce-point::after, .ce-subPoint::after {
    content: attr(data-tooltip);
    position: absolute;
    left: 100%;
    transform: translateY(0%);
    background: transparent;
    color: #3498DB;
    padding: 3px 8px;
    border-radius: 3px;
    font-size: 10px;
    white-space: nowrap;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
    margin-left: 5px;
  }

  .ce-topic::before, .ce-lesson::before, .ce-point::before, .ce-subPoint::before {
    content: '';
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    border: 5px solid transparent;
    border-right-color: transparent;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
  }

  .ce-topic:hover::after, .ce-topic:hover::before,
  .ce-lesson:hover::after, .ce-lesson:hover::before,
  .ce-point:hover::after, .ce-point:hover::before,
  .ce-subPoint:hover::after, .ce-subPoint:hover::before {
    visibility: visible;
    opacity: 1;
  }
.ce-topic {
  font-size: 2.5em;
}
.ce-topic::after {
  content: "Topic";
}

.ce-lesson {
  font-size: 2em;
}
.ce-lesson::after {
  content: "Lesson";
}

.ce-point {
  font-size: 1.5em;
}
.ce-point::after {
  content: "Point";
}

.ce-subPoint {
  font-size: 1.25em;
}
.ce-subPoint::after {
  content: "Sub Point";
}
  .comment-popover {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  z-index: 9999;
}
  .ce-comment-button {
  width: 34px;
  height: 34px;
  line-height: 32px;
  border-radius: 3px;
  text-align: center;
  background: #fff;
  border: 1px solid #e8e8eb;
  cursor: pointer;
  margin: 0 3px;
}

.ce-comment-button:hover {
  background: #eff2f5;
}

.ce-comment-button svg {
  width: 20px;
  height: 20px;
}
`;
// .ce-popover-item[data-item-name="topics"] {
//   display:  ${(props) =>
//     props.isTopicsDisabled ? "none !important" : "flex"};
// }
// .ce-popover-item[data-item-name="lessons"] {
//   display:  ${(props) =>
//     props.isLessonsDisabled ? "none !important" : "flex"};
// }
// .ce-popover-item[data-item-name="points"] {
//   display:  ${(props) =>
//     props.isPointsDisabled ? "none !important" : "flex"};
// }
// .ce-popover-item[data-item-name="subpoints"] {
//   display:  ${(props) =>
//     props.isSubPointsDisabled ? "none !important" : "flex"};
// }
