import { IProfileFormValues } from "app/pages/Settings/type";
import * as yup from "yup";

const profileValidation: yup.ObjectSchema<IProfileFormValues> = yup
  .object()
  .shape({
    first_name: yup
      .string()
      .required("Name is Required")
      .typeError("please enter a valid first name"),
    email: yup
      .string()
      .email("Please enter valid email")
      .required("Email Address is Required")
      .typeError("email should be a string"),
    last_name: yup
      .string()
      .required("Name is Required")
      .typeError("please enter a valid last name"),
    phone: yup.string().required("Phone is required"),
    address: yup.string(),
    pref_language: yup.string().required("Preferred language is required"),
  });

export default profileValidation;
