import React from "react";
import {
  Card,
  Container,
  Flex,
  Spacer,
  Typography,
  Button as CoreButton,
  Image,
} from "app/components/Core";
import Grid from "@mui/material/Grid";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PasswordForm from "app/components/shared/PasswordForm";
import { IChangePasswordFields } from "./slice/type";
import { usechangePasswordSlice } from "./slice";
import { AccountAvatar, withHeader } from "app/components/shared/WithHeader";
import { ChangePasswordForm } from "./contants";
import {
  selectIsChangePasswordError,
  selectIsChangePasswordLoading,
} from "./slice/selector";
import { selectToastMessage } from "../DefaultLayout/slice/selectors";
import { toast } from "react-toastify";
import { useDefaultLayoutSlice } from "../DefaultLayout/slice";

export default function ChangePasswordPage() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { actions: defaultActions } = useDefaultLayoutSlice();
  const { actions } = usechangePasswordSlice();
  const loading = useSelector(selectIsChangePasswordLoading);
  const isChangedError = useSelector(selectIsChangePasswordError);
  const toastData = useSelector(selectToastMessage);
  const toastID = React.useRef<string | number | undefined>(undefined);
  const onSubmit = (values: IChangePasswordFields) => {
    dispatch(
      actions.changePassword({
        password: values.password,
        confirmPassword: values.confirmPassword,
        token: searchParams.get("token"),
      })
    );
  };
  React.useEffect(() => {
    if (!toast.isActive(toastID.current as React.ReactText) && !!toastData) {
      toastID.current = toast(toastData?.currentMessage, {
        position: "bottom-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        type: toastData?.currentVariant,
        toastId: toastData?.count,
      });
    }
    return () => {
      dispatch(defaultActions.hideAppMessage());
    };
  }, [toastData?.count]);
  const avatarWithHeader = withHeader(AccountAvatar);
  return (
    <Container
      flexDirection={"column"}
      justifyContent="start"
      width={71}
      pt={["60px", "80px"]}
    >
      {isChangedError === false ? (
        <>
          <Flex
            width={71}
            height={71}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Flex width={["250px"]} height={["250px"]}>
              <Image
                width={"100%"}
                height={"100%"}
                src={require("../../../assets/images/ActivationSuccess.png")}
              />
            </Flex>
            <Spacer my="16px" />
            <Typography
              fontFamily="Mulish"
              fontWeight={5}
              fontSize={[6, 8]}
              textAlign="center"
            >
              You have successfully changed your password.
            </Typography>
            <Spacer my="16px" />

            <Flex width={71} justifyContent="center" alignItems="center">
              <CoreButton variant="padded" onClick={() => navigate("/login")}>
                Login
              </CoreButton>
            </Flex>
          </Flex>
        </>
      ) : (
        <>
          {avatarWithHeader()}
          <Spacer my="16px" />
          <Card width={[250, 450, 650, 850]}>
            <Grid container p={[2, 4, 6]} sx={{ width: "100%" }}>
              <Spacer mb="8px" />
              <PasswordForm
                onSubmit={onSubmit}
                initialValues={ChangePasswordForm}
                isLoading={loading}
              />
            </Grid>
          </Card>
        </>
      )}
    </Container>
  );
}
