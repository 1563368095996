import { call, put, takeLatest, select } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import makeCall from "app/API/makeCalls";
import routes from "app/API/api.routes";
import { modulesActions as actions } from ".";
import { TypeOptions } from "react-toastify";
import {
  DEFAULT_DESCRIPTION,
  DEFAULT_TITLE,
  VARIANTS,
} from "app/pages/DefaultLayout/constants";
import { v4 as uuidV4 } from "uuid";
import { defaultLayoutActions } from "app/pages/DefaultLayout/slice";
import { handleAPIError } from "app/pages/DefaultLayout/slice/saga";
import {
  IDeleteModule,
  ISetCourse,
  IUpdateCourse,
} from "app/pages/Courses/slice/types";
import { ILanguage } from "utils/type";

function* updateModuleTitleSaga(action: PayloadAction<IUpdateCourse>) {
  try {
    const response: any = yield call(makeCall, {
      method: "POST",
      isSecureRoute: true,
      route: routes.module.update_title,
      body: {
        module_id: action.payload.course_id,
        title: action.payload.title,
        description: action.payload.description,
      },
    });

    if (response.code === "0") {
      const _response = yield call(makeCall, {
        method: "POST",
        isSecureRoute: true,
        route: routes.module.get,
        body: {
          module_id: action.payload.course_id,
        },
      });

      if (_response.module) {
        const coursesRes: ISetCourse = yield call(makeCall, {
          method: "GET",
          isSecureRoute: true,
          route: routes.course.list,
          query: {
            language: action.payload.language,
          },
        });
        const courses = coursesRes?.courses;
        const courseIndex = courses?.findIndex((course) =>
          course.modules.some(
            (module) => module.id === action.payload.course_id
          )
        );
        const {
          module_id,
          module_title,
          module_sequence_number,
          module_description,
          ...rest
        } = _response.module;
        if (courseIndex && courseIndex !== -1 && courses) {
          const updatedCourse = {
            ...courses[courseIndex],
            modules: courses[courseIndex].modules.map((module) =>
              module.id === action.payload.course_id
                ? {
                    id: _response.module.module_id,
                    title: _response.module.module_title,
                    sequence_num: _response.module.module_sequence_number,
                    description: _response.module.module_description,
                    image_url: _response.module.module_image_url,
                    ...rest,
                  }
                : module
            ),
          };

          const updatedCourses = [
            ...courses.slice(0, courseIndex),
            updatedCourse,
            ...courses.slice(courseIndex + 1),
          ];

          yield put(actions.setModulesState(updatedCourses));
        }
      }

      yield put(
        defaultLayoutActions.setAppMessage({
          currentMessage: DEFAULT_DESCRIPTION.Success,
          currentTitle: DEFAULT_TITLE.Success,
          currentVariant: VARIANTS.SUCCESS as TypeOptions,
          count: uuidV4(),
        })
      );
      yield put(actions.setIsModulesLoading(false));
    }
  } catch (error: any) {
    console.log("ERROR UPDATING MODULE", error);
    yield put(
      defaultLayoutActions.setAppMessage({
        currentMessage: DEFAULT_DESCRIPTION.Error,
        currentTitle: DEFAULT_TITLE.Fail,
        currentVariant: VARIANTS.Error as TypeOptions,
        count: uuidV4(),
      })
    );
    yield put(actions.setIsModulesLoading(false));
    yield call(handleAPIError, error);
  }
}

function* getModulesSaga(action: PayloadAction<ILanguage>) {
  try {
    let response: ISetCourse = yield call(makeCall, {
      method: "GET",
      isSecureRoute: true,
      route: routes.course.list,
      query: {
        language: action.payload,
      },
    });
    if (response.courses) {
      yield put(actions.setModulesState(response.courses));
    }
    yield put(actions.setIsModulesLoading(false));
  } catch (error: any) {
    yield put(actions.setIsModulesLoading(false));
    yield call(handleAPIError, error);
  }
}

function* deleteModuleSaga(action: PayloadAction<IDeleteModule>) {
  try {
    const { course_id, module_id } = action.payload;

    const response = yield call(makeCall, {
      method: "POST",
      isSecureRoute: true,
      route: `${routes.module.delete}`,
      body: {
        module_id,
      },
    });
    if (response.code == "0") {
      if (response.course) {
        const _response: ISetCourse = yield call(makeCall, {
          method: "GET",
          isSecureRoute: true,
          route: routes.course.list,
          query: {
            language: action.payload.language,
          },
        });
        const courses = _response?.courses;
        const courseIndex = courses?.findIndex(
          (course) => course.id == course_id
        );
        if (courseIndex && courseIndex !== -1 && courses) {
          const updatedCourse = {
            ...courses[courseIndex],
            modules: response.course.modules,
          };

          const updatedCourses = [
            ...courses.slice(0, courseIndex),
            updatedCourse,
            ...courses.slice(courseIndex + 1),
          ];
          yield put(actions.setModulesState(updatedCourses));
        }
        yield put(actions.setIsModulesLoading(false));
      }
    }
  } catch (e: any) {
    yield put(
      defaultLayoutActions.setAppMessage({
        currentMessage: DEFAULT_DESCRIPTION.Error,
        currentTitle: DEFAULT_TITLE.Fail,
        currentVariant: VARIANTS.Error as TypeOptions,
        count: uuidV4(),
      })
    );
    yield put(actions.setIsModulesLoading(false));
    yield call(handleAPIError, e);
  }
}

export function* modulesSaga() {
  yield takeLatest(actions.getModules.type, getModulesSaga);
  yield takeLatest(actions.deleteModule.type, deleteModuleSaga);
  yield takeLatest(actions.updateModuleTitle.type, updateModuleTitleSaga);
}
