import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { useFormik } from "formik";
import { importModuleValidationSchema } from "app/components/Form/Validation/importModuleValidation";
import { useDispatch, useSelector } from "react-redux";
import { useImportModuleSlice } from "./slice";
import {
  selectIsImportModuleLoading,
  selectIsImportModuleSuccessful,
} from "./slice/selector";
import { useCourseSlice } from "../Courses/slice";
import { selectCourses } from "../Courses/slice/selectors";
import {
  selectGlobalLanguage,
  selectIsImportModuleDisabled,
} from "../DefaultLayout/slice/selectors";
import { useNavigate } from "react-router-dom";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { theme as styledTheme } from "styles/theme/themes";

export default function ImportPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const globalLanguage = useSelector(selectGlobalLanguage);
  const isLoading = useSelector(selectIsImportModuleLoading);
  const isSuccess = useSelector(selectIsImportModuleSuccessful);
  const courses = useSelector(selectCourses);
  const { actions } = useImportModuleSlice();
  const { actions: courseActions } = useCourseSlice();
  const isPageDisabled = useSelector(selectIsImportModuleDisabled);
  const formik = useFormik({
    initialValues: {
      url: "",
      courseId: "",
    },
    validationSchema: importModuleValidationSchema,
    onSubmit: (values) => {
      let formValues = {
        courseId: values.courseId,
        url: values.url,
      };
      dispatch(actions.importModule(formValues));
    },
  });

  React.useEffect(() => {
    dispatch(courseActions.getCourses(globalLanguage!));
  }, [globalLanguage]);

  React.useEffect(() => {
    if (isSuccess) {
      navigate("/jobs");
      dispatch(actions.setIsImportModuleSuccessful(false));
    }
  }, [isSuccess]);

  const courseOptions =
    courses.result?.map((course) => ({
      id: course.id,
      title: course.title,
    })) || [];

  return (
    <>
      {isPageDisabled ? (
        <></>
      ) : (
        <Grid container spacing={3} sx={{ p: 2 }}>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: 600,
                color: styledTheme.colors.primary.main,
              }}
              gutterBottom
            >
              Import Module
            </Typography>
          </Grid>
          <Grid item container xs={12} spacing={3}>
            <Grid item xs={12} md={6}>
              <form onSubmit={formik.handleSubmit}>
                <Box mb={2}>
                  <FormControl
                    fullWidth
                    error={
                      formik.touched.courseId && Boolean(formik.errors.courseId)
                    }
                  >
                    <Select
                      id="courseId"
                      name="courseId"
                      value={formik.values.courseId}
                      onChange={formik.handleChange}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select a course
                      </MenuItem>
                      {courseOptions.map((course) => (
                        <MenuItem key={course.id} value={course.id}>
                          {course.title}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.courseId && formik.errors.courseId && (
                      <FormHelperText>{formik.errors.courseId}</FormHelperText>
                    )}
                  </FormControl>
                </Box>
                <Box mb={2}>
                  <TextField
                    id="url"
                    name="url"
                    variant="outlined"
                    placeholder="Paste your URL here..."
                    fullWidth
                    sx={{
                      backgroundColor: "#f0f0f0",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#ccc",
                        },
                        "&:hover fieldset": {
                          borderColor: "#888",
                        },
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton edge="start">
                            <ContentPasteIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    value={formik.values.url}
                    onChange={formik.handleChange}
                    error={formik.touched.url && Boolean(formik.errors.url)}
                    helperText={formik.touched.url && formik.errors.url}
                  />
                </Box>
                <Button
                  disabled={isLoading || !courseOptions.length}
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                >
                  Import
                </Button>
              </form>
            </Grid>
            <Grid item xs={12} md={6} sx={{ display: "flex" }}>
              <Divider orientation="vertical" flexItem sx={{ mr: 3 }} />
              <Box>
                <Typography variant="h6" gutterBottom>
                  How to Import a Module
                </Typography>
                <Typography variant="body1" paragraph>
                  Follow these steps to import your course:
                </Typography>
                <ol>
                  <li>
                    <Typography variant="body2" paragraph>
                      Select a predefined course from the dropdown menu.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body2" paragraph>
                      Copy the URL of your module content from Google Drive.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body2" paragraph>
                      Paste the URL into the text field provided.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body2">
                      Click the "Import" button to start the import process.
                    </Typography>
                  </li>
                </ol>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  Note: Make sure your Google Drive content is accessible to the
                  application.
                </Typography>
                <Typography variant="body2" color="error">
                  Limitation: Italic/Bold may not be maintained make sure to
                  make updates manually
                </Typography>
                <Typography variant="body2" color="error">
                  Images will not be captured (if any)
                </Typography>
                <Typography variant="body2" color="error">
                  Headers and Footers will not be captured
                </Typography>
                <Typography variant="body2" color="error">
                  Validate the content after importing the module. Although rare
                  but some contents might get skipped in the import process
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
