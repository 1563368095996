import { ISignUpFormValues } from "app/pages/SignUpPage/types";
import { ILanguage } from "utils/type";
import * as yup from "yup";

const signUpValidation: yup.ObjectSchema<ISignUpFormValues> = yup
  .object()
  .shape({
    first_name: yup
      .string()
      .required("Name is Required")
      .typeError("please enter a valid first name"),
    last_name: yup
      .string()
      .required("Name is Required")
      .typeError("please enter a valid last name"),
    password: yup
      .string()
      .min(8, ({ min }) => `Password must be at least ${min} characters`)
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .test("confirmPassword", "Passwords must match", function (value) {
        return this.parent.password === value;
      }),
    phone: yup.string().typeError("please enter a valid phone number"),
    preferred_language: yup
      .string()
      .required("Preferred Language is Required")
      .oneOf(Object.values(ILanguage), "Please select a valid language"),
  });

export default signUpValidation;
