import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "types";
import { initialState } from ".";
import { ITopicsState } from "./types";

const selectSlice = (state: RootState) => state?.topics || initialState;

export const selectTopics: (state: RootState) => ITopicsState = createSelector(
  [selectSlice],
  (state) => state
);

export const selectAllTopics = createSelector(
  [selectTopics],
  (state) => state.result
);

export const selectGettingTopics = createSelector(
  [selectTopics],
  (state) => state.isTopicLoading
);
