import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Box,
  Typography,
  Tooltip,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Card,
  CardContent,
  useTheme,
  IconButton,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  FormHelperText,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import CalendarViewDayIcon from "@mui/icons-material/CalendarViewDay";
import TableChartIcon from "@mui/icons-material/TableChart";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Input } from "../../components/Form/FormField/_Input";
import { truncate, turnToHumanReadable } from "helper/helperFunctions";
import { v4 as uuidv4 } from "uuid";
import { selectAllTopics } from "./slice/selector";
import { theme as styledTheme } from "styles/theme/themes";
import { useTopicsSlice } from "./slice";
import { CreateModuleModal } from "../Course/CourseDetails";
import { CustomModal } from "app/components/Core";
import { ITopic, ITopicsRes } from "./slice/types";
import { selectGlobalLanguage } from "../DefaultLayout/slice/selectors";

export default function Topics() {
  const dispatch = useDispatch();
  const { actions } = useTopicsSlice();
  const [alignment, setAlignment] = useState("list");
  const navigate = useNavigate();
  const allTopics = useSelector(selectAllTopics) as ITopicsRes[] | null;
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredTopics, setFilteredTopics] = useState<ITopic[]>([]);
  const theme = useTheme();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [editInitialValues, setEditInitialValues] = useState({
    title: "",
  });
  const [editingTopicId, setEditingTopicId] = useState("");
  const [courseFilter, setCourseFilter] = useState("");
  const [moduleFilter, setModuleFilter] = useState("");
  const [tempCourseFilter, setTempCourseFilter] = useState("");
  const [tempModuleFilter, setTempModuleFilter] = useState("");
  const globalLanguage = useSelector(selectGlobalLanguage);

  useEffect(() => {
    dispatch(actions.getTopics(globalLanguage!));
  }, [globalLanguage]);

  useEffect(() => {
    if (allTopics) {
      applyFilters(searchTerm, courseFilter, moduleFilter);
    }
  }, [allTopics, searchTerm, courseFilter, moduleFilter]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputText = event.target.value;
    setSearchTerm(inputText);
  };

  const applyFilters = useCallback(
    (search: string, course: string, module: string) => {
      if (allTopics) {
        const filtered = allTopics
          .filter((c) => course === "" || c.course_title === course)
          .flatMap((c) =>
            c.modules
              .filter((m) => module === "" || m.module_title === module)
              .flatMap((m) =>
                m.topics
                  .filter((t) =>
                    t.topic_title.toLowerCase().includes(search.toLowerCase())
                  )
                  .map((t) => ({
                    ...t,
                    module_title: m.module_title,
                    course_title: c.course_title,
                  }))
              )
          );
        setFilteredTopics(filtered);
      }
    },
    [allTopics]
  );

  const handleEdit = (topicId: string, title: string) => {
    setEditingTopicId(topicId);
    setEditInitialValues({ title });
    setIsEditModalOpen(true);
  };

  const handleDelete = (topicId: string) => {
    dispatch(actions.deleteElement(topicId));
  };

  const handleEditSubmit = (values: { title: string }) => {
    dispatch(
      actions.updateElement({
        element_id: editingTopicId,
        title: values.title,
      })
    );
    setIsEditModalOpen(false);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
  };

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);
  };

  const handleCourseFilterChange = (event: SelectChangeEvent<string>) => {
    setTempCourseFilter(event.target.value);
    setTempModuleFilter("");
  };

  const handleModuleFilterChange = (event: SelectChangeEvent<string>) => {
    setTempModuleFilter(event.target.value);
  };

  const handleApplyFilter = () => {
    setCourseFilter(tempCourseFilter);
    setModuleFilter(tempModuleFilter);
    setIsFilterModalOpen(false);
  };

  const handleClearFilter = () => {
    setSearchTerm("");
    setCourseFilter("");
    setModuleFilter("");
    setTempCourseFilter("");
    setTempModuleFilter("");
    if (allTopics) {
      applyFilters("", "", "");
    }
  };

  const uniqueCourses = Array.from(
    new Set(allTopics?.map((course) => course.course_title))
  );

  const uniqueModules = Array.from(
    new Set(
      allTopics
        ?.find((course) => course.course_title === tempCourseFilter)
        ?.modules.map((module) => module.module_title) || []
    )
  );

  const renderTableView = () => (
    <TableContainer
      component={Paper}
      sx={{ maxHeight: "calc(100vh - 200px)", overflow: "auto" }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="topics table">
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: theme.palette.grey[100],
              borderBottom: `2px solid ${theme.palette.divider}`,
            }}
          >
            <TableCell
              sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
            >
              Topic Title
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
            >
              Module
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
            >
              Course
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
            >
              Last Modified
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
            >
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredTopics.map((topic, index) => (
            <TableRow
              key={uuidv4()}
              sx={{
                "&:nth-of-type(even)": {
                  backgroundColor: theme.palette.action.hover,
                },
                borderBottom: `1px solid ${theme.palette.divider}`,
              }}
            >
              <TableCell component="th" scope="row">
                {topic.topic_title}
              </TableCell>
              <TableCell>{topic.module_title}</TableCell>
              <TableCell>{topic.course_title}</TableCell>
              <TableCell>{turnToHumanReadable(topic.modified_date)}</TableCell>
              <TableCell>
                <Box sx={{ display: "flex" }}>
                  <IconButton
                    onClick={() =>
                      handleEdit(topic.topic_id, topic.topic_title)
                    }
                    color="primary"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleDelete(topic.topic_id)}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderCardView = () => (
    <Grid
      container
      spacing={3}
      sx={{ maxHeight: "calc(100vh - 200px)", overflow: "auto" }}
    >
      {filteredTopics.map((topic) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={uuidv4()}>
          <Card
            sx={{
              height: "100%",
              borderLeft: `4px solid ${theme.palette.primary.main}`,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography
                variant="h6"
                sx={{
                  cursor: "pointer",
                  mb: 2,
                }}
                onClick={() => navigate(`/topic/${topic.topic_id}`)}
              >
                {truncate(topic.topic_title, 20)}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Module: {topic.module_title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Course: {topic.course_title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Last modified: {turnToHumanReadable(topic.modified_date)}
              </Typography>
            </CardContent>
            <Box
              sx={{
                mt: "auto",
                display: "flex",
                justifyContent: "space-between",
                p: 2,
                borderTop: `1px solid ${theme.palette.divider}`,
              }}
            >
              <Button
                size="small"
                startIcon={<EditIcon />}
                onClick={() => handleEdit(topic.topic_id, topic.topic_title)}
              >
                Edit
              </Button>
              <Button
                size="small"
                startIcon={<DeleteIcon />}
                color="error"
                onClick={() => handleDelete(topic.topic_id)}
              >
                Delete
              </Button>
            </Box>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Box
      sx={{
        p: 3,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      <CreateModuleModal
        isOpen={isEditModalOpen}
        elementType="Topic"
        handleClose={handleCloseEditModal}
        handleSubmit={handleEditSubmit}
        title="Update"
        initialFormValues={editInitialValues}
      />
      <CustomModal
        open={isFilterModalOpen}
        handleClose={() => setIsFilterModalOpen(false)}
      >
        <Box
          sx={{
            p: 4,
            width: 350,
            backgroundColor: theme.palette.background.paper,
            borderRadius: theme.shape.borderRadius,
            boxShadow: theme.shadows[5],
            position: "relative",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={() => setIsFilterModalOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h6"
            sx={{
              mb: 3,
              fontWeight: "bold",
              color: theme.palette.primary.main,
            }}
          >
            Filter Topics
          </Typography>
          <FormControl fullWidth sx={{ mb: 3 }}>
            <InputLabel id="course-filter-label">Filter by Course</InputLabel>
            <Select
              labelId="course-filter-label"
              id="course-filter"
              value={tempCourseFilter}
              label="Filter by Course"
              onChange={handleCourseFilterChange}
            >
              <MenuItem value="">
                <em>All Courses</em>
              </MenuItem>
              {uniqueCourses.map((course) => (
                <MenuItem key={course} value={course}>
                  {course}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 3 }}>
            <InputLabel id="module-filter-label">Filter by Module</InputLabel>
            <Select
              labelId="module-filter-label"
              id="module-filter"
              value={tempModuleFilter}
              label="Filter by Module"
              onChange={handleModuleFilterChange}
              disabled={!tempCourseFilter}
            >
              <MenuItem value="">
                <em>All Modules</em>
              </MenuItem>
              {uniqueModules.map((module) => (
                <MenuItem key={module} value={module}>
                  {module}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            onClick={handleApplyFilter}
            fullWidth
            sx={{
              mt: 2,
              backgroundColor: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            Apply Filter
          </Button>
        </Box>
      </CustomModal>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={7}>
          <Input
            isFancy
            placeholder="Search"
            endAdornment={
              <InputAdornment
                position="end"
                sx={{ width: "25px", height: "25px", paddingTop: 1 }}
              >
                <SearchIcon fontSize="medium" />
              </InputAdornment>
            }
            sx={{
              backgroundColor: "#FFFFFF",
              boxShadow: styledTheme.shadows[9],
              padding: styledTheme.spaces[6],
              width: "40%",
            }}
            value={searchTerm}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={5}>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Button
              variant="outlined"
              onClick={handleClearFilter}
              sx={{ mr: 2 }}
            >
              Clear Filters
            </Button>
            <IconButton
              size="small"
              onClick={() => setIsFilterModalOpen(true)}
              sx={{ mr: 2 }}
            >
              <FilterListIcon />
            </IconButton>
            <ToggleButtonGroup
              color="primary"
              value={alignment}
              exclusive
              onChange={handleChange}
            >
              <ToggleButton value="list">
                <Tooltip title="List View">
                  <CalendarViewDayIcon />
                </Tooltip>
              </ToggleButton>
              <ToggleButton value="card">
                <Tooltip title="Card View">
                  <TableChartIcon />
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ mt: 4 }}>
        {alignment === "list" ? renderTableView() : renderCardView()}
      </Box>
    </Box>
  );
}
