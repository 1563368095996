/* eslint-disable array-callback-return */
import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { importModuleSaga } from "./saga";
import { EImportModuleForm, EImportModuleState } from "./type";

export const initialState: EImportModuleState = {
  isImportModuleLoading: false,
  isImportModuleSuccess: false,
};

const slice = createSlice({
  name: "importModule",
  initialState,
  reducers: {
    importModule(state, action: PayloadAction<EImportModuleForm>) {
      state.isImportModuleLoading = true;
    },
    setIsImportModuleLoading(state, action: PayloadAction<boolean>) {
      state.isImportModuleLoading = action.payload;
    },
    setIsImportModuleSuccessful(state, action: PayloadAction<boolean>) {
      state.isImportModuleSuccess = action.payload;
    },
  },
});

export const { actions: importModuleActions } = slice;
export const useImportModuleSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: importModuleSaga });
  return { actions: slice.actions };
};
export const importModuleReducers = slice.reducer;

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useCartSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
