import React from "react";
import styled from "styled-components";
import { FieldProps } from "formik";
import { theme } from "styles/theme/themes";

interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  width?: string;
  height?: string;
}

const StyledTextArea = styled.textarea<TextAreaProps>`
  width: ${(props) => props.width || "100%"};
  min-height: ${(props) => props.height || "100px"};
  padding: ${theme.spaces[3]};
  font-size: ${theme.fontSizes[4]};
  font-family: ${theme.fonts.nunito};
  color: ${theme.colors.grey[600]};
  background-color: ${theme.colors.grey[100]};
  border: 1px solid ${theme.colors.grey[300]};
  border-radius: ${theme.radii[2]};
  resize: vertical;
  overflow-y: auto;
  overflow-x: hidden;
  word-wrap: break-word;

  &:focus {
    outline: none;
    border-color: ${theme.colors.primary};
  }

  ::placeholder {
    color: ${theme.colors.grey[500]};
  }
`;

export const TextArea: React.FC<TextAreaProps & FieldProps> = ({
  field,
  form,
  ...props
}) => <StyledTextArea {...field} {...props} />;
