import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "types";
import { initialState } from ".";

const selectSlice = (state: RootState) => state?.changePassword || initialState;

export const selectIsChangePasswordLoading = createSelector(
  [selectSlice],
  (state) => state.isChangingPasswordLoading
);

export const selectIsChangePasswordError = createSelector(
  [selectSlice],
  (state) => state.isChangePasswordError
);
