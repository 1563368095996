import { ILanguage } from "utils/type";
import { ISignUpFormValues } from "./types";

export const signUpFormInitialValues: ISignUpFormValues = {
  first_name: "",
  last_name: "",
  password: "",
  confirmPassword: "",
  phone: "",
  preferred_language: ILanguage.en,
};
