import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "types";
import { initialState } from ".";
import { CourseState } from "./types";

const selectSlice = (state: RootState) => state?.courses || initialState;

export const selectCourses: (state: RootState) => CourseState = createSelector(
  [selectSlice],
  (state) => state
);

export const selectModuleById = createSelector(
  [selectSlice, (state, param) => param],
  (state, param) => {
    const courses = state.result?.filter((crs) => crs.id == param);
    if (courses) return courses[0]?.modules;
  }
);
export const selectCourseById = createSelector(
  [selectSlice, (state, param) => param],
  (state, param) => {
    const courses = state.result?.filter((crs) => crs.id == param);
    if (courses) return courses[0];
  }
);

export const selectAllCourses = createSelector(
  [selectCourses],
  (state) => state.result
);

export const selectModules = createSelector([selectCourses], (state) => {
  return (
    state.result?.flatMap((course) =>
      course.modules.map((module) => ({
        ...module,
        course_id: course.id,
        course_title: course.title,
      }))
    ) || []
  );
});

export const selectGettingCousrses = createSelector(
  [selectCourses],
  (state) => state.isCourseLoading
);
