import * as Yup from "yup";

export const addOrganizationValidationSchema = Yup.object().shape({
  name: Yup.string().required("Organization name is required"),
  email: Yup.string().email("Invalid email"),
  address: Yup.string(),
  aws_access_key: Yup.string(),
  aws_secret_key: Yup.string(),
  ses_region: Yup.string(),
  ses_username: Yup.string(),
  ses_password: Yup.string(),
  openai_api_key: Yup.string(),
  coudinary_cloud_name: Yup.string(),
  cloudinary_api_key: Yup.string(),
  cloudinary_api_secret: Yup.string(),
  deepl_api_key: Yup.string(),
  eleven_labs_api_key: Yup.string(),
  anthropic_api_key: Yup.string(),
  perplexity_api_key: Yup.string(),
  root_user_email: Yup.string()
    .email("Invalid email")
    .required("Root user email is required"),
  root_user_first_name: Yup.string().required(
    "Root user first name is required"
  ),
  root_user_lastname: Yup.string().required("Root user last name is required"),
  root_user_pref_language: Yup.string().required(
    "Root user preferred language is required"
  ),
  root_user_phone: Yup.string().required("Root user phone is required"),
  root_user_password: Yup.string().required("Root user password is required"),
});
