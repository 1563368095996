import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "types";
import { initialState } from ".";
import { ISettingState } from "./types";

const selectSlice = (state: RootState) => state?.setting || initialState;

export const selectSetting: (state: RootState) => ISettingState =
  createSelector([selectSlice], (state) => state);

export const selectRoles = createSelector([selectSetting], (state) =>
  state.roles?.reduce((acc: any, curr: any) => {
    if (curr.name != "super_user") {
      acc = [
        ...acc,
        {
          id: curr.id,
          name: curr.name.charAt(0).toUpperCase() + curr.name.slice(1),
        },
      ];
    }
    return acc;
  }, [])
);

export const selectUsers = createSelector(
  [selectSetting],
  (state) => state.users
);
