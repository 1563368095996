import React from "react";
import { Box, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  selectGlobalLanguage,
  selectUser,
} from "app/pages/DefaultLayout/slice/selectors";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import { ILanguage } from "utils/type";
import { Spacer } from "app/components/Core";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import DoneIcon from "@mui/icons-material/Done";
import Button from "@mui/material/Button";
import { useDefaultLayoutSlice } from "app/pages/DefaultLayout/slice";
import { languageOptions } from "utils/constants";
import { useNavigate } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import { clearLocalStorage } from "utils/localStorage";
import { addTimestampToUrl } from "helper/helperFunctions";

const TopBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { actions } = useDefaultLayoutSlice();
  const globalLanguage = useSelector(selectGlobalLanguage);
  const loggedInUser = useSelector(selectUser);
  const [anchorElLanguage, setAnchorElLanguage] =
    React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const getCurrentLanguageLabel = () => {
    const currentLanguage = languageOptions.find(
      (lang) => lang.value === globalLanguage
    );
    return currentLanguage?.label || "Language";
  };

  const handleTranslate = (lang: ILanguage) => {
    dispatch(actions.setGlobalLanguage(lang));
    setAnchorElLanguage(null);
  };

  const handleLanguageOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElLanguage(event.currentTarget);
  };

  const handleLanguageClose = () => {
    setAnchorElLanguage(null);
  };

  const handleUserMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    dispatch(actions.clearUser());
    clearLocalStorage();
    navigate("/login");
  };

  const handleProfile = () => {
    navigate("/settings");
  };

  const isLanguageOpen = Boolean(anchorElLanguage);
  const isUserMenuOpen = Boolean(anchorElUser);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        padding: "1rem",
        borderBottom: "1px solid #e0e0e0",
        zIndex: 5001,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          columnGap: 2,
          alignItems: "center",
        }}
      >
        <Button
          onClick={handleLanguageOpen}
          endIcon={isLanguageOpen ? <ExpandLess /> : <ExpandMore />}
        >
          {getCurrentLanguageLabel()}
        </Button>
        <IconButton onClick={handleUserMenuOpen}>
          <Avatar
            src={
              addTimestampToUrl(loggedInUser?.image_url!) || "/broken-image.jpg"
            }
          />
        </IconButton>
      </Box>
      <Spacer mr={3} />
      <Menu
        id="language-menu"
        anchorEl={anchorElLanguage}
        open={isLanguageOpen}
        onClose={handleLanguageClose}
      >
        {languageOptions
          .filter((language) => language.value !== globalLanguage)
          .map((language) => (
            <MenuItem
              key={language.value}
              onClick={() => handleTranslate(language.value)}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: 1,
                }}
              >
                <Box>{language.label}</Box>
                <Box sx={{ mb: "2px" }}>
                  {language.value == globalLanguage && (
                    <DoneIcon fontSize="small" />
                  )}
                </Box>
              </Box>
            </MenuItem>
          ))}
      </Menu>
      <Menu
        id="user-menu"
        anchorEl={anchorElUser}
        open={isUserMenuOpen}
        onClose={handleUserMenuClose}
      >
        <MenuItem onClick={handleProfile}>
          <SettingsIcon sx={{ mr: 1 }} />
          Profile
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <LogoutIcon sx={{ mr: 1 }} />
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default TopBar;
