import { ReactNode } from "react";
import Box from "@mui/material/Box";
interface IProps {
  children: ReactNode;
}

export default function DashboardBody(props: IProps) {
  return (
    <Box display=" flex" flex={1} position="relative">
      {props.children}
    </Box>
  );
}
