export interface IPagination {
  offset?: number;
  limit?: number;
  total?: number;
}

export enum ILanguage {
  en = "EN",
  de = "DE",
  es = "ES",
  fr = "FR",
  it = "IT",
  nl = "NL",
}
