import { call, put, takeLatest, select } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import makeCall from "app/API/makeCalls";
import routes from "app/API/api.routes";
import { ICourseModel, IModuleSchema } from "app/Model/course";
import {
  ICreateCourse,
  ICreateModule,
  ICreateModuleRes,
  IDeleteModule,
  ISetCourse,
  IUpdateCourse,
  IUpdateModule,
  IUpdateModuleRes,
} from "./type";
import { courseActions as actions } from ".";
import { TypeOptions } from "react-toastify";
import {
  DEFAULT_DESCRIPTION,
  DEFAULT_TITLE,
  VARIANTS,
} from "app/pages/DefaultLayout/constants";
import { v4 as uuidV4 } from "uuid";
import { defaultLayoutActions } from "app/pages/DefaultLayout/slice";
import { handleAPIError } from "app/pages/DefaultLayout/slice/saga";
import { selectGlobalLanguage } from "app/pages/DefaultLayout/slice/selectors";
import { ILanguage } from "utils/type";

function* createModuleSaga(action: PayloadAction<ICreateModule>) {
  try {
    yield put(defaultLayoutActions.setIsModuleCreating(true));
    const globalLanguage = yield select(selectGlobalLanguage);
    const response: ICreateModuleRes = yield call(makeCall, {
      method: "POST",
      isSecureRoute: true,
      route: routes.module.create,
      body: { ...action.payload, language: globalLanguage },
    });
    if (response.code === "0") {
      yield put(actions.setModuleCourse(response));
      yield put(
        defaultLayoutActions.setAppMessage({
          currentMessage: DEFAULT_DESCRIPTION.Success,
          currentTitle: DEFAULT_TITLE.Success,
          currentVariant: VARIANTS.SUCCESS as TypeOptions,
          count: uuidV4(),
        })
      );
      yield put(defaultLayoutActions.setIsModuleCreating(response.module_id));
    }
  } catch (error: any) {
    yield put(
      defaultLayoutActions.setAppMessage({
        currentMessage: DEFAULT_DESCRIPTION.Error,
        currentTitle: DEFAULT_TITLE.Fail,
        currentVariant: VARIANTS.Error as TypeOptions,
        count: uuidV4(),
      })
    );
    yield put(defaultLayoutActions.setIsModuleCreating(false));
    yield call(handleAPIError, error);
  }
}

function* updateModuleSequenceSaga(action: PayloadAction<IUpdateModule>) {
  try {
    const { course_id, ...rest } = action.payload;
    const response: IUpdateModuleRes = yield call(makeCall, {
      method: "POST",
      isSecureRoute: true,
      route: routes.module.update,
      body: {
        ...rest,
      },
    });
    if (response.code == "0") {
      const res = {
        modules: response.modules,
        course_id,
      };
      yield put(actions.setModuleCourseUpdate(res));
      yield put(
        defaultLayoutActions.setAppMessage({
          currentMessage: DEFAULT_DESCRIPTION.Success,
          currentTitle: DEFAULT_TITLE.Success,
          currentVariant: VARIANTS.SUCCESS as TypeOptions,
          count: uuidV4(),
        })
      );
      yield put(actions.setIsCourseLoading(false));
    }
  } catch (error: any) {
    yield put(actions.setIsCourseLoading(false));
    yield call(handleAPIError, error);
  }
}

function* updateModuleTitleSaga(action: PayloadAction<IUpdateCourse>) {
  try {
    const response: any = yield call(makeCall, {
      method: "POST",
      isSecureRoute: true,
      route: routes.module.update_title,
      body: {
        module_id: action.payload.course_id,
        title: action.payload.title,
        description: action.payload.description,
      },
    });

    if (response.code === "0") {
      const _response = yield call(makeCall, {
        method: "POST",
        isSecureRoute: true,
        route: routes.module.get,
        body: {
          module_id: action.payload.course_id,
        },
      });

      if (_response.module) {
        let coursesResponse: ISetCourse = yield call(makeCall, {
          method: "GET",
          isSecureRoute: true,
          route: routes.course.list,
          query: {
            language: action.payload.language,
          },
        });
        if (coursesResponse.courses) {
          const courseIndex = coursesResponse.courses.findIndex((course) =>
            course.modules.some(
              (module) => module.id === action.payload.course_id
            )
          );
          const {
            module_id,
            module_title,
            module_sequence_number,
            module_description,
            ...rest
          } = _response.module;
          if (courseIndex !== -1) {
            const updatedCourse = {
              ...coursesResponse.courses[courseIndex],
              modules: coursesResponse.courses[courseIndex].modules.map(
                (module) =>
                  module.id === action.payload.course_id
                    ? {
                        id: _response.module.module_id,
                        title: _response.module.module_title,
                        sequence_num: _response.module.module_sequence_number,
                        description: _response.module.module_description,
                        image_url: _response.module.module_image_url,
                        ...rest,
                      }
                    : module
              ),
            };
            yield put(actions.setCourseState(updatedCourse));
            yield put(actions.setModuleState(_response.module));
          }
        }
      }

      yield put(
        defaultLayoutActions.setAppMessage({
          currentMessage: DEFAULT_DESCRIPTION.Success,
          currentTitle: DEFAULT_TITLE.Success,
          currentVariant: VARIANTS.SUCCESS as TypeOptions,
          count: uuidV4(),
        })
      );
      yield put(actions.setIsCourseLoading(false));
    }
  } catch (error: any) {
    console.log("ERROR UPDATING MODULE", error);
    yield put(
      defaultLayoutActions.setAppMessage({
        currentMessage: DEFAULT_DESCRIPTION.Error,
        currentTitle: DEFAULT_TITLE.Fail,
        currentVariant: VARIANTS.Error as TypeOptions,
        count: uuidV4(),
      })
    );
    yield put(actions.setIsCourseLoading(false));
    yield call(handleAPIError, error);
  }
}

function* getCourseSaga(
  action: PayloadAction<{ course_id: string; language: ILanguage }>
) {
  try {
    let response: ISetCourse = yield call(makeCall, {
      method: "GET",
      isSecureRoute: true,
      route: routes.course.list,
      query: { language: action.payload.language },
    });
    if (response.courses) {
      const course = response.courses.filter(
        (course) => course.id == action.payload.course_id
      );
      yield put(actions.setCourseState(course[0]));
    }
    yield put(actions.setIsCourseLoading(false));
  } catch (error: any) {
    yield put(actions.setIsCourseLoading(false));
    yield call(handleAPIError, error);
  }
}

function* getModuleSaga(action: PayloadAction<string>) {
  try {
    const response = yield call(makeCall, {
      method: "POST",
      isSecureRoute: true,
      route: routes.module.get,
      body: {
        module_id: action.payload,
      },
    });
    if (response.module) {
      yield put(actions.setModuleState(response.module));
    }
    yield put(actions.setIsCourseLoading(false));
  } catch (error: any) {
    yield put(actions.setIsCourseLoading(false));
    yield call(handleAPIError, error);
    console.log("Error Getting Module", error);
  }
}

function* deleteModuleSaga(action: PayloadAction<IDeleteModule>) {
  try {
    const { course_id, module_id } = action.payload;

    const response = yield call(makeCall, {
      method: "POST",
      isSecureRoute: true,
      route: `${routes.module.delete}`,
      body: {
        module_id,
      },
    });
    if (response.code == "0") {
      if (response.course) {
        let coursesResponse: ISetCourse = yield call(makeCall, {
          method: "GET",
          isSecureRoute: true,
          route: routes.course.list,
          query: {
            language: action.payload.language,
          },
        });
        if (coursesResponse.courses) {
          const courseIndex = coursesResponse.courses.findIndex(
            (course) => course.id == course_id
          );
          if (courseIndex !== -1) {
            const updatedCourse = {
              ...coursesResponse.courses[courseIndex],
              modules: response.course.modules,
            };
            yield put(actions.setCourseState(updatedCourse));
          }
        }
      }
      yield put(
        defaultLayoutActions.setAppMessage({
          currentMessage: DEFAULT_DESCRIPTION.Success,
          currentTitle: DEFAULT_TITLE.Success,
          currentVariant: VARIANTS.SUCCESS as TypeOptions,
          count: uuidV4(),
        })
      );
      yield put(actions.setIsCourseLoading(false));
    }
  } catch (e: any) {
    yield put(
      defaultLayoutActions.setAppMessage({
        currentMessage: DEFAULT_DESCRIPTION.Error,
        currentTitle: DEFAULT_TITLE.Fail,
        currentVariant: VARIANTS.Error as TypeOptions,
        count: uuidV4(),
      })
    );
    yield put(actions.setIsCourseLoading(false));
    yield call(handleAPIError, e);
  }
}

export function* courseDetailSaga() {
  yield takeLatest(actions.getCourse.type, getCourseSaga);
  yield takeLatest(actions.getModule.type, getModuleSaga);
  yield takeLatest(actions.createModule.type, createModuleSaga);
  yield takeLatest(actions.deleteModule.type, deleteModuleSaga);
  yield takeLatest(actions.updateModuleSequence.type, updateModuleSequenceSaga);
  yield takeLatest(actions.updateModuleTitle.type, updateModuleTitleSaga);
}
