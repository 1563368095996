/* eslint-disable array-callback-return */
import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import {
  IElementSequenceRes,
  IModuleRes,
  IModuleSchema,
} from "app/Model/course";
import { moduleSaga } from "./saga";
import {
  ICreateElement,
  IDeleteElement,
  IFileUploadState,
  IGetModulePayload,
  IModuleComment,
  IParsedLessonRes,
  IParsedPointRes,
  IParsedSubPointRes,
  IParsedTopicRes,
  ISequenceUpdateElement,
  IUpdateElementSequence,
  InvalidBlock,
  ModuleState,
  ParsedResponse,
} from "./type";
import { produce } from "immer";
import {
  findElementParents,
  findNextInvalidBlock,
  mapApiResponseToModuleSchema,
} from "helper/helperFunctions";
import { ILanguage } from "utils/type";

export const initialState: ModuleState = {
  //   result: {
  //     module_id: "",
  //     module_title: "",
  //     module_sequence_number: 0,
  //   },
  result: undefined,
  isModuleLoading: false,
  isContentLoading: false,
  content: {},
  isValidationModalOpen: false,
  isDownloadingItem: false,
  isElementAdding: false,
  language: ILanguage.en,
  lockError: undefined,
  lockErrorWarningModal: false,
  imageGenerationError: undefined,
  imageGenerateWarningModal: false,
  contentError: undefined,
  isContentFailedModalOpen: false,
  isElementDeleting: false,
  hierarchyError: false,
  isEditorEmpty: false,
  isGlossaryModalOpen: "",
  targetModuleGlossary: [],
  selectedGlossaryIds: [],
  isHasChangeModal: "",
  isUpdatingElementSequenceModal: undefined,
  elementSequenceUpdated: false,
  parentElsSequenceChange: [],
  uploadState: undefined,
  selectedFile: null,
  invalidBlocks: {
    editors: {},
    currentEditorId: null,
    currentIndex: 0,
  },
  isDownloading: false,
  creatingParentElements: false,
  lastInteractionBeforeSave: false,
  comments: [],
  commentsLoading: false,
  activeCommentBlockId: null,
  rightSidebarOpen: false,
  tabValue: 0,
};

const slice = createSlice({
  name: "module",
  initialState,
  reducers: {
    getModule(state, action: PayloadAction<IGetModulePayload>) {
      state.isModuleLoading = true;
    },

    setLockErrRes(state, action: PayloadAction<ParsedResponse>) {
      state.lockErrorWarningModal = true;
      state.lockError = action.payload;
    },

    setFileArraySuccessObject(
      state,
      action: PayloadAction<{
        _res: {
          status: "fulfilled" | "rejected";
          v: {
            code: "string";
            message: "string";
            url: "string";
          };
        }[];
        elementIds: Array<string>;
      }>
    ) {
      return produce(state, (draftState) => {
        action.payload.elementIds?.map((elId, idx) => {
          if (elId == draftState.result?.id) {
            if (draftState.result?.["module_image_url"])
              draftState.result["module_image_url"] =
                action.payload._res[idx].v.url;
            if (draftState.result) {
              draftState.result["module_image_url"] =
                action.payload._res[idx].v.url;
            }
          } else {
            const topicId = Object.keys(draftState.result?.topics || {}).find(
              (topic) => topic == elId
            );
            if (topicId) {
              if (draftState.result?.topics?.[topicId])
                draftState.result.topics[topicId]["img_url"] =
                  action.payload._res[idx].v.url;
              if (draftState.result?.topics?.[topicId])
                draftState.result.topics[topicId]["img_url"] =
                  action.payload._res[idx].v.url;
            }
          }
        });
      });
    },

    setFileArrayPartialFailedObject(
      state,
      action: PayloadAction<{
        _res: {
          status: "rejected";
          v: {
            code: "string";
            message: "string";
            url: "string";
          };
        }[];
        elementIds: Array<string>;
      }>
    ) {
      return produce(state, (draftState) => {
        action.payload.elementIds?.map((elId, idx) => {
          if (!draftState.imageGenerationError) {
            draftState.imageGenerationError = {
              sections_without_images: {
                module: [],
                topics: [],
              },
              isAll: false,
            };
          }
          // Update sections_without_images based on the first character of elId
          if (elId[0] === "M") {
            draftState.imageGenerationError.sections_without_images.module.push(
              {
                element_name: draftState.result?.title || "",
                element_id: elId,
              }
            );
          } else {
            draftState.imageGenerationError.sections_without_images.topics.push(
              {
                element_name: draftState.result?.topics?.[elId].title || "",
                element_id: elId,
              }
            );
          }
          draftState.imageGenerateWarningModal = true;
        });
      });
    },

    setAllFilesFailed(state, action: PayloadAction<boolean>) {
      return produce(state, (draftState) => {
        if (!draftState.imageGenerationError) {
          draftState.imageGenerationError = {
            sections_without_images: {
              module: [],
              topics: [],
            },
            isAll: action.payload,
          };
        } else {
          draftState.imageGenerationError.isAll = action.payload;
        }
        draftState.imageGenerateWarningModal = true;
      });
    },

    lockModule(state, action: PayloadAction<boolean>) {
      state.lockError = undefined;
      state.isModuleLoading = true;
    },

    setLanguageValue(state, action: PayloadAction<string>) {},

    setTargetGlossary(
      state,
      action: PayloadAction<
        { id: string; en_phrase: string; target_phrase: string }[]
      >
    ) {
      state.targetModuleGlossary = action.payload;
    },

    setSelectedGlossaryIds(state, action: PayloadAction<string[]>) {
      state.selectedGlossaryIds = action.payload;
    },

    setClearGlossary(state) {
      state.targetModuleGlossary = [];
      state.selectedGlossaryIds = [];
    },

    translateLanguage(
      state,
      action: PayloadAction<{
        lang: string;
        useGlossary: boolean;
        deepl_formality: boolean;
      }>
    ) {
      state.lockError = undefined;
      state.isContentLoading = true;
    },

    setInvalidBlocks: (
      state,
      action: PayloadAction<{ editorId: string; invalidBlocks: InvalidBlock[] }>
    ) => {
      return produce(state, (draftState) => {
        draftState.invalidBlocks.editors[action.payload.editorId] = {
          blocks: action.payload.invalidBlocks,
        };
        if (draftState.invalidBlocks.currentEditorId === null) {
          draftState.invalidBlocks.currentEditorId = action.payload.editorId;
          draftState.invalidBlocks.currentIndex = 0;
        }
      });
    },

    setCurrentInvalidBlock: (
      state,
      action: PayloadAction<{ editorId: string; index: number }>
    ) => {
      return produce(state, (draftState) => {
        draftState.invalidBlocks.currentEditorId = action.payload.editorId;
        draftState.invalidBlocks.currentIndex = action.payload.index;
      });
    },

    clearInvalidBlocksById: (state, action: PayloadAction<string>) => {
      return produce(state, (draftState) => {
        const editorIdToRemove = action.payload;
        delete draftState.invalidBlocks.editors[editorIdToRemove];

        // If we're removing the current editor's invalid blocks
        if (draftState.invalidBlocks.currentEditorId === editorIdToRemove) {
          const nextInvalidBlock = findNextInvalidBlock(
            draftState.invalidBlocks,
            editorIdToRemove
          );
          if (nextInvalidBlock) {
            draftState.invalidBlocks.currentEditorId =
              nextInvalidBlock.editorId;
            draftState.invalidBlocks.currentIndex = nextInvalidBlock.index;
          } else {
            draftState.invalidBlocks.currentEditorId = null;
            draftState.invalidBlocks.currentIndex = 0;
          }
        }
      });
    },

    clearInvalidBlocks: (state) => {
      return produce(state, (draftState) => {
        draftState.invalidBlocks = {
          editors: {},
          currentEditorId: null,
          currentIndex: 0,
        };
      });
    },

    setModuleResult(
      state,
      action: PayloadAction<{
        element: {
          sequence_number: number;
          title: string;
          modified_date: string;
          contents: Record<string, any>;
        };
        parentIds: Array<string>;
        addedElementId: string;
      }>
    ) {
      return produce(state, (draft) => {
        switch (action.payload.parentIds.length) {
          case 1:
            if (draft.result?.topics) {
              draft.result.topics = {
                ...draft.result?.topics,
                [action.payload.addedElementId]: {
                  ...action.payload.element,
                  lessons: {},
                  img_url: "NA",
                  ppt_url: "NA",
                },
              };
            }
            break;
          case 2:
            if (draft.result?.topics?.[action.payload.parentIds[0]].lessons) {
              draft.result.topics[action.payload.parentIds[0]].lessons = {
                ...draft.result?.topics[action.payload.parentIds[0]].lessons,
                [action.payload.addedElementId]: {
                  ...action.payload.element,
                  points: {},
                },
              };
            }
            break;
          case 3:
            if (
              draft.result?.topics?.[action.payload.parentIds[0]].lessons?.[
                action.payload.parentIds[1]
              ].points
            ) {
              draft.result.topics[action.payload.parentIds[0]].lessons[
                action.payload.parentIds[1]
              ].points = {
                ...draft.result.topics[action.payload.parentIds[0]].lessons[
                  action.payload.parentIds[1]
                ].points,
                [action.payload.addedElementId]: {
                  ...action.payload.element,
                  sub_points: {},
                },
              };
            }
            break;
          case 4:
            if (
              draft.result?.topics?.[action.payload.parentIds[0]].lessons?.[
                action.payload.parentIds[1]
              ].points?.[action.payload.parentIds[2]].sub_points
            ) {
              draft.result.topics[action.payload.parentIds[0]].lessons[
                action.payload.parentIds[1]
              ].points[action.payload.parentIds[2]].sub_points = {
                ...draft.result.topics[action.payload.parentIds[0]].lessons[
                  action.payload.parentIds[1]
                ].points[action.payload.parentIds[2]].sub_points,
                [action.payload.addedElementId]: {
                  ...action.payload.element,
                },
              };
            }
            break;
        }
      });
    },

    setModuleState(state, action: PayloadAction<IModuleRes>) {
      return produce(state, (draftState) => {
        const moduleSchema: IModuleSchema = mapApiResponseToModuleSchema(
          action.payload
        );
        draftState.result = moduleSchema;
      });
    },

    sortModuleItems(
      state,
      action: PayloadAction<{ id: string; sequence_no: number; type: string }>
    ) {
      return produce(state, (draftState) => {
        const { id, sequence_no, type } = action.payload;

        let items: Record<string, any> = {};
        let parentItems: Record<string, any> | undefined;
        // Determine the correct items and parent items based on the type
        switch (type) {
          case "topics":
            items = draftState?.result?.topics || {};
            break;
          case "lessons":
            Object.values(draftState?.result?.topics || {}).some((topic) => {
              if (topic.lessons && id in topic.lessons) {
                items = topic.lessons;
                parentItems = draftState?.result?.topics;
                return true;
              }
              return false;
            });
            break;
          case "points":
            Object.values(draftState?.result?.topics || {}).some((topic) =>
              Object.values(topic.lessons || {}).some((lesson) => {
                if (lesson.points && id in lesson.points) {
                  items = lesson.points;
                  parentItems = topic.lessons;
                  return true;
                }
                return false;
              })
            );
            break;
          case "subpoints":
            Object.values(draftState?.result?.topics || {}).some((topic) =>
              Object.values(topic.lessons || {}).some((lesson) =>
                Object.values(lesson.points || {}).some((point) => {
                  if (point.sub_points && id in point.sub_points) {
                    items = point.sub_points;
                    parentItems = lesson.points;
                    return true;
                  }
                  return false;
                })
              )
            );
            break;
          default:
            // If the type is not recognized, do nothing
            return;
        }

        if (Object.keys(items).length > 0) {
          const currentItem = items[id];
          const oldSequenceNumber = currentItem.sequence_number;

          // If the new sequence number is the same as the old one, do nothing
          if (sequence_no === oldSequenceNumber) {
            return;
          }

          // Update other items' sequence numbers
          Object.values(items).forEach((item: any) => {
            if (item.id !== id) {
              if (sequence_no > oldSequenceNumber) {
                if (
                  item.sequence_number > oldSequenceNumber &&
                  item.sequence_number <= sequence_no
                ) {
                  item.sequence_number--;
                }
              } else {
                if (
                  item.sequence_number >= sequence_no &&
                  item.sequence_number < oldSequenceNumber
                ) {
                  item.sequence_number++;
                }
              }
            }
          });

          // Update the sequence number of the current item
          currentItem.sequence_number = sequence_no;

          // Sort the items based on their new sequence numbers
          const sortedItems = Object.fromEntries(
            Object.entries(items).sort(
              (a, b) => a[1].sequence_number - b[1].sequence_number
            )
          );

          if (parentItems) {
            const parentKey = Object.keys(parentItems).find(
              (key) => parentItems?.[key] === items
            );
            if (parentKey) {
              parentItems[parentKey] = sortedItems;
            }
          } else if (draftState?.result?.topics) {
            draftState.result.topics = sortedItems;
          }
          draftState.elementSequenceUpdated = true;
        }
      });
    },

    setIsModuleLoading(state, action: PayloadAction<boolean>) {
      state.isModuleLoading = action.payload;
    },

    setIsUpdatingElementSequenceModal(
      state,
      action: PayloadAction<ISequenceUpdateElement | undefined>
    ) {
      state.isUpdatingElementSequenceModal = action.payload;
    },

    setParentElsSequenceChange(
      state,
      action: PayloadAction<
        Array<{
          id: string;
          sequence_no: number;
          type: string;
        }>
      >
    ) {
      return produce(state, (draft) => {
        const newElements = action.payload;

        if (!draft.parentElsSequenceChange) {
          draft.parentElsSequenceChange = [];
        }

        newElements.forEach((newElement) => {
          const existingIndex = draft.parentElsSequenceChange.findIndex(
            (el) => el.id === newElement.id
          );

          if (existingIndex !== -1) {
            // Update existing element
            draft.parentElsSequenceChange[existingIndex] = newElement;
          } else {
            // Add new element
            draft.parentElsSequenceChange.push(newElement);
          }
        });
        // Sort the array by sequence_no
        draft.parentElsSequenceChange.sort(
          (a, b) => a.sequence_no - b.sequence_no
        );
      });
    },

    setIsHasChangesModal(state, action: PayloadAction<string>) {
      state.isHasChangeModal = action.payload;
    },

    setIsElementSequenceUpdate(state, action: PayloadAction<boolean>) {
      state.elementSequenceUpdated = action.payload;
    },

    setIsValidationOpen(state, action: PayloadAction<boolean>) {
      state.isValidationModalOpen = action.payload;
    },

    setIsCreatingParentElement(state, action: PayloadAction<boolean>) {
      state.creatingParentElements = action.payload;
    },

    setLastInteraction(state, action: PayloadAction<boolean>) {
      state.lastInteractionBeforeSave = action.payload;
    },

    setIsGlossaryModalOpen(state, action: PayloadAction<string>) {
      state.isGlossaryModalOpen = action.payload;
    },

    setIsLockModalOpen(state, action: PayloadAction<boolean>) {
      state.lockErrorWarningModal = action.payload;
    },

    setIsContentErrorModalOpen(state, action: PayloadAction<boolean>) {
      state.isContentFailedModalOpen = action.payload;
    },

    setIsImageGenerateErrorModalOpen(state, action: PayloadAction<boolean>) {
      state.imageGenerateWarningModal = action.payload;
    },

    manageContent(state, action: PayloadAction<string | undefined>) {
      state.isContentFailedModalOpen = false;
      state.isContentLoading = true;
      if (!action.payload) state.lastInteractionBeforeSave = true;
    },

    manageFailed(
      state,
      action: PayloadAction<{
        errors: Array<{
          elementId: string;
          elementType: string;
          elementNo: number;
        }>;
        module: Record<string, any>;
      }>
    ) {
      return produce(state, (draft) => {
        const contentErrors = action.payload.errors.reduce(
          (acc: Array<string>, curr) => {
            let title;
            if (
              ["topics", "lessons", "points", "subpoints"].includes(
                curr.elementType
              )
            ) {
              switch (curr.elementType) {
                case "topics":
                  title = action.payload.module.topics[curr.elementId].title;
                  break;
                case "lessons":
                  Object.keys(action.payload.module.topics).map((topicId) => {
                    const lesson = Object.keys(
                      action.payload.module.topics[topicId].lessons
                    ).find((lessonId) => lessonId == curr.elementId);
                    if (lesson) {
                      title =
                        action.payload.module.topics[topicId].lessons[
                          curr.elementId
                        ].title;
                    }
                  });
                  break;
                case "points":
                  Object.keys(action.payload.module.topics).map((topicId) => {
                    Object.keys(
                      action.payload.module.topics[topicId].lessons
                    ).map((lessonId) => {
                      const point = Object.keys(
                        action.payload.module.topics[topicId].lessons[lessonId]
                          .points
                      ).find((pointId) => pointId == curr.elementId);
                      if (point) {
                        title =
                          action.payload.module.topics[topicId].lessons[
                            lessonId
                          ].points[curr.elementId].title;
                      }
                    });
                  });
                  break;
                case "subpoints":
                  Object.keys(action.payload.module.topics).map((topicId) => {
                    Object.keys(
                      action.payload.module.topics[topicId].lessons
                    ).map((lessonId) => {
                      Object.keys(
                        action.payload.module.topics[topicId].lessons[lessonId]
                          .points
                      ).map((pointId) => {
                        const subpoint = Object.keys(
                          action.payload.module.topics[topicId].lessons[
                            lessonId
                          ].points[pointId].sub_points
                        ).find((subpointId) => subpointId == curr.elementId);
                        if (subpoint) {
                          title =
                            action.payload.module.topics[topicId].lessons[
                              lessonId
                            ].points[pointId].sub_points[curr.elementId].title;
                        }
                      });
                    });
                  });
                  break;
                default:
                  title = action.payload.module.topics[curr.elementId].title;
              }
            } else {
              const parentIds = findElementParents(
                curr.elementId,
                action.payload.module
              );
              switch (parentIds.length) {
                case 1:
                  title = action.payload.module.topics[parentIds[0]].title;
                  break;
                case 2:
                  title =
                    action.payload.module.topics[parentIds[0]].lessons[
                      parentIds[1]
                    ].title;
                  break;
                case 3:
                  title =
                    action.payload.module.topics[parentIds[0]].lessons[
                      parentIds[1]
                    ].points[parentIds[2]].title;
                  break;
                case 4:
                  title =
                    action.payload.module.topics[parentIds[0]].lessons[
                      parentIds[1]
                    ].points[parentIds[2]].sub_points[parentIds[3]].title;
                  break;
                default:
                  title = action.payload.module.topics[parentIds[0]].title;
              }
            }
            acc.push(
              `Element #${curr.elementNo} of type ${curr.elementType} in ${title}`
            );
            return acc;
          },
          []
        );
        draft.contentError = contentErrors;
        draft.isContentFailedModalOpen = true;
      });
    },

    setIsContentLoading(state, action: PayloadAction<boolean>) {
      state.isContentLoading = action.payload;
    },

    setContent(
      state,
      action: PayloadAction<
        Record<
          string,
          {
            changed: Array<Record<string, any>>;
            unchanged: Array<Record<string, any>>;
            deleted: Array<Record<string, any>>;
            created: Array<Record<string, any>>;
          }
        >
      >
    ) {
      return produce(state, (draft) => {
        const _editorDataKey = Object.keys(action.payload)[0];
        if (!draft.content?.[_editorDataKey]) {
          draft.content[_editorDataKey] = {
            changed: [],
            created: [],
            unchanged: [],
            deleted: [],
          };
        }
        draft.content[_editorDataKey].created =
          action.payload[_editorDataKey].created;
        draft.content[_editorDataKey].changed =
          action.payload[_editorDataKey].changed;
        if (action.payload[_editorDataKey].unchanged.length) {
          action.payload[_editorDataKey].unchanged.map((block) => {
            const elIdx = draft.content[_editorDataKey].unchanged?.findIndex(
              (item) => item.id == block.id
            );
            if (elIdx == -1) {
              draft.content[_editorDataKey].unchanged.push({ ...block });
            }
          });
        }
        draft.content[_editorDataKey].deleted =
          action.payload[_editorDataKey].deleted;
      });
    },

    setContentRes(
      state,
      action: PayloadAction<{
        id: string;
        key: string;
        elementId: string;
      }>
    ) {
      return produce(state, (draft) => {
        if (action.payload.key in draft.content[action.payload.id]) {
          draft.content[action.payload.id][action.payload.key] = draft.content[
            action.payload.id
          ][action.payload.key].filter(
            (item) => item.id !== action.payload.elementId
          );
        } else {
          console.error("Index out of bounds");
        }
      });
    },

    createElement(state, action: PayloadAction<ICreateElement>) {
      state.creatingParentElements = true;
      state.lastInteractionBeforeSave = true;
      state.isModuleLoading = true;
    },

    updateElement(state, action: PayloadAction<ICreateElement>) {
      state.isModuleLoading = true;
    },

    updateElementSequence(
      state,
      action: PayloadAction<IUpdateElementSequence[]>
    ) {},

    deleteElement(state, action: PayloadAction<IDeleteElement>) {
      state.lastInteractionBeforeSave = true;
      state.isModuleLoading = true;
    },

    clearData(state) {
      state.result = undefined;
      state.contentError = undefined;
      state.content = {};
      state.language = ILanguage.en;
    },

    clearContent(state) {
      state.content = {};
    },

    setTopic(state, action: PayloadAction<IParsedTopicRes>) {
      return produce(state, (draft) => {
        if (draft.result?.topics) {
          draft.result.topics[action.payload.topic_id] = {
            sequence_number: action.payload.topic_sequence_number,
            contents: {},
            img_url: "",
            ppt_url: "",
            modified_date: "",
            lessons: {},
            title: action.payload.topic_title,
          };
        }
      });
    },

    setLesson(state, action: PayloadAction<IParsedLessonRes>) {
      return produce(state, (draft) => {
        if (draft.result?.topics) {
          draft.result.topics[action.payload.topic_id].lessons[
            action.payload.lesson_id
          ] = {
            sequence_number: action.payload.lesson_sequence_number,
            contents: {},
            modified_date: "",
            points: {},
            title: action.payload.lesson_title,
          };
        }
      });
    },

    setPoint(state, action: PayloadAction<IParsedPointRes>) {
      return produce(state, (draft) => {
        if (draft.result?.topics) {
          Object.values(draft.result.topics).map((topic) => {
            Object.keys(topic.lessons).map((lesson_id) => {
              if (lesson_id == action.payload.lesson_id) {
                topic.lessons[lesson_id].points[action.payload.point_id] = {
                  contents: {},
                  modified_date: "",
                  sequence_number: action.payload.point_sequence_number,
                  sub_points: {},
                  title: action.payload.point_title,
                };
              }
            });
          });
        }
      });
    },

    setUpdateTopicSequence(state, action: PayloadAction<any>) {
      return produce(state, (draft) => {
        switch (action.payload.selectedEls?.length) {
          case 1:
            const filter = Object.keys(action.payload.topics).reduce(
              (acc: any, topicId) => {
                if (draft.result?.topics) {
                  if (draft.result.topics[topicId]) {
                    acc[topicId] = {
                      ...draft.result.topics[topicId],
                      sequence_number:
                        action.payload.topics[topicId].topic_sequence_number,
                      modified_date:
                        action.payload.topics[topicId].modified_date,
                    };
                  }
                  return acc;
                }
              },
              {}
            );
            if (draft.result) {
              const topicIds = Object.keys(filter);
              topicIds.sort((topicIdA, topicIdB) => {
                const topicA = filter[topicIdA];
                const topicB = filter[topicIdB];
                return topicA.sequence_number - topicB.sequence_number;
              });
              const sortedUpdatedTopics = topicIds.reduce((acc, topicId) => {
                acc[topicId] = filter[topicId];
                return acc;
              }, {});
              draft.result.topics = sortedUpdatedTopics;
            }
            if (action.payload?.deleted_entity) {
              const deletedTopicId = action.payload.deleted_entity;
              if (draft.result && draft.result.topics) {
                delete draft.result.topics[deletedTopicId];
              }
            }
            break;
          case 2:
            const lessons = Object.keys(action.payload.lessons).reduce(
              (acc: any, lessonId) => {
                if (
                  draft.result?.topics?.[action.payload.selectedEls[0]]?.lessons
                ) {
                  if (
                    draft.result.topics?.[action.payload.selectedEls[0]]
                      ?.lessons?.[lessonId]
                  ) {
                    acc[lessonId] = {
                      ...draft.result.topics?.[action.payload.selectedEls[0]]
                        ?.lessons?.[lessonId],
                      sequence_number:
                        action.payload.lessons[lessonId].lesson_sequence_number,
                      modified_date:
                        action.payload.lessons[lessonId].modified_date,
                    };
                  }
                  return acc;
                }
              },
              {}
            );
            if (draft.result) {
              const lessonIds = Object.keys(lessons);
              lessonIds.sort((lessonIdA, lessonIdB) => {
                const lessonA = lessons[lessonIdA];
                const lessonB = lessons[lessonIdB];
                return lessonA.sequence_number - lessonB.sequence_number;
              });
              const sortedUpdatedLessons = lessonIds.reduce((acc, lessonId) => {
                acc[lessonId] = lessons[lessonId];
                return acc;
              }, {});
              if (
                draft.result.topics?.[action.payload.selectedEls?.[0]]?.lessons
              )
                draft.result.topics[action.payload.selectedEls[0]]["lessons"] =
                  sortedUpdatedLessons;
            }
            if (action.payload?.deleted_entity) {
              const deletedLessonId = action.payload.deleted_entity;
              if (draft.result && draft.result.topics) {
                delete draft.result.topics[action.payload.selectedEls[0]][
                  "lessons"
                ][deletedLessonId];
              }
            }
            break;
          case 3:
            const points = Object.keys(action.payload.points).reduce(
              (acc: any, pointId) => {
                if (
                  draft.result?.topics?.[action.payload.selectedEls[0]]
                    ?.lessons?.[action.payload.selectedEls?.[1]]?.points
                ) {
                  if (
                    draft.result?.topics?.[action.payload.selectedEls[0]]
                      ?.lessons?.[action.payload.selectedEls?.[1]]?.points?.[
                      pointId
                    ]
                  ) {
                    acc[pointId] = {
                      ...draft.result?.topics?.[action.payload.selectedEls[0]]
                        ?.lessons?.[action.payload.selectedEls?.[1]]?.points?.[
                        pointId
                      ],
                      sequence_number:
                        action.payload.points[pointId].point_sequence_number,
                      modified_date:
                        action.payload.points[pointId].modified_date,
                    };
                  }
                  return acc;
                }
              },
              {}
            );
            if (draft.result) {
              const pointIds = Object.keys(points);
              pointIds.sort((pointIdA, pointIdB) => {
                const pointA = points[pointIdA];
                const pointB = points[pointIdB];
                return pointA.sequence_number - pointB.sequence_number;
              });
              const sortedUpdatedpoints = pointIds.reduce((acc, pointId) => {
                acc[pointId] = points[pointId];
                return acc;
              }, {});
              if (
                draft.result?.topics?.[action.payload.selectedEls[0]]
                  ?.lessons?.[action.payload.selectedEls?.[1]]?.points
              )
                draft.result.topics[action.payload.selectedEls[0]]["lessons"][
                  action.payload.selectedEls?.[1]
                ]["points"] = sortedUpdatedpoints;
            }
            if (action.payload?.deleted_entity) {
              const deletedPointId = action.payload.deleted_entity;
              if (draft.result && draft.result.topics) {
                delete draft.result.topics[action.payload.selectedEls[0]][
                  "lessons"
                ][action.payload.selectedEls?.[1]]["points"][deletedPointId];
              }
            }
            break;
          case 4:
            const sub_points = Object.keys(action.payload.sub_points).reduce(
              (acc: any, subPointId) => {
                if (
                  draft.result?.topics?.[action.payload.selectedEls[0]]
                    ?.lessons?.[action.payload.selectedEls?.[1]]?.points?.[
                    action.payload.selectedEls[2]
                  ]?.sub_points
                ) {
                  if (
                    draft.result?.topics?.[action.payload.selectedEls[0]]
                      ?.lessons?.[action.payload.selectedEls?.[1]]?.points?.[
                      action.payload.selectedEls[2]
                    ]?.sub_points?.[subPointId]
                  ) {
                    acc[subPointId] = {
                      ...draft.result?.topics?.[action.payload.selectedEls[0]]
                        ?.lessons?.[action.payload.selectedEls?.[1]]?.points?.[
                        action.payload.selectedEls[2]
                      ]?.sub_points?.[subPointId],
                      sequence_number:
                        action.payload.sub_points[subPointId]
                          .sup_point_sequence_number,
                      modified_date:
                        action.payload.sub_points[subPointId].modified_date,
                    };
                  }
                  return acc;
                }
              },
              {}
            );
            if (draft.result) {
              const subpointIds = Object.keys(sub_points);
              subpointIds.sort((subpointIdA, subpointIdB) => {
                const subpointA = sub_points[subpointIdA];
                const subpointB = sub_points[subpointIdB];
                return subpointA.sequence_number - subpointB.sequence_number;
              });
              const sortedUpdatedsub_points = subpointIds.reduce(
                (acc, subPointId) => {
                  acc[subPointId] = sub_points[subPointId];
                  return acc;
                },
                {}
              );
              if (
                draft.result?.topics?.[action.payload.selectedEls[0]]
                  ?.lessons?.[action.payload.selectedEls?.[1]]?.points?.[
                  action.payload.selectedEls[2]
                ]?.sub_points
              )
                draft.result.topics[action.payload.selectedEls[0]]["lessons"][
                  action.payload.selectedEls[1]
                ]["points"][action.payload.selectedEls[2]]["sub_points"] =
                  sortedUpdatedsub_points;
            }
            if (action.payload?.deleted_entity) {
              const deletedSubPointId = action.payload.deleted_entity;
              if (draft.result && draft.result.topics) {
                delete draft.result.topics[action.payload.selectedEls[0]][
                  "lessons"
                ][action.payload.selectedEls[1]]["points"][
                  action.payload.selectedEls[2]
                ]["sub_points"][deletedSubPointId];
              }
            }
            break;
          default:
            console.log("------------>Non valid response");
        }
        draft.isElementDeleting = false;
        draft.isModuleLoading = false;
      });
    },

    downloadFile(
      state,
      action: PayloadAction<{
        itemId: string;
        itemType: "isImage" | "isPdf" | "isPpt";
        isCourseDetails?: boolean;
      }>
    ) {
      state.isDownloadingItem = true;
    },

    downloadPPT(state, action: PayloadAction<string>) {
      state.isDownloading = true;
    },

    setIsDownloadPPT(state, action: PayloadAction<boolean>) {
      state.isDownloading = action.payload;
    },

    bulkDownloadFile(state) {
      state.imageGenerationError = undefined;
      state.isDownloadingItem = true;
      state.lockErrorWarningModal = false;
      state.imageGenerateWarningModal = false;
    },

    setIsDownloadingFile(state, action: PayloadAction<boolean>) {
      state.isDownloadingItem = action.payload;
    },

    setIsElementAdding(state, action: PayloadAction<boolean>) {
      state.isElementAdding = action.payload;
    },

    setIsElementDeleting(state, action: PayloadAction<boolean>) {
      state.isElementDeleting = action.payload;
    },

    setIsHierarchyError(state, action: PayloadAction<boolean>) {
      state.hierarchyError = action.payload;
    },

    setIsEditorEmpty(state, action: PayloadAction<boolean>) {
      state.isEditorEmpty = action.payload;
    },

    setSelectedFile: (state, action) => {
      state.selectedFile = action.payload;
    },

    uploadFileStart(
      state,
      action: PayloadAction<{
        fileName: string;
        fileType: string;
        fileSize: number;
        id: string;
        isImage: boolean;
      }>
    ) {},

    updateFileUploadState(state, action: PayloadAction<IFileUploadState>) {
      state.uploadState = action.payload;
    },

    finishUploadState(
      state,
      action: PayloadAction<{
        id?: string;
        isImage: boolean;
        uploadedPath: string;
      }>
    ) {
      return produce(state, (draft) => {
        if (draft?.result) {
          if (action.payload?.id == undefined) {
            if (action.payload.isImage)
              draft.result["image_url "] = action.payload.uploadedPath;
            else draft.result["ppt_url"] = action.payload.uploadedPath;
          } else {
            if (draft.result?.topics) {
              if (action.payload.isImage)
                draft.result.topics[action.payload.id]["img_url"] =
                  action.payload.uploadedPath;
              else
                draft.result.topics[action.payload.id]["ppt_url"] =
                  action.payload.uploadedPath;
            }
          }
        }
      });
    },

    resetUploadState: (state) => {
      state.uploadState = {
        progress: 0,
        processing: false,
        haveError: false,
      };
      state.selectedFile = null;
    },

    setSubPoint(state, action: PayloadAction<IParsedSubPointRes>) {
      return produce(state, (draft) => {
        if (draft.result?.topics) {
          Object.values(draft.result.topics).map((topic) => {
            Object.values(topic.lessons).map((lesson) => {
              Object.keys(lesson.points).map((point_id) => {
                if (point_id == action.payload.point_id) {
                  lesson.points[point_id].sub_points[
                    action.payload.sub_point_id
                  ] = {
                    contents: {},
                    modified_date: "",
                    sequence_number: action.payload.sub_point_sequence_number,
                    title: action.payload.subpoint_title,
                  };
                }
              });
            });
          });
        }
      });
    },

    getComments: (state, action: PayloadAction<string | null>) => {
      state.commentsLoading = true;
    },
    setComments: (state, action: PayloadAction<IModuleComment[]>) => {
      state.comments = action.payload;
    },
    createComment: (
      state,
      action: PayloadAction<{ blockId: string; content: string }>
    ) => {
      state.commentsLoading = true;
    },
    createCommentSuccess: (state, action: PayloadAction<IModuleComment>) => {
      state.comments.push(action.payload);
      state.commentsLoading = false;
    },
    updateComment: (
      state,
      action: PayloadAction<{ id: string; content: string }>
    ) => {
      state.commentsLoading = true;
    },
    updateCommentSuccess: (
      state,
      action: PayloadAction<{ id: string; content: string }>
    ) => {
      const index = state.comments.findIndex(
        (comment) => comment.id === action.payload.id
      );
      if (index !== -1) {
        state.comments[index] = {
          ...state.comments[index],
          content: action.payload.content,
        };
      }
    },

    deleteCommentSuccess: (state, action: PayloadAction<string>) => {
      state.comments = state.comments.filter(
        (comment) => comment.id !== action.payload
      );
    },
    setIsCommentLoading: (state, action: PayloadAction<boolean>) => {
      state.commentsLoading = action.payload;
    },
    setRightSidebarOpen: (state, action: PayloadAction<boolean>) => {
      state.rightSidebarOpen = action.payload;
    },
    setTabValue: (state, action: PayloadAction<number>) => {
      state.tabValue = action.payload;
    },
    setActiveCommentBlockId: (state, action: PayloadAction<string | null>) => {
      state.activeCommentBlockId = action.payload;
    },
    deleteComment: (state, action: PayloadAction<string>) => {
      state.commentsLoading = true;
    },
  },
});

export const { actions: workplaceActions } = slice;
export const useModuleSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: moduleSaga });
  return { actions: slice.actions };
};
export const moduleReducers = slice.reducer;

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useCartSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
