import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "types";
import { initialState } from ".";
import { ICourseDetailState } from "./type";

const selectSlice = (state: RootState) => state?.courseDetail || initialState;

export const selectCourseDetail: (state: RootState) => ICourseDetailState =
  createSelector([selectSlice], (state) => state);

export const selectCourse = createSelector(
  [selectCourseDetail],
  (state) => state?.result
);

export const selectModule = createSelector(
  [selectCourseDetail],
  (state) => state?.module
);

export const selectGettingCousrse = createSelector(
  [selectCourseDetail],
  (state) => state.isCourseLoading
);
