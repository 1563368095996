import * as Yup from "yup";

export const organizationValidationSchema = Yup.object().shape({
  name: Yup.string().required("Organization name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  is_active: Yup.boolean(),
  aws_access_key: Yup.string().required("AWS Access Key is required"),
  aws_secret_key: Yup.string().required("AWS Secret Key is required"),
  ses_region: Yup.string().required("SES Region is required"),
  ses_username: Yup.string().required("SES Username is required"),
  ses_password: Yup.string().required("SES Password is required"),
  openai_api_key: Yup.string().required("OpenAI API Key is required"),
  coudinary_cloud_name: Yup.string().required(
    "Cloudinary Cloud Name is required"
  ),
  cloudinary_api_key: Yup.string().required("Cloudinary API Key is required"),
  cloudinary_api_secret: Yup.string().required(
    "Cloudinary API Secret is required"
  ),
  deepl_api_key: Yup.string().required("DeepL API Key is required"),
});
