import React from "react";
import lottie403 from "assets/lotties/403.json";
import Lottie from "react-lottie";
import { Container, Button, Box } from "app/components/Core";
import { useNavigate } from "react-router-dom";
import { theme } from "styles/theme/themes";
import { Typography } from "@mui/material";
export const UnauthorizedPage = () => {
  const navigate = useNavigate();
  const lottieDefaultOptions = {
    loop: true,
    autoplay: true,
    animationData: lottie403,
    renderSettings: {
      preserveAspectRation: "xMidYMid slice",
    },
  };
  return (
    <Container height="100vh" flexDirection="column">
      <Box width={["100%", "80%", "50%", "40%"]}>
        <Lottie options={lottieDefaultOptions} width="100%" />
      </Box>
      <Typography gutterBottom>You need Admin to grant you access</Typography>
      <Button
        bg={theme.colors.info.main as string}
        variant="paddedOutline"
        width={["90%", "30%", 42]}
        onClick={() => {
          localStorage.clear();
          navigate("/login");
        }}
      >
        Login
      </Button>
    </Container>
  );
};
