import React from "react";
import { Input, TextArea } from "../Core";
import { IFormControlProps } from "./types";
import { Field } from "formik";

function FormControl({
  control,
  name,
  placeholder,
  valid,
  type,
  variant,
  disabled = false,
}: IFormControlProps) {
  switch (control) {
    case "input":
      return (
        <Input
          name={name}
          placeholder={placeholder}
          type={type}
          valid={valid}
          variant={variant}
          disabled={disabled}
          autoFocus={true}
          width={(name == "open_ai_key" || name == "deepl_key") && "100%"}
        />
      );
    case "textarea":
      return (
        <Field component={TextArea} name={name} placeholder={placeholder} />
      );
    default:
      return null;
  }
}
export default FormControl;
