import { CustomModal, Flex } from "../Core";
import { DataGrid } from "@mui/x-data-grid";
import { theme } from "styles/theme/themes";
import Box from "@mui/material/Box";
import { Container, Button as StyledButton } from "../Core";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

export const GlossaryModal = ({
  open,
  handleClose,
  isLoading,
  handleUseGlossaryTranslate,
  handleNoGlossaryTranslate,
  targetModuleGlossary,
  columns,
  handleSelectionChange,
  selectedGlossaryIds,
  formality,
  handleFormalityChange,
}) => {
  return (
    <CustomModal open={open} handleClose={handleClose}>
      <Flex width={["100%", null, 600]} p={[2, 3]} flexDirection={"column"}>
        <Container
          justifyContent="space-between"
          height="auto"
          alignItems="center"
        >
          <Typography fontWeight={theme.fontWeights[5]} fontSize={[20, 35]}>
            Select Glossary
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={formality}
                onChange={handleFormalityChange}
                size="small"
              />
            }
            label="deepl_formality"
          />
        </Container>
        <DataGrid
          rows={targetModuleGlossary}
          columns={columns}
          checkboxSelection
          autoHeight
          disableColumnMenu
          hideFooter
          onRowSelectionModelChange={handleSelectionChange}
          rowSelectionModel={selectedGlossaryIds}
          loading={isLoading}
        />
        <Box sx={{ display: "flex", py: 2, justifyContent: "end" }}>
          <Button
            disabled={isLoading}
            onClick={() => {
              handleUseGlossaryTranslate();
            }}
            variant="text"
          >
            Yes, use glossary
          </Button>
          <Button
            onClick={handleNoGlossaryTranslate}
            variant="text"
            disabled={isLoading}
          >
            No, don't use glossary
          </Button>
          <Button onClick={handleClose} variant="outlined">
            Close
          </Button>
        </Box>
      </Flex>
    </CustomModal>
  );
};
