import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { IContentProps } from "./type";
import lottieEmpty from "assets/lotties/empty.json";
import Lottie from "react-lottie";

import { useNavigate } from "react-router-dom";

interface IModuleItemProps {
  title: string;
  module_id: string;
}

const ModuleItem = ({ title, module_id }: IModuleItemProps) => {
  const navigate = useNavigate();

  return (
    <ListItem
      sx={{
        margin: "8px 0",
        "&.MuiListItem-root": {
          backgroundColor: "#f3f6f966",
          border: "1px solid #e5eaf280",
          borderRadius: "12px",
          "&:hover": {
            backgroundColor: "#f0f7ff",
            borderColor: "#66b2ff",
          },
        },
      }}
    >
      <Box
        sx={{
          flex: "1 1 auto",
          minWidth: 0,
          marginTop: "4px",
          marginBottom: "4px",
          height: "42px",
        }}
      >
        <Typography
          sx={{
            display: "inline",
            fontWeight: 400,
            fontSize: "1rem",
            lineHeight: "1.5",
            letterSpacing: "0.00938em",
            color: "#1976d2",
            cursor: "pointer",
          }}
          onClick={() => navigate(`/module/${module_id}?isFromCourseDetail=F`)}
        >
          {title}
        </Typography>
      </Box>
    </ListItem>
  );
};

export default function ModuleContent(props: IContentProps) {
  const lottieDefaultOptions = {
    loop: true,
    autoplay: true,
    animationData: lottieEmpty,
    renderSettings: {
      preserveAspectRation: "xMidYMid slice",
    },
  };
  return (
    <Grid container sx={{ width: 500 }}>
      <Grid
        item
        xs={12}
        sx={{
          p: 2,
          borderBottom: "1px solid #dae2ed",
          borderTop: "ecebec",
          borderRight: "ecebec",
          borderLeft: "ecebec",
        }}
      >
        <Typography variant="h6">Here are list of your Modules</Typography>
      </Grid>
      <Grid sx={{ minHeight: "384px", padding: "4px 8px" }} item xs={12}>
        <Grid item container xs={12}>
          <Box sx={{ width: "100%" }}>
            <List>
              {props?.modules?.length ? (
                props?.modules?.map((module) => (
                  <ModuleItem
                    key={module.id}
                    title={module.title}
                    module_id={module.id}
                  />
                ))
              ) : (
                <>
                  <Lottie options={lottieDefaultOptions} width="100%" />
                  <Typography gutterBottom align="center">
                    Empty List
                  </Typography>
                </>
              )}
            </List>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
