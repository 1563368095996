import { CustomModal, Flex } from "../Core";
import { Dangerous } from "styled-icons/material";
import { theme } from "styles/theme/themes";
import { Container, Button as StyledButton } from "../Core";
import Typography from "@mui/material/Typography";

export const UnlockWarningModal = ({
  open,
  handleClose,
  isLoading,
  handleUnlock,
}) => {
  return (
    <CustomModal open={open} handleClose={handleClose}>
      <Flex width={["100%", null, 600]} p={[2, 3]} flexDirection={"column"}>
        <Container justifyContent="flex-start" height="auto">
          <Dangerous size={35} color={theme.colors.grey[600]} />
          <Typography
            fontWeight={theme.fontWeights[5]}
            fontSize={[20, 35]}
            color={theme.colors.gold[0]}
          >
            Warning
          </Typography>
        </Container>
        <Typography my={[2, null, 4]}>
          Are you sure you want to Unlock Module? Unlocking would mean reverting
          all changes
        </Typography>

        <Flex justifyContent="flex-end" width="100%" alignItems="center">
          <StyledButton
            onClick={handleClose}
            mr={2}
            color={theme.colors.grey[600]}
            variant="status"
            fontSize={[2, 3, 4]}
          >
            Cancel
          </StyledButton>
          <StyledButton
            loading={isLoading}
            onClick={() => {
              handleUnlock();
            }}
            variant="status"
            bg={theme.colors.gold[0]}
            px={[2, 3]}
            fontSize={[2, 3, 4]}
          >
            Unlock
          </StyledButton>
        </Flex>
      </Flex>
    </CustomModal>
  );
};
