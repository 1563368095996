import { IModalProps } from "../types";
import Style from "./styles";

export function CustomModal({
  handleClose,
  open,
  children,
  isTransparent,
  leftWidth,
  borderRadius,
}: IModalProps) {
  return (
    <Style.StyledDialog
      sx={{
        zIndex: 5100,
        ".MuiPaper-root": {
          maxWidth: "100% !important",
          overflowY: "unset !important",
        },
      }}
      onClose={handleClose}
      open={open}
      isTransparent={isTransparent}
      borderRadius={borderRadius}
      leftWidth={leftWidth}
    >
      {children}
    </Style.StyledDialog>
  );
}
