import styled from "styled-components";
import {
  compose,
  boxShadow,
  alignSelf,
  justifySelf,
  variant,
} from "styled-system";
import { Box } from "../Box/Box";
import { CardProps } from "../types";
import { theme } from "styles/theme/themes";

export const Card = styled(Box)<CardProps>`
  background-color: ${(props) => props.bg || theme.colors.white[0]};
  min-width: ${theme.sizes[10]};
  transition: all 0.2s ease-out;
  &:hover {
    box-shadow: ${(props) => props.boxShadow || theme.shadows[10]};
  }

  ${compose(
    boxShadow,
    alignSelf,
    justifySelf,
    variant({
      variants: {
        default: {
          boxShadow: theme.shadows[9],
          // borderRadius: theme.radii[1],
        },
        medium: {
          boxShadow: theme.shadows[10],
        },
        dense: {
          boxShadow: theme.shadows[11],
        },
        rightBended: {
          boxShadow: theme.shadows[9],
          borderBottomRightRadius: [11],
        },
        radiant: {
          boxShadow: "0px 10px 20px rgba(148, 70, 247, 0.2)",
        },
      },
    })
  )}
`;

Card.defaultProps = {
  variant: "default",
};
