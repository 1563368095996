import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "types";
import { initialState } from ".";
import { IJobStateInterface } from "./types";

const selectSlice = (state: RootState) => state?.jobs || initialState;

export const selectJobs: (state: RootState) => IJobStateInterface =
  createSelector([selectSlice], (state) => state);

export const selectAllJobs = createSelector(
  [selectJobs],
  (state) => state.result
);

export const selectGettingJobs = createSelector(
  [selectJobs],
  (state) => state.isjobsLoading
);
