/* eslint-disable array-callback-return */
import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { glossariesSaga } from "./saga";
import { IGlossary, IGlossaryForm, IGlossaryState } from "./type";

export const initialState: IGlossaryState = {
  result: [],
  isGlossaryLoading: false,
};

const slice = createSlice({
  name: "glossaries",
  initialState,
  reducers: {
    getGlossaries(state, action: PayloadAction<string | undefined>) {
      state.isGlossaryLoading = true;
    },
    setGlossariesState(state, action: PayloadAction<IGlossary[]>) {
      state.result = action.payload;
    },
    setIsGlossariesLoading(state, action: PayloadAction<boolean>) {
      state.isGlossaryLoading = action.payload;
    },
    createGlossary(state, action: PayloadAction<IGlossaryForm>) {
      state.isGlossaryLoading = true;
    },
    deleteGlossary(state, action: PayloadAction<string>) {
      state.isGlossaryLoading = true;
    },
  },
});

export const { actions: glossariesActions } = slice;
export const useGlossarySlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: glossariesSaga });
  return { actions: slice.actions };
};
export const glossariesReducers = slice.reducer;
