import { call, put, takeLatest, select } from "redux-saga/effects";
import makeCall from "app/API/makeCalls";
import routes from "app/API/api.routes";
import { glossariesActions as actions } from ".";
import { defaultLayoutActions } from "app/pages/DefaultLayout/slice";
import { PayloadAction } from "@reduxjs/toolkit";
import { IGlossaryForm } from "./type";
import {
  DEFAULT_DESCRIPTION,
  DEFAULT_TITLE,
  VARIANTS,
} from "app/pages/DefaultLayout/constants";
import { TypeOptions } from "react-toastify";
import { v4 as uuidV4 } from "uuid";
import { handleAPIError } from "app/pages/DefaultLayout/slice/saga";
import { workplaceActions } from "app/pages/Workplace/slice";
import { extractPhrases } from "helper/helperFunctions";
import { selectGlobalLanguage } from "app/pages/DefaultLayout/slice/selectors";

function* getGlossariesSaga(action: PayloadAction<string | undefined>) {
  try {
    const response = yield call(makeCall, {
      method: "GET",
      isSecureRoute: true,
      route: routes.glossaries.list,
    });
    if (response.code == "0") {
      if (response?.glossaries) {
        if (action?.payload) {
          const parsedGlossary = yield call(
            extractPhrases,
            response?.glossaries,
            action.payload
          );
          yield put(workplaceActions.setTargetGlossary(parsedGlossary));
          yield put(
            workplaceActions.setSelectedGlossaryIds(
              parsedGlossary.map((item) => item.id)
            )
          );
        }
        yield put(actions.setGlossariesState(response.glossaries));
      }
      yield put(actions.setIsGlossariesLoading(false));
    }
  } catch (error: any) {
    yield call(handleAPIError, error);
    yield put(actions.setIsGlossariesLoading(false));
    console.log("Error Getting Glossaries", error);
  }
}

function* createGlossarySaga(action: PayloadAction<IGlossaryForm>) {
  try {
    const language = yield select(selectGlobalLanguage);
    const response: any = yield call(makeCall, {
      method: "POST",
      isSecureRoute: true,
      route: routes.glossaries.create,
      body: { glossaries: [action.payload], language },
    });
    if (response.code == "0") {
      const glossariesRsponse = yield call(makeCall, {
        method: "GET",
        isSecureRoute: true,
        route: routes.glossaries.list,
      });
      if (glossariesRsponse?.glossaries) {
        yield put(actions.setGlossariesState(response.glossaries));

        yield put(
          defaultLayoutActions.setAppMessage({
            currentMessage: DEFAULT_DESCRIPTION.Success,
            currentTitle: DEFAULT_TITLE.Success,
            currentVariant: VARIANTS.SUCCESS as TypeOptions,
            count: uuidV4(),
          })
        );
      }
    } else {
      if (response?.message) {
        yield put(
          defaultLayoutActions.setAppMessage({
            currentMessage: response?.message,
            currentTitle: DEFAULT_TITLE.Fail,
            currentVariant: VARIANTS.Error as TypeOptions,
            count: uuidV4(),
          })
        );
      } else {
        yield put(
          defaultLayoutActions.setAppMessage({
            currentMessage: "No valid glossaries found.",
            currentTitle: DEFAULT_TITLE.Fail,
            currentVariant: VARIANTS.Error as TypeOptions,
            count: uuidV4(),
          })
        );
      }
    }
    yield put(actions.setIsGlossariesLoading(false));
  } catch (error: any) {
    yield put(
      defaultLayoutActions.setAppMessage({
        currentMessage: error?.message || DEFAULT_DESCRIPTION.Error,
        currentTitle: DEFAULT_TITLE.Fail,
        currentVariant: VARIANTS.Error as TypeOptions,
        count: uuidV4(),
      })
    );
    yield put(actions.setIsGlossariesLoading(false));
    yield call(handleAPIError, error);
  }
}

function* deleteGlossariesSaga(action: PayloadAction<string>) {
  try {
    const response = yield call(makeCall, {
      method: "POST",
      isSecureRoute: true,
      route: routes.glossaries.delete,
      body: { glossaries: [action.payload] },
    });
    if (response.code == "0") {
      if (response?.glossaries) {
        yield put(actions.setGlossariesState(response.glossaries));
      }
      yield put(actions.setIsGlossariesLoading(false));
    }
  } catch (error: any) {
    yield call(handleAPIError, error);
    yield put(actions.setIsGlossariesLoading(false));
    console.log("Error Getting Glossaries", error);
  }
}

export function* glossariesSaga() {
  yield takeLatest(actions.getGlossaries.type, getGlossariesSaga);
  yield takeLatest(actions.createGlossary.type, createGlossarySaga);
  yield takeLatest(actions.deleteGlossary.type, deleteGlossariesSaga);
}
