import { call, put, takeLatest } from "redux-saga/effects";
import makeCall from "app/API/makeCalls";
import routes from "app/API/api.routes";
import { jobsActions as actions } from ".";
import { handleAPIError } from "app/pages/DefaultLayout/slice/saga";

function* getJobsSaga() {
  try {
    const response: any = yield call(makeCall, {
      method: "GET",
      isSecureRoute: true,
      route: routes.jobs.list,
    });
    if (response.code == "0") {
      if (response?.Jobs) {
        yield put(actions.setJobsState(response?.Jobs));
      }
      yield put(actions.setIsJobsLoading(false));
    }
  } catch (error: any) {
    yield put(actions.setIsJobsLoading(false));
    yield call(handleAPIError, error);
    console.log("Error Getting Jobs", error);
  }
}

export function* jobsSaga() {
  yield takeLatest(actions.getJobs.type, getJobsSaga);
}
