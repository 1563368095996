import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Box,
  Typography,
  Tooltip,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Card,
  CardContent,
  useTheme,
  IconButton,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  FormHelperText,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import CalendarViewDayIcon from "@mui/icons-material/CalendarViewDay";
import TableChartIcon from "@mui/icons-material/TableChart";
import CloseIcon from "@mui/icons-material/Close";
import { Input } from "../../components/Form/FormField/_Input";
import { truncate, turnToHumanReadable } from "helper/helperFunctions";
import { v4 as uuidv4 } from "uuid";
import { theme as styledTheme } from "styles/theme/themes";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { CreateModuleModal } from "../Course/CourseDetails";
import { ICourseFormValue } from "../Courses/types";
import { selectAllModules } from "./slice/selector";
import { useModulesSlice } from "./slice";
import { selectGlobalLanguage } from "../DefaultLayout/slice/selectors";
import { IModuleWithCourse } from "./slice/type";
import { CustomModal } from "app/components/Core";

export default function Modules() {
  const dispatch = useDispatch();
  const { actions } = useModulesSlice();
  const [alignment, setAlignment] = useState("list");
  const navigate = useNavigate();
  const allModules = useSelector(selectAllModules) as
    | IModuleWithCourse[]
    | null;
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredModules, setFilteredModules] = useState<IModuleWithCourse[]>(
    []
  );
  const theme = useTheme();
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);
  const [editInitialValues, setEditInitialValues] = useState<{
    title: string;
    description: string;
  }>({
    title: "",
    description: "",
  });
  const [editingModuleId, setEditingModuleId] = useState("");
  const [courseFilter, setCourseFilter] = useState<string>("");
  const [tempCourseFilter, setTempCourseFilter] = useState<string>("");
  const globalLanguage = useSelector(selectGlobalLanguage);

  useEffect(() => {
    dispatch(actions.getModules(globalLanguage!));
  }, [globalLanguage]);

  useEffect(() => {
    if (allModules) {
      applyFilters(searchTerm, courseFilter);
    }
  }, [allModules, searchTerm, courseFilter]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputText = event.target.value;
    setSearchTerm(inputText);
  };

  const applyFilters = useCallback(
    (search: string, course: string) => {
      if (allModules) {
        const filtered = allModules.filter((module) => {
          const matchesSearch = module.title
            .toLowerCase()
            .includes(search.toLowerCase());
          const matchesCourse = course === "" || module.course_id === course;
          return matchesSearch && matchesCourse;
        });
        setFilteredModules(filtered);
      }
    },
    [allModules]
  );

  const handleEdit = (moduleId: string, title: string, description: string) => {
    setEditingModuleId(moduleId);
    setEditInitialValues({ title, description });
    setIsEditModalOpen(true);
  };

  const handleDelete = (moduleId: string, courseId: string) => {
    dispatch(
      actions.deleteModule({
        module_id: moduleId,
        course_id: courseId,
        language: globalLanguage!,
      })
    );
  };

  const handleEditSubmit = (values: ICourseFormValue) => {
    dispatch(
      actions.updateModuleTitle({
        course_id: editingModuleId,
        title: values.title,
        description: values?.description!,
        language: globalLanguage!,
      })
    );
    setIsEditModalOpen(false);
  };

  const handleCloseCreateModuleModal = () => {
    setIsEditModalOpen(false);
  };

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);
  };

  const handleCourseFilterChange = (event: SelectChangeEvent<string>) => {
    setTempCourseFilter(event.target.value);
  };

  const handleApplyFilter = () => {
    setCourseFilter(tempCourseFilter);
    setIsFilterModalOpen(false);
  };

  const handleClearFilter = () => {
    setSearchTerm("");
    setCourseFilter("");
    setTempCourseFilter("");
    if (allModules) {
      setFilteredModules(allModules);
    }
  };

  const uniqueCourses = Array.from(
    new Set(allModules?.map((module) => module.course_id))
  );

  const renderTableView = () => (
    <TableContainer
      component={Paper}
      sx={{ maxHeight: "calc(100vh - 200px)", overflow: "auto" }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="modules table">
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: theme.palette.grey[100],
              borderBottom: `2px solid ${theme.palette.divider}`,
            }}
          >
            <TableCell
              sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
            >
              Module Title
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
            >
              Course
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
            >
              Description
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
            >
              Author
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
            >
              Last Modified
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
            >
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredModules.map((module, index) => (
            <TableRow
              key={uuidv4()}
              sx={{
                "&:nth-of-type(even)": {
                  backgroundColor: theme.palette.action.hover,
                },
                borderBottom: `1px solid ${theme.palette.divider}`,
              }}
            >
              <TableCell component="th" scope="row">
                <Typography
                  sx={{
                    color: theme.palette.primary.main,
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    navigate(`/module/${module.id}?isFromCourseDetail=F`)
                  }
                >
                  {module.title}
                </Typography>
              </TableCell>
              <TableCell>{module.course_title}</TableCell>
              <TableCell>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  {truncate(module?.description, 100)}
                </Typography>
              </TableCell>
              <TableCell>{module.created_by || "N/A"}</TableCell>
              <TableCell>{turnToHumanReadable(module.modified_date)}</TableCell>
              <TableCell>
                <Box sx={{ display: "flex" }}>
                  <IconButton
                    onClick={() =>
                      handleEdit(
                        module.id,
                        module.title,
                        module.description || ""
                      )
                    }
                    color="primary"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleDelete(module.id, module.course_id)}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderCardView = () => (
    <Grid
      container
      spacing={3}
      sx={{ maxHeight: "calc(100vh - 200px)", overflow: "auto" }}
    >
      {filteredModules.map((module) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={uuidv4()}>
          <Card
            sx={{
              height: "100%",
              borderLeft: `4px solid ${theme.palette.primary.main}`,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography
                variant="h6"
                sx={{
                  cursor: "pointer",
                  mb: 2,
                }}
                onClick={() =>
                  navigate(`/module/${module.id}?isFromCourseDetail=F`)
                }
              >
                {truncate(module.title, 20)}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Course: {module.course_title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Author: {module.created_by || "N/A"}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Last modified: {turnToHumanReadable(module.modified_date)}
              </Typography>
            </CardContent>
            <Box
              sx={{
                mt: "auto",
                display: "flex",
                justifyContent: "space-between",
                p: 2,
                borderTop: `1px solid ${theme.palette.divider}`,
              }}
            >
              <Button
                size="small"
                startIcon={<EditIcon />}
                onClick={() =>
                  handleEdit(module.id, module.title, module.description || "")
                }
              >
                Edit
              </Button>
              <Button
                size="small"
                startIcon={<DeleteIcon />}
                color="error"
                onClick={() => handleDelete(module.id, module.course_id)}
              >
                Delete
              </Button>
            </Box>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Box
      sx={{
        p: 3,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      <CreateModuleModal
        isOpen={isEditModalOpen}
        elementType={"Module"}
        handleClose={handleCloseCreateModuleModal}
        handleSubmit={handleEditSubmit}
        title={"Update"}
        initialFormValues={editInitialValues}
      />
      <CustomModal
        open={isFilterModalOpen}
        handleClose={() => setIsFilterModalOpen(false)}
      >
        <Box
          sx={{
            p: 4,
            width: 350,
            backgroundColor: theme.palette.background.paper,
            borderRadius: theme.shape.borderRadius,
            boxShadow: theme.shadows[5],
            position: "relative",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={() => setIsFilterModalOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h6"
            sx={{
              mb: 3,
              fontWeight: "bold",
              color: theme.palette.primary.main,
            }}
          >
            Filter Modules
          </Typography>
          <FormControl fullWidth sx={{ mb: 3 }}>
            <InputLabel id="course-filter-label">Filter by Course</InputLabel>
            <Select
              labelId="course-filter-label"
              id="course-filter"
              value={tempCourseFilter}
              label="Filter by Course"
              onChange={handleCourseFilterChange}
            >
              <MenuItem value="">
                <em>All Courses</em>
              </MenuItem>
              {uniqueCourses.map((courseId) => (
                <MenuItem key={courseId} value={courseId}>
                  {
                    allModules?.find((module) => module.course_id === courseId)
                      ?.course_title
                  }
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Select a course to filter modules</FormHelperText>
          </FormControl>
          <Button
            variant="contained"
            onClick={handleApplyFilter}
            fullWidth
            sx={{
              mt: 2,
              backgroundColor: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            Apply Filter
          </Button>
        </Box>
      </CustomModal>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={7}>
          <Input
            isFancy
            placeholder="Search"
            endAdornment={
              <InputAdornment
                position="end"
                sx={{ width: "25px", height: "25px", paddingTop: 1 }}
              >
                <SearchIcon fontSize={"medium"} />
              </InputAdornment>
            }
            sx={{
              backgroundColor: "#FFFFFF",
              boxShadow: styledTheme.shadows[9],
              padding: styledTheme.spaces[6],
              width: "40%",
            }}
            value={searchTerm}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={5}>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Button
              variant="outlined"
              onClick={handleClearFilter}
              sx={{ mr: 2 }}
            >
              Clear Filters
            </Button>
            <IconButton
              size="small"
              onClick={() => setIsFilterModalOpen(true)}
              sx={{ mr: 2 }}
            >
              <FilterListIcon />
            </IconButton>
            <ToggleButtonGroup
              color="primary"
              value={alignment}
              exclusive
              onChange={handleChange}
            >
              <ToggleButton value="list">
                <Tooltip title="List View">
                  <CalendarViewDayIcon />
                </Tooltip>
              </ToggleButton>
              <ToggleButton value="card">
                <Tooltip title="Card View">
                  <TableChartIcon />
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ mt: 4 }}>
        {alignment === "list" ? renderTableView() : renderCardView()}
      </Box>
    </Box>
  );
}

function throttle(func: Function, delay: number) {
  let timeoutId: NodeJS.Timeout | null = null;
  return function (...args: any[]) {
    if (!timeoutId) {
      timeoutId = setTimeout(() => {
        func(...args);
        timeoutId = null;
      }, delay);
    }
  };
}
