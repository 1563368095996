import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import RenderBook from "app/components/Card";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { CustomModal } from "app/components/Core";
import CourseController from "app/components/FormikWrapper/CourseForm";
import { useCourseSlice } from "../Courses/slice";
import { ICourseEdit, ICourseFormValue } from "../Courses/types";
import { selectCourses } from "../Courses/slice/selectors";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./Homepage.module.css";

import { FreeMode, Navigation } from "swiper/modules";
import {
  selectGlobalLanguage,
  selectUser,
} from "../DefaultLayout/slice/selectors";
import { useTheme } from "@mui/material";

const CreateCourseModal = ({
  open,
  initialValues,
  handleClose,
  isLoading,
  handleSubmit,
}) => {
  return (
    <CustomModal leftWidth="239px" open={open} handleClose={handleClose}>
      <CourseController
        initialValues={initialValues}
        onSubmit={handleSubmit}
        isSubmittingForm={isLoading}
      />
    </CustomModal>
  );
};

export default function HomePage() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { actions } = useCourseSlice();
  const signedInUser = useSelector(selectUser);
  const [isAdd, setIsAdd] = React.useState(true);
  const [editInitialValues, setEditInitialValues] = React.useState({
    title: "",
    description: "",
  });
  const globalLanguage = useSelector(selectGlobalLanguage);
  const loggedInUser = useSelector(selectUser);
  const role = loggedInUser?.role;
  const [courseId, setCourseId] = React.useState("");
  const handleSubmit = (values: ICourseFormValue) => {
    if (isAdd) {
      dispatch(actions.createCourse(values));
    } else {
      dispatch(
        actions.updateCourse({
          title: values.title,
          course_id: courseId,
          description: values.description!,
          language: globalLanguage!,
        })
      );
    }
    handleClose();
  };

  const handleDelete = (courseId) => {
    dispatch(actions.deleteCourse(courseId));
  };

  const handleClose = () => {
    dispatch(actions.toggleCourseModalState(false));
  };

  const { result, isCourseLoading, courseModalState } =
    useSelector(selectCourses);

  React.useEffect(() => {
    dispatch(actions.getCourses(globalLanguage!));
  }, [globalLanguage]);

  const handleClickOpen = (add, course?: ICourseEdit) => {
    if (add) {
      setIsAdd(true);
    } else {
      setIsAdd(false);
      setEditInitialValues({
        title: course?.title!,
        description: course?.description!,
      });
      setCourseId(course?.course_id!);
    }
    dispatch(actions.toggleCourseModalState(true));
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        padding: "0 40px",
      }}
    >
      <CreateCourseModal
        initialValues={
          isAdd ? { title: "", language: globalLanguage } : editInitialValues
        }
        open={courseModalState}
        handleClose={handleClose}
        isLoading={isCourseLoading}
        handleSubmit={handleSubmit}
      />
      <Box
        sx={{
          flexDirection: "column",
          display: "flex",
          height: "100%",
          width: "100%",
          paddingTop: 3,
          rowGap: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px",
            backgroundColor: "#ffffff",
            borderRadius: "15px",
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.05)",
          }}
        >
          <Typography variant="h4">
            Welcome, {signedInUser?.first_name}
          </Typography>
          {(role == "editor" || role == "admin" || role == "super_user") && (
            <Button
              sx={{
                backgroundColor: `${theme.palette.primary.main}`,
                color: "white",
                border: "none",
                padding: "12px 24px",
                borderRadius: "8px",
                cursor: "pointer",
                transition: "background-color 0.2s, transform 0.2s",
                fontWeight: "600",
                "&:hover": {
                  backgroundColor: "#2980b9",
                  transform: "translateY(-2px)",
                },
              }}
              onClick={() => handleClickOpen(true)}
            >
              Create Course
            </Button>
          )}
        </Box>

        <List
          sx={{
            listStyle: "none",
            flexFlow: "row wrap",
            display: "flex",
            width: "100%",
            padding: 0,
          }}
        >
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={3}
            freeMode={true}
            navigation={true}
            className={styles.swiper}
            modules={[FreeMode, Navigation]}
          >
            {result?.map((course) => (
              <SwiperSlide className={styles["swiper-slide"]}>
                <ListItem
                  key={uuidv4()}
                  sx={{
                    flex: "0 0 25%",
                    minWidth: "100%",
                    "&.MuiListItem-padding": {
                      padding: "0 32px 0px 0px",
                    },
                  }}
                >
                  <RenderBook
                    role={role}
                    course={course}
                    handleDelete={handleDelete}
                    isCourseLoading={isCourseLoading}
                    handleClickOpen={handleClickOpen}
                  />
                </ListItem>
              </SwiperSlide>
            ))}
          </Swiper>
        </List>

        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            borderRadius: "15px",
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.05)",
            padding: "25px",
          }}
          elevation={0}
        >
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontSize: "1.5em",
              fontWeight: "bold",
            }}
          >
            AI Power Insights
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: "15px",
              padding: "10px",
              backgroundColor: "rgba(52, 152, 219, 0.1)",
              borderRadius: "8px",
            }}
          >
            <Typography>
              Based on student performance, consider adding more interactive
              elements to "Horticulture 101".
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: "15px",
              padding: "10px",
              backgroundColor: "rgba(52, 152, 219, 0.1)",
              borderRadius: "8px",
            }}
          >
            <Typography>
              Your "Animal Communication" course has seen a 15% increase in
              enrollment this week.
            </Typography>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
