import { ILanguage } from "./type";

export const API_ROUTE = `${process.env.REACT_APP_API_ROUTE}`;
export const languages = ["EN", "FR", "DE", "ES", "IT", "NL"];

export const languageOptions = [
  { value: ILanguage.en, label: "English" },
  { value: ILanguage.de, label: "Deutsch" },
  { value: ILanguage.es, label: "Español" },
  { value: ILanguage.fr, label: "Français" },
  { value: ILanguage.it, label: "Italiano" },
  { value: ILanguage.nl, label: "Nederlands" },
];

export enum ROUTES {
  home = "/home",
  login = "/login",
  signup = "/signup",
  course = "/course",
  newFunnel = "/newFunnel",
  settings = "/settings/*",
  modules = "/modules",
  topics = "/topics",
  courses = "/courses",
  quizzes = "/quizzes",
  glossary = "/glossaries",
  importPage = "/import",
  jobs = "/jobs",
}

export enum RouteNames {
  Home = "Home",
  Course = "Course",
  Login = "Login",
  SignUp = "SignUp",
  NewFunnel = "NewFunnel",
  Settings = "Settings",
  Topics = "Topics",
  Modules = "Modules",
  Courses = "Courses",
  Quizzes = "Quizzes",
  ImportModule = "ImportModule",
  Jobs = "Jobs",
  Glossaries = "Glossaries",
  NoAccess = "NoAccess",
  ChangePassword = "ChangePassword",
}

export const ItemTypes = {
  SidebarEl: "sidebarEl",
};

export enum HexColorOpacity {
  ZERO = "00",
  FIVE = "0C",
  TEN = "19",
  FIFTEEN = "26",
  TWENTY = "33",
  TWENTY_FIVE = "3F",
  THIRTY = "4C",
  THIRTY_FIVE = "59",
  FOURTY = "66",
  FOURTY_FIVE = "72",
  FIFTY = "7F",
  FIFTY_FIVE = "8C",
  SIXTY = "99",
  SIXTY_FIVE = "A5",
  SEVENTY = "B2",
  SEVENTY_FIVE = "BF",
  EIGHTY = "CC",
  EIGHTY_FIVE = "D8",
  NINETY = "E5",
  NINETY_FIVE = "F2",
  HUNDRED = "FF",
}
