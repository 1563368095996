/**
 *
 * SignInPage
 *
 */
import * as React from "react";
import styled from "styled-components";
import AuthController from "app/components/FormikWrapper/AuthForm";
import { useDispatch, useSelector } from "react-redux";
import { Box, Image } from "app/components/Core";
import { theme } from "styles/theme/themes";
import { useNavigate } from "react-router-dom";
import ForgetPasswordController from "app/components/FormikWrapper/ForgetPassword";
import { useDefaultLayoutSlice } from "../DefaultLayout/slice";
import {
  selectDefaultLayoutState,
  selectIsForgetPasswordLoading,
  selectToastMessage,
  selectUser,
} from "../DefaultLayout/slice/selectors";
import {
  forgetPasswordInitialValues,
  signInFormInitialValues,
} from "./constants";
import { IForgetPassword, ISignInFormValues } from "./types";
import { signInImage } from "assets/images";
import { toast } from "react-toastify";
import { localstorageGetItem } from "helper/helperFunctions";

interface Props {}
interface ImageContainerProps {
  isLogin: boolean;
}
export const ImageContainer = styled.div<ImageContainerProps>`
  display: none;
  justify-content: center;
  align-items: center;
  ${theme.mediaQueries.sm} {
    display: flex;
    width: 50%;
  }
  ${theme.mediaQueries.md} {
    display: flex;
    width: 50%;
  }
  ${theme.mediaQueries.lg} {
    display: flex;
    width: ${({ isLogin }) => (isLogin ? "60%" : "50%")};
  }
`;

export function SignInPage(_props: Props) {
  const { actions } = useDefaultLayoutSlice();
  const dispatch = useDispatch();
  const signingInState = useSelector(selectDefaultLayoutState);
  const isForgetPasswordLoading = useSelector(selectIsForgetPasswordLoading);
  const navigate = useNavigate();
  const [isSignIn, setIsSignIn] = React.useState(true);
  const toastData = useSelector(selectToastMessage);
  const toastID = React.useRef<string | number | undefined>(undefined);
  const loggedInUser = useSelector(selectUser);
  const role = loggedInUser?.role;

  React.useEffect(() => {
    if (!toast.isActive(toastID.current as React.ReactText) && !!toastData) {
      toastID.current = toast(toastData?.currentMessage, {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        type: toastData?.currentVariant,
        toastId: toastData?.count,
      });
    }
    return () => {
      dispatch(actions.hideAppMessage());
    };
  }, [toastData?.count]);

  React.useEffect(() => {
    const token = localstorageGetItem("token");
    if (token) {
      if (role == "new") {
        navigate("/no-access");
      } else navigate("/home");
    }
  }, [signingInState.user]);

  const onLoginInSubmit = (values: ISignInFormValues) => {
    dispatch(actions.signinUser(values));
  };

  const onForgetPasswordSubmit = (values: IForgetPassword) => {
    dispatch(actions.forgetPassword(values));
  };

  const handleToggleSignIn = () => {
    setIsSignIn(!isSignIn);
  };

  return (
    <Box display="flex" flex={1}>
      <ImageContainer isLogin={true}>
        <Box
          display="flex"
          width={[null, 260, 340, 453]}
          height={[null, 240, 320, 420]}
        >
          <Image width="100%" height="100%" src={signInImage} />
        </Box>
      </ImageContainer>
      <Box display="flex" bg={"#ffffff"} flex={1} alignItems="center">
        {isSignIn ? (
          <AuthController
            isAuthLoading={signingInState.isSigningIn}
            isSubmittingForm={signingInState.isSigningIn}
            onSubmit={onLoginInSubmit}
            initialValues={signInFormInitialValues}
            handleToggleIsSignIn={handleToggleSignIn}
          />
        ) : (
          <ForgetPasswordController
            isLoadingForgetPassword={isForgetPasswordLoading}
            initialValues={forgetPasswordInitialValues}
            onSubmit={onForgetPasswordSubmit}
            handleToggleIsSignIn={handleToggleSignIn}
          />
        )}
      </Box>
    </Box>
  );
}
